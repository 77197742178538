import React, { useContext, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
// import { toJS } from 'mobx';
// import { Navigate, useNavigate } from 'react-router-dom';

import styles from './Layout.module.scss';
import LeftBarAdmin from '../left-bar';
import Layout from '../../layout';

import { Context } from '../../..';
import withAuth from '../../hoc/withAuth';
import LoadingSpinner from '../../loading-spinner';

const LayoutSeller = ({ children, typeInfo }) => {
	const store = useContext(Context);
	const { authStore, myInfoStore } = store.rootStore;
	const navigate = useNavigate();

	// console.log('authStore.isAuth >>>', authStore.isAuth);

	// if (!authStore.isAuth) {
	// 	// navigate('/');
	// 	<Navigate to='/' />
	// }

	useEffect(() => {
		const fetchValidateJWT = async () => {
			await authStore.validateJWT([typeInfo]);
			await myInfoStore.setMyInfoDataFromResponse(authStore, typeInfo);

			if (!authStore.isAuth) {
				navigate('/');
			}
		}
		fetchValidateJWT();
	}, [authStore, myInfoStore, navigate, typeInfo]);

	if (authStore.isLoading) {
		return <LoadingSpinner />
	}

	if (authStore.isAuth) {
		return (
			<StyledEngineProvider injectFirst>
				<Layout>
					<Container maxWidth={false} sx={{ pl: { xxs: 0, def: 2 }, pr: { xxs: 0, def: 2 } }}>
						<Grid container>
							{
								!isMobile && (
									<Grid item xxs="12" def="2" className={styles['layout-menu']}>
										<LeftBarAdmin />
									</Grid>
								)
							}
							<Grid item xxs="12" def="10" className={styles['layout-content']}>{ children }</Grid>
						</Grid>
					</Container>
				</Layout>
			</StyledEngineProvider>
		)
	}
}

export default withAuth(observer(LayoutSeller));
