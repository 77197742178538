import React, { useState } from 'react';
import { Box, IconButton, Collapse, Button, Grid, Pagination, Fade } from '@mui/material';
import clsx from 'clsx';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

import { isDesktop } from 'react-device-detect';

import withAuth from '../../../hoc/withAuth';

import MyInfoHeader from '../my-info-header';
import styles from './MyOrders.module.scss';
import PaginationSeller from '../pagination-seller';

const orders = [
	{
		id: 756709,
		date: '23.02.22',
		productsImage: [
			{
				id: 1,
				img: '../assets/images/product-card/prod_front6.jpg'
			},
			{
				id: 2,
				img: '../assets/images/product-card/prod_front6.jpg'
			},
			{
				id: 3,
				img: '../assets/images/product-card/prod_front6.jpg'
			}
		],
		totalPrice: 1650,
		status: 'Виконано',
		orderDetails: {
			products: [
				{
					id: 1,
					image: '../assets/images/product-card/prod_front6.jpg',
					title: 'Джинсова куртка від бренду NAkD',
					price: 550,
					properties: [
						{
							id: 1,
							name: 'Розмір',
							value: 'M'
						},
						{
							id: 2,
							name: 'Колір',
							value: 'чорний'
						}
					]
				},
				{
					id: 2,
					image: '../assets/images/product-card/prod_front6.jpg',
					title: 'Джинсова куртка від бренду NAkD',
					price: 550,
					properties: [
						{
							id: 1,
							name: 'Розмір',
							value: 'M'
						},
						{
							id: 2,
							name: 'Колір',
							value: 'чорний'
						}
					]
				},
				{
					id: 3,
					image: '../assets/images/product-card/prod_front6.jpg',
					title: 'Джинсова куртка від бренду NAkD',
					price: 550,
					properties: [
						{
							id: 1,
							name: 'Розмір',
							value: 'M'
						}
					]
				}
			],
			shopperDetails: [
				{
					id: 1,
					title: 'Отримувач',
					info: [
						'Баланюк Ксенія Павлівна',
						'380688031838',
						'kseniabalanyk@gmail.com'
					]
				},
				{
					id: 2,
					title: 'Доставка',
					info: [
						'TTH 20450639097817',
						'Самовивіз з Нової Пошти',
						'Чернівці, 13, вул. Буковинська, б.25'
					]
				},
				{
					id: 3,
					title: 'Графік роботи',
					info: [
						'Пн-Пт: 08:00-20:00',
						'Сб-Нд: 09:00-19:00'
					]
				}
			]
				// shopperInfo: {

				// 	// fullName: 'Баланюк Ксенія Павлівна',
				// 	// phone: '380688031838',
				// 	// email: 'kseniabalanyk@gmail.com'
				// },
				// deliveryInfo: {

				// 	// ttn: 'TTH 20450639097817',
				// 	// postType: 'Самовивіз з Нової Пошти',
				// 	// address: 'Чернівці, 13, вул. Буковинська, б.25'
				// },
				// scheduleInfo: {

				// 	// schedule1: 'Пн-Пт: 08:00-20:00',
				// 	// schedule2: 'Сб-Нд: 09:00-19:00'
				// }
		}
	}
];

const MyOrdersBlock = () => {
	const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
		// console.log('click');
  };

	const siblingCountPagination = isDesktop ? 1 : 0;

	return (
		<Box className={styles['my-orders']}>
			<MyInfoHeader title="Замовлення" pageName="my-orders" />
			<Box className={styles['my-orders__content']}>
				<Box className={styles['my-orders__form']}></Box>
				<Box className={styles['my-orders__orders']}>
					{
						orders.map(order => (
							<Box key={order.id} className={clsx(styles['order-item'], (open && styles['is-open']))}>
								<Box className={styles['order-item__header']}>
									<Box className={styles['order-item__order-number']}>
										<Box>Замовлення <span className={styles['order-number']}>№{order.id}</span></Box>
										<Box sx={{ mt: { md: 2 } }}> від {order.date}</Box>
									</Box>
									{
										!open && (
											<Fade in={!open}>
												<Box className={styles['order-item__fade']}>
													<Box className={styles['order-item__images']}>
														<Box>Товари</Box>
														<Box className={styles['order-item__images-wrapper']}>
															{
																order.productsImage.map(image => (
																	<Box key={image.id} className={styles['order-item__images-image']}>
																		<img src={image.img} alt="order images" />
																	</Box>
																))
															}
														</Box>
													</Box>
													<Box className={styles['order-item__total-price']}>
														<Box>Сума замовлення</Box>
														<Box sx={{ mt: { md: 2 }, fontWeight: 600 }}>{order.totalPrice}&nbsp;₴</Box>
													</Box>
													<Box className={styles['order-item__status']}>
														<Box>Статус</Box>
														<Box sx={{ mt: { md: 2 }, fontWeight: 600 }}>{order.status}</Box>
													</Box>
												</Box>
											</Fade>
										)
									}

									{/*
										!open && (
											<>

											</>
										)
									*/}
									<Box className={styles['order-item__details']}>
										<Box>Деталі</Box>
										<Box>
											<IconButton className={styles['order-item__more-info']} disableRipple onClick={handleToggle}>
												{ open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon /> }
											</IconButton>
										</Box>
									</Box>
								</Box>
								<Collapse in={open}>
									<Grid container className={styles['order-details']}>
										<Grid item xxs="12" md="8">
											<Box className={styles['order-details__products']}>
												{
													order.orderDetails.products.map(product => (
														<Box key={product.id} className={styles['product-detail']}>
															<Box className={styles['product-detail__image']}>
																<img src={product.image} alt="" />
															</Box>
															<Box sx={{ display: 'flex', flexDirection: { xxs: 'column', md: 'row' }, ml: { xxs: 2, md: 0 } }}>
																<Box className={styles['product-detail__info']}>
																	<Box sx={{ fontWeight: 600 }}>{product.title}</Box>
																	{
																		product.properties.length && (
																			<Box sx={{ mt: { xxs: 1, md:  2 } }}>
																				{
																					product.properties.map(property => (
																						<Box key={property.id} sx={{ display: 'flex' }}>
																							<Box>{property.name}:</Box>
																							<Box sx={{ fontWeight: '600', ml: 1 }}>{property.value}</Box>
																						</Box>
																					))
																				}
																			</Box>
																		)
																	}
																	<Box sx={{ mt: { xxs: 1, md: 2 } }}>Ціна:&nbsp;<strong>{product.price}</strong>&nbsp;₴</Box>
																</Box>
																<Box sx={{ ml: { md: 'auto'}, mt: { xxs: 1, md: 0 } }}>
																	<Button className='button button-blue' variant='contained'>Залишити відгук</Button>
																</Box>
															</Box>

														</Box>
													))
												}
												<Box className={styles['order-details__total-price']}>
													<Box>Разом: <strong>{order.totalPrice}</strong>&nbsp;₴</Box>
												</Box>
											</Box>
										</Grid>
										<Grid item xxs="12" md="4" sx={{ pl: { md: 1 }, mt: { xxs: 1, md: 0 } }}>
											<Box className={styles['order-details__delivery']}>
												{
													order.orderDetails.shopperDetails.map(item => (
														<Box className={styles['order-details__delivery-item']} key={item.id}>
															<Box className={styles['order-details__delivery-title']}>{item.title}:</Box>
															{
																item.info.map((itemInfo, index) => (
																	<Box className={styles['order-details__delivery-info']} key={index}>{itemInfo}</Box>
																))
															}
														</Box>
													))
												}
											</Box>

										</Grid>
									</Grid>
								</Collapse>
							</Box>
						))
					}
				</Box>
				<Box className={styles['my-orders__pagination']}>
					<PaginationSeller count={8} shape="rounded" defaultPage={1} siblingCount={siblingCountPagination} />
					{/* <Pagination count={10} shape="rounded" defaultPage={4} /> */}
				</Box>
			</Box>
		</Box>
	)
}

export default withAuth(MyOrdersBlock);
