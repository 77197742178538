import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Stack, Box, Button, FormHelperText, FormControl, OutlinedInput, Grid, InputLabel, InputAdornment } from '@mui/material';
import { useForm, Controller, useController } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { toJS } from 'mobx';

import { schemaSize } from '../../../validator';
import withAuth from '../../../hoc/withAuth';
import SizeItem from './size-item';
import LoadingSpinner from '../../../loading-spinner';

import { Context } from '../../../..';

import styles from './MySizesBlock.module.scss';

// const defSize = {
// 	caption: '',
// 	sizes: [
// 		{
// 			key: 'breastVolume',
// 			caption: 'Об\'єм грудей',
// 			limits: [
// 				{
// 					key: 'from',
// 					name: 'Від',
// 					value: '',
// 				},
// 				{
// 					key: 'to',
// 					name: 'До',
// 					value: ''
// 				}
// 			]
// 		},
// 		{
// 			key: 'waistVolume',
// 			caption: 'Об\'єм талії',
// 			limits: [
// 				{
// 					key: 'from',
// 					name: 'Від',
// 					value: ''
// 				},
// 				{
// 					key: 'to',
// 					name: 'До',
// 					value: ''
// 				}
// 			]
// 		},
// 		{
// 			key: 'hipVolume',
// 			caption: 'Об\'єм стегон',
// 			limits: [
// 				{
// 					key: 'from',
// 					name: 'Від',
// 					value: ''
// 				},
// 				{
// 					key: 'to',
// 					name: 'До',
// 					value: ''
// 				}
// 			]
// 		},
// 		{
// 			key: 'footLength',
// 			caption: 'Довжина ступні',
// 			limits: [
// 				{
// 					key: 'length',
// 					name: 'Довжина',
// 					value: ''
// 				}
// 			]
// 		}
// 	]
// };

const MySizesBlock = () => {
	const [showHideForm, setShowHideForm] = useState(false);
	const [findIndexOfSize, setFindIndexOfSize] = useState(null);
	// const [currKeyCode, setCurrKeyCode] = useState(null);
	const [selSize, setSelSize] = useState({});


	const { register, formState: { errors }, handleSubmit, trigger } = useForm({
		resolver: yupResolver(schemaSize),
	});

	// const { field } = useController({
	// 	name: 'caption'
	// });

	const store = useContext(Context);
	const { mySizes, defaultSize, selectedSizeID, deleteSize, setSelectedSizeID, addNewOrSaveEditSize, saveSizeLoading } = store.rootStore.myInfoStore;
	const { _jwt } = store.rootStore.authStore;

	const submitForm = (data) => {
		// console.log('data size >', data);

		selSize.jwt = _jwt;
		selSize.typeInfo = 'saveSize';
		// if (selectedSizeID === null) {
		// 	mySizes.push(selSize);
		// } else {
		// 	setMySizes(
		// 		mySizes.map(size => {
		// 			if (size.sizeID === selectedSizeID) {
		// 				return selSize;
		// 			} else {
		// 				return size;
		// 			}
		// 		})
		// 	);
		// }
		addNewOrSaveEditSize(selSize);
		setShowHideForm(false);
  }

	const handleEditSize = (id) => {
		const findSize = mySizes.find(item => Number(item.sizeID) === Number(id));
		setSelectedSizeID(id);
		setSelSize(toJS(findSize));
		setShowHideForm(true);
		trigger();
	}

	const handleDeleteSize = (id) => {
		// const findIndex = mySizes.findIndex(size => size.id === id);
		// mySizes.splice(findIndex, 1);
		const data = {
			sizeID: id,
			jwt: _jwt,
			typeInfo: 'deleteSize'
		}
		deleteSize(data);
	}

	const handleShowHideForm = () => {
		setShowHideForm(true);
		setSelectedSizeID(null);
		setSelSize(toJS(defaultSize));
	}

	const handleChangeCaptionSize = (value) => {

		setSelSize({
			...selSize,
			caption: value
		});

		// console.log('defaultSize >>>', toJS(defaultSize));
		// changeCaptionSize(value);
		// setDefaultSize({
		// 	...defaultSize,
		// 	caption: e.target.value
		// });
	}

	const handleChangeLimit = (e) => {
		const parentKey = e.target.dataset.parentKey;
		const limitKey = e.target.dataset.limitKey;
		const tempSelSize = { ...toJS(selSize) };
		const findLimit = tempSelSize.sizes.find(size => size.key === parentKey).limits.find(limit => limit.key === limitKey);
		findLimit.value = e.target.value;

		setSelSize(tempSelSize);

		// setSelSize({
		// sizes: [
		// 	...selSize.sizes,
		// 	{
		// 		[findSizeIndex]: {
		// 			...selSize.sizes[findSizeIndex],
		// 			limits: [
		// 				...selSize.sizes[findSizeIndex].limits,
		// 				{
		// 					[findLimitIndex]: {
		// 						...selSize.sizes[findSizeIndex].limits[findLimitIndex],
		// 						value: e.target.value
		// 					}
		// 				}
		// 			]
		// 		}
		// 	}
		// ]
		// });
	}

	// const handleKeyDownSize = (e) => {
	// 	if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
	// 		setCurrKeyCode(e.keyCode);
	// 	}
	// }

	const handleBackToListSizes = () => {
		setShowHideForm(false);
	}

	return (
		<Stack className={styles['sizes']}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: { xxs: 1, def: 3 } }}>
					<Box className={styles['sizes-title']}>Мої розміри</Box>
					{
						!showHideForm && mySizes.length > 0 && (
							<Box>
								<Button className={'button button-blue'} variant='contained' disableRipple onClick={handleShowHideForm}>
									Додати розмір
								</Button>
							</Box>
						)
					}
				</Box>
			<Stack className={styles['sizes-wrapper']}>
				{
					showHideForm ? (
						<form onSubmit={handleSubmit(submitForm)} className={styles['sizes-form']}>
							<Box sx={{ width: '100%', mb: 3 }}>
								<Box className={styles['size-name']}>
									<Box className={styles['size-name__title']}>Назва</Box>
									<Box className='form-control'>
										<FormControl>
											<OutlinedInput
												{...register('caption')}
												placeholder="Введіть назву"
												error={Boolean(errors.caption)}
												autoComplete='off'
												id="caption"
												className="form-input__text"
												aria-describedby="caption-helper-text"
												value={selSize.caption || ''}
												onChange={(e) => handleChangeCaptionSize(e.target.value)}
											/>
											<FormHelperText id="caption-helper-text" className='form-helper-text'>{errors.caption ? errors.caption.message : ''}</FormHelperText>
										</FormControl>
									</Box>
								</Box>
							</Box>

							<Box className={styles['sizes-form__title']}>Основна інформація</Box>
							<Box className={styles['sizes-form__inputs']}>
								{
									selSize.sizes.map(sizeInfo => (
										<Box className={styles['input-item']} key={sizeInfo.key}>
											<Box className={styles['title']} sx={{ textAlign: 'center' }}>{sizeInfo.caption}</Box>
											<Box className={clsx(styles['limits'], styles[`count-inputs-${sizeInfo.limits.length}`])}>
												{
													sizeInfo.limits.map(limit => {
														const inputName = `${sizeInfo.key}-${limit.key}`;
														return (
															<Box className="form-control" key={limit.key}>
																{
																	sizeInfo.limits.length > 1 && (
																		<InputLabel htmlFor={`limit-${inputName}`} className={clsx('form-control__label', styles['form-control__label-limit'])}>{limit.name}</InputLabel>
																	)
																}
																<FormControl sx={{ alignItems: 'center' }}>
																	<OutlinedInput
																		name={`${inputName}`}
																		{...register(inputName)}
																		placeholder="0"
																		error={Boolean(errors.numberSize)}
																		autoComplete='off'
																		id={`limit-${inputName}`}
																		className="form-input__text form-input__number"
																		aria-describedby={`${inputName}-helper-text`}
																		value={limit.value || ''}
																		inputProps={{ 'data-parent-key': `${sizeInfo.key}`, 'data-limit-key': `${limit.key}` }}
																		onChange={(e) => handleChangeLimit(e)}
																		type="number"
																		// onKeyDown={handleKeyDownSize}
																		sx={{ maxWidth: '225px' }}
																		endAdornment={<InputAdornment position="end">см</InputAdornment>}
																	/>
																	<FormHelperText id={`${inputName}-helper-text`} className='form-helper-text'>{errors.numberSize ? errors.numberSize.message : ""}</FormHelperText>
																</FormControl>
															</Box>
														);
													})
												}
											</Box>
										</Box>
									))
								}
							</Box>
							<Box sx={{ textAlign: 'center', mt: { xxs: 2, def: 4 } }}>
								<Button className='button button-light' variant='contained' disableRipple onClick={handleBackToListSizes}>Назад</Button>
								<Button type="submit" className='button button-blue' variant='contained' disableRipple sx={{ ml: 4 }}>
									{ selectedSizeID > 0 ? 'Змінити' : 'Додати' } розмір
								</Button>
							</Box>
						</form>
					) : (
						mySizes.length == 0 ? (
							<Box className={styles['sizes-not-found']}>
								<Box className={styles['sizes-not-found__title']}>На жаль, у Вас ще не має доданих розмірів</Box>
								<Box className={styles['sizes-add-size']}>
									<Button className={clsx('button button-blue', styles['button-add-size'])} variant='contained' disableRipple onClick={handleShowHideForm}>
										Додати
									</Button>
								</Box>
							</Box>
						) : (
							<Box className={styles['sizes-list']}>
								{
									saveSizeLoading ? (
										<LoadingSpinner />
									) : (
										mySizes.map(sizeItem => (
											<SizeItem caption={sizeItem.caption} sizes={sizeItem.sizes} key={sizeItem.sizeID} onEditSize={() => handleEditSize(sizeItem.sizeID)} onDeleteSize={() => handleDeleteSize(sizeItem.sizeID)} />
										))
									)
								}
							</Box>
						)
					)
				}
			</Stack>
		</Stack>

	)
}

export default withAuth(observer(MySizesBlock));
