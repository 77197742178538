import React from 'react';
// import { observer } from 'mobx-react-lite';
// import { useNavigate } from 'react-router-dom';

// import { Context } from '../../..';
// import LoadingSpinner from '../../loading-spinner';

const withAuth = (WrappedComponent) => {
  const AuthenticatedComponent = (props) => {
		// const navigate = useNavigate();

		// const store = useContext(Context);
  	// const { authStore } = store.rootStore;

		// useEffect(() => {
		// 	const fetchValidateJWT = async () => {
		// 		await authStore.validateJWT();
		// 	}
		// 	fetchValidateJWT();
		// 	console.log('withAuth, isAuth >', authStore.isAuth);
		// }, [authStore]);

		// validateJWT();

		// if (!authStore.isAuth) {
		// 	return <LoadingSpinner />
		// }

    // if (authStore.isAuth) {
			return <WrappedComponent {...props} />;
    // }
		// else {
		// 	// window.location.href = window.location.origin;
		// 	navigate('/');
    //   // return <p>Будь ласка, увійдіть, щоб переглянути цю сторінку.</p>;
    // }
  };

  return AuthenticatedComponent;
};

export default withAuth;
