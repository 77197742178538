import React from 'react'
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';

const BreadcrumbsPage = ({ items }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} className={styles.breadcrumbs}>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;

        return isLastItem ? (
          <Typography color="text.primary" key={index}>
            {item.label}
          </Typography>
        ) : (
          <Link color="inherit" to={item.url} key={index} className={styles['breadcrumbs-item']}>
            {item.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  )
}

export default BreadcrumbsPage;
