import React, { useContext, useEffect } from 'react'
import { Box, Grid, Typography, Container, Button } from '@mui/material'
// import { useTranslation } from 'react-i18next';

import Layout from '../../components/layout';
import LeftBar from '../../components/left-bar';
import TopBar from '../../components/top-bar';
import MainSlider from '../../components/sliders/main-slider';
import Categories from '../../components/categories';
import ProductCard from '../../components/product-card';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import Slider from 'react-slick';
// import LoadingSpinner from '../../components/loading-spinner';

// import styles from './Main.module.scss';
// import RecommendedProdCard from '../../components/recommended-product-card';
import SliderOfProducts from '../../components/slider-of-products';
// import clsx from 'clsx';
// import TopSeller from '../../components/top-seller';
import CustomSlider from '../../components/custom-slider';
// import useWindowSize from '../../hooks/window-size';
import { isMobile } from 'react-device-detect';
import { ErrorBoundary } from "react-error-boundary";
import { observer } from 'mobx-react-lite';

import { Context } from '../..';

const categoryProductsInfo = [
  {
    id: 1,
    title: 'Джинсовий костюм',
    description: 'Джинси блакитні з високою посадкою',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front1.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '930 ₴'
  },
  {
    id: 2,
    title: 'Біла сукня',
    description: 'Джинси блакитні з високою посадкою',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front2.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '780 ₴'
  },
  {
    id: 3,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front3.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '330 ₴'
  },
  {
    id: 4,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front4.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '350 ₴'
  },
  {
    id: 5,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front5.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '450 ₴'
  },
  {
    id: 6,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front6.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '620 ₴'
  },
  {
    id: 7,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front7.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '790 ₴'
  },
  {
    id: 8,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front8.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '240 ₴'
  }
];

const firstArrayCatProd = categoryProductsInfo.slice(0, 4);
const secondArrayCatProd = categoryProductsInfo.slice(4);

const recommendedProducts = [
  {
    id: 1,
    image: '../assets/images/product-card/prod_front1.webp',
    title: 'Топ з відкритими плечима',
    price: '33 ₴',
    priceOld: '100 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 0
          },
          {
            id: 2,
            value: 'M',
            isActive: 1
          },
          {
            id: 3,
            value: 'L',
            isActive: 0
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#316586',
            isActive: 1
          },
          {
            id: 2,
            value: '#ffb1bf',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 2,
    image: '../assets/images/product-card/prod_front2.webp',
    title: 'Сукня міді в рубчик',
    price: '93 ₴',
    priceOld: '100 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 1
          },
          {
            id: 2,
            value: 'M',
            isActive: 0
          },
          {
            id: 3,
            value: 'L',
            isActive: 0
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#FFB1BF',
            isActive: 1
          },
          {
            id: 2,
            value: '#FF8300',
            isActive: 0
          },
          {
            id: 3,
            value: '#FF5074',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 3,
    image: '../assets/images/product-card/prod_front4.webp',
    title: 'Джинси мом з високою посадкою',
    price: '97 ₴',
    priceOld: '120 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 0
          },
          {
            id: 2,
            value: 'M',
            isActive: 0
          },
          {
            id: 3,
            value: 'L',
            isActive: 1
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#316586',
            isActive: 1
          },
          {
            id: 2,
            value: '#FFB1BF',
            isActive: 0
          },
          {
            id: 3,
            value: '#FF8300',
            isActive: 0
          },
          {
            id: 4,
            value: '#FF5074',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 4,
    image: '../assets/images/product-card/prod_front6.webp',
    title: 'Топ з відкритими плечима',
    price: '33 ₴',
    priceOld: '100 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 0
          },
          {
            id: 2,
            value: 'M',
            isActive: 1
          },
          {
            id: 3,
            value: 'L',
            isActive: 0
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#316586',
            isActive: 1
          },
          {
            id: 2,
            value: '#ffb1bf',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 5,
    image: '../assets/images/product-card/prod_front5.webp',
    title: 'Сукня міді в рубчик',
    price: '93 ₴',
    priceOld: '100 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 1
          },
          {
            id: 2,
            value: 'M',
            isActive: 0
          },
          {
            id: 3,
            value: 'L',
            isActive: 0
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#FFB1BF',
            isActive: 1
          },
          {
            id: 2,
            value: '#FF8300',
            isActive: 0
          },
          {
            id: 3,
            value: '#FF5074',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 6,
    image: '../assets/images/product-card/prod_front8.webp',
    title: 'Джинси мом з високою посадкою',
    price: '97 ₴',
    priceOld: '120 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 0
          },
          {
            id: 2,
            value: 'M',
            isActive: 0
          },
          {
            id: 3,
            value: 'L',
            isActive: 1
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#316586',
            isActive: 1
          },
          {
            id: 2,
            value: '#FFB1BF',
            isActive: 0
          },
          {
            id: 3,
            value: '#FF8300',
            isActive: 0
          },
          {
            id: 4,
            value: '#FF5074',
            isActive: 0
          }
        ]
      }
    ]
  }
];

const topSellers = [
  {
    id: 1,
    name: 'Аліса Мілонська',
    image: '../assets/images/product-card/prod_front2.webp',
    rating: 4.5,
    countReviews: 1872,
    percentCompletedOrders: 100,
    efficiencies: [
      '100% виконаних робіт',
      'відповідає дуже швидко'
    ]
  },
  {
    id: 2,
    name: 'Аліса Мілонська',
    image: '../assets/images/product-card/prod_front2.webp',
    rating: 5,
    countReviews: 1872,
    percentCompletedOrders: 100,
    efficiencies: [
      '100% виконаних робіт',
      'відповідає дуже швидко'
    ]
  },
  {
    id: 3,
    name: 'Аліса Мілонська',
    image: '../assets/images/product-card/prod_front2.webp',
    rating: 3.5,
    countReviews: 1872,
    percentCompletedOrders: 100,
    efficiencies: [
      '100% виконаних робіт',
      'відповідає дуже швидко'
    ]
  },
  {
    id: 4,
    name: 'Аліса Мілонська',
    image: '../assets/images/product-card/prod_front2.webp',
    rating: 4,
    countReviews: 1872,
    percentCompletedOrders: 100,
    efficiencies: [
      '100% виконаних робіт',
      'відповідає дуже швидко'
    ]
  },
  {
    id: 5,
    name: 'Аліса Мілонська',
    image: '../assets/images/product-card/prod_front2.webp',
    rating: 4.5,
    countReviews: 1872,
    percentCompletedOrders: 100,
    efficiencies: [
      '100% виконаних робіт',
      'відповідає дуже швидко'
    ]
  },
  {
    id: 6,
    name: 'Аліса Мілонська',
    image: '../assets/images/product-card/prod_front2.webp',
    rating: 4.5,
    countReviews: 1872,
    percentCompletedOrders: 100,
    efficiencies: [
      '100% виконаних робіт',
      'відповідає дуже швидко'
    ]
  },
  {
    id: 7,
    name: 'Аліса Мілонська',
    image: '../assets/images/product-card/prod_front2.webp',
    rating: 4.5,
    countReviews: 1872,
    percentCompletedOrders: 100,
    efficiencies: [
      '100% виконаних робіт',
      'відповідає дуже швидко'
    ]
  },
  {
    id: 8,
    name: 'Аліса Мілонська',
    image: '../assets/images/product-card/prod_front2.webp',
    rating: 4.5,
    countReviews: 1872,
    percentCompletedOrders: 100,
    efficiencies: [
      '100% виконаних робіт',
      'відповідає дуже швидко'
    ]
  }
];

const brandsImages = [
	{
		id: 1,
		image: '../assets/images/brands/espirit.webp'
	},
	{
		id: 2,
		image: '../assets/images/brands/forever21.webp'
	},
	{
		id: 3,
		image: '../assets/images/brands/mango.webp'
	},
	{
		id: 4,
		image: '../assets/images/brands/only.webp'
	},
	{
		id: 5,
		image: '../assets/images/brands/topshop.webp'
	},
	{
		id: 6,
		image: '../assets/images/brands/mango.webp'
	},
	{
		id: 7,
		image: '../assets/images/brands/only.webp'
	},
	{
		id: 8,
		image: '../assets/images/brands/forever21.webp'
	},
	{
		id: 9,
		image: '../assets/images/brands/espirit.webp'
	},
	{
		id: 10,
		image: '../assets/images/brands/topshop.webp'
	},
	{
		id: 11,
		image: '../assets/images/brands/espirit.webp'
	},
	{
		id: 12,
		image: '../assets/images/brands/forever21.webp'
	},
	{
		id: 13,
		image: '../assets/images/brands/mango.webp'
	},
	{
		id: 14,
		image: '../assets/images/brands/only.webp'
	},
	{
		id: 15,
		image: '../assets/images/brands/topshop.webp'
	},
	{
		id: 16,
		image: '../assets/images/brands/mango.webp'
	},
	{
		id: 17,
		image: '../assets/images/brands/only.webp'
	},
	{
		id: 18,
		image: '../assets/images/brands/forever21.webp'
	},
	{
		id: 19,
		image: '../assets/images/brands/espirit.webp'
	},
	{
		id: 20,
		image: '../assets/images/brands/topshop.webp'
	}
];

const Main = () => {
	const store = useContext(Context);
	const { categoriesStore } = store.rootStore;

	// useEffect(() => {
	// 	const handleFetchCategories = async () => {
	// 		await categoriesStore.fetchCategories();
	// 	}

	// 	handleFetchCategories();
	// }, [categoriesStore]);

  // const [activeCategory, setActiveCategory] = useState(1);
  // // const size = useWindowSize();
	// // const [categories, setCategories] = useState([]);
  // // const { t } = useTranslation();

  // const handleClickCategory = (id) => {
  //   setActiveCategory(id);
  // }

  return (
    <Layout>
			<TopBar categoriesStore={categoriesStore} />
			<MainSlider />
			<Container maxWidth='xmg' sx={{ paddingLeft: { xxs: 0, def: '24px' }, paddingRight: { xxs: 0, def: '24px' } }}>
				<Grid container>
					{ /* <Grid item sx={{ display: { xxs: 'none', def: 'block' }, pr: 3 }} xxs={12} def={2}>
						{ !isMobile && <ErrorBoundary fallback={<div>Something went wrong</div>}><LeftBar /></ErrorBoundary> }
					</Grid> */ }
					<Box sx={{ def: { pl: 3 }, width: '100%' }}>

						<Box sx={{ mt: 4, pl: 2, pr: 2 }}>
							<Typography sx={{ fontWeight: 700, fontSize: { xxs: '1.5rem', def: '2.2rem' } }}>Категорії</Typography>
							<Categories categoriesStore={categoriesStore} />
              {
                isMobile ? (
                  <>
                    <Box display="grid" gridTemplateColumns={{ xxs: 'repeat(2, 1fr)', sm: 'repeat(3, 2fr)', lg: 'repeat(4, 1fr)' }} gap={{ xxs: 2, def: 4 }} sx={{ mt: { xxs: 2, def: 6 } }}>
                      {
                        firstArrayCatProd.map((prod) => (
                          <ProductCard key={prod.id} productInfo={prod} />
                        ))
                      }
                    </Box>
                    <Grid container sx={{ mt: 2, mb: 2 }}>
                      <CustomSlider title='Топ продавці' arrayFromSlider={topSellers} sliderType='sellers' />
                    </Grid>
                    <Box display="grid" gridTemplateColumns={{ xxs: 'repeat(2, 1fr)', sm: 'repeat(3, 2fr)', lg: 'repeat(4, 1fr)' }} gap={{ xxs: 2, def: 4 }} sx={{ mt: { xxs: 2, def: 6 } }}>
                      {
                        secondArrayCatProd.map((prod) => (
                          <ProductCard key={prod.id} productInfo={prod} />
                        ))
                      }
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                      <Button disableRipple className='slider-products__show-more' variant="contained">Більше</Button>
                    </Box>
                  </>
                ) : (
                  <Box display="grid" gridTemplateColumns={{ xxs: 'repeat(2, 1fr)', sm: 'repeat(3, 2fr)', lg: 'repeat(5, 1fr)' }} gap={{ xxs: 2, def: 4 }} sx={{ mt: { xxs: 2, def: 6 } }}>
                    {
                      categoryProductsInfo.map((prod) => (
                        <ProductCard key={prod.id} productInfo={prod} />
                      ))
                    }
                  </Box>
                )
              }
						</Box>
					</Box>
				</Grid>
        {/* <Grid container sx={{ mt: { xxs: 3, def: 6 }, mb: { xxs: 3, def: 6 } }}>
          <CustomSlider title='Бренди' arrayFromSlider={brandsImages} sliderType='brands' />
        </Grid> */}
			</Container>
      {
        !isMobile && (
          <>
            <Grid sx={{ mt: 6, mb: 6 }}>
              <CustomSlider title='Рекомендуємо' arrayFromSlider={recommendedProducts} sliderType='recommended' />
            </Grid>
            <Container maxWidth='mg'>
              <Grid container>
                {/* <Grid item sx={{ display: { xxs: 'none', lg: 'block' }, pr: 3 }} xxs={12} lg={2}></Grid> */}
                <Grid sx={{ lg: { pl: 3 }, width: '100%' }}>
                  <SliderOfProducts title='Популярні товари' productsInfo={categoryProductsInfo} />
                </Grid>
              </Grid>
            </Container>
            <Grid container sx={{ mt: 6, mb: 6 }}>
              <CustomSlider title='Топ продавці' arrayFromSlider={topSellers} sliderType='sellers' />
            </Grid>
            <Container maxWidth='mg'>
              <Grid container>
                {/* <Grid item sx={{ display: { xxs: 'none', lg: 'block' }, pr: 3 }} xxs={12} lg={2}></Grid> */}
                <Grid sx={{ lg: { pl: 3 }, width: '100%' }}>
                  <SliderOfProducts title='Новинки' productsInfo={categoryProductsInfo} />
                </Grid>
              </Grid>
            </Container>
            {/* <Grid container sx={{ mt: 6, mb: 6 }}>
              <CustomSlider title='Бренди' arrayFromSlider={brandsImages} sliderType='brands' />
            </Grid> */}
          </>
        )
      }
      <Grid container sx={{ mt: { xxs: 3, def: 6 }, mb: { xxs: 3, def: 6 } }}>
        <CustomSlider title='Бренди' arrayFromSlider={brandsImages} sliderType='brands' />
      </Grid>
    </Layout>
  )
}

export default observer(Main);
