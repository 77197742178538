import React, { useContext } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';

import { Context } from '../..';

const SearchBlock = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 25,
    backgroundColor: 'white',
    border: '1px solid #ebebeb',
    // marginLeft: theme.spacing(1),
    // width: '500px',
    // display: 'none',
    [theme.breakpoints.up('def')]: {
        marginLeft: theme.spacing(4),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
		right: 0,
		top: '-50%',
		transform: 'translateY(50%)',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
		zIndex: 99
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 0, 1, 2),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(3)})`,
        width: '100%',
        color: '#35322e'
    }
}));

const Search = () => {
	const store = useContext(Context);
	const { searchStore } = store.rootStore;
  return (
    <form>
        <SearchBlock>
					<StyledInputBase
						placeholder="Пошук…"
						inputProps={{ 'aria-label': 'search' }}
						value={searchStore.query}
						onChange={(e) => searchStore.setQuery(e.target.value)}
					/>
					<SearchIconWrapper onClick={() => searchStore.handleSearch()}>
						<SearchIcon color="action" />
					</SearchIconWrapper>
        </SearchBlock>
    </form>
  )
}

export default observer(Search);