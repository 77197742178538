import React from 'react'
import { Box } from '@mui/system'

import Layout from '../components/layout'

const NoPage = () => {
  return (
    <Layout>
        <Box sx={{ mt: 3 }}>404</Box>
    </Layout>
  )
}

export default NoPage