import React from 'react';
import ImageLoader from '../../../image-loader';

import styles from './Mobile.module.scss';

const MainSliderItemMobile = ({ objectImage }) => {
  return (
    <div className={styles['slider-mobile__block']}>
        <ImageLoader src={objectImage.image} alt={`item-slider-${objectImage.id}`} />
        {/* <img src={objectImage.image} alt={`item-slider-${objectImage.id}`} /> */}
    </div>
  )
}

export default MainSliderItemMobile