import { makeAutoObservable, toJS } from "mobx";
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';

class OrdersSeller {
	constructor () {
		makeAutoObservable(this);
	}

	initialOrders = [
		{
			id: 1,
			orderNumber: 756709,
			orderDate: '23.02.2022',
			products: [
				'../assets/images/product-card/prod_front2.webp',
				'../assets/images/product-card/prod_front3.webp'
			],
			price: 1213,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '2'
		},
		{
			id: 2,
			orderNumber: 756710,
			orderDate: '23.02.2022',
			products: [
				'../assets/images/product-card/prod_front2.webp',
				'../assets/images/product-card/prod_front3.webp'
			],
			price: 2313,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '3'
		},
		{
			id: 3,
			orderNumber: 756711,
			orderDate: '23.02.2023',
			products: [
				'../assets/images/product-card/prod_front2.webp',
				'../assets/images/product-card/prod_front3.webp'
			],
			price: 213,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '3'
		},
		{
			id: 4,
			orderNumber: 756712,
			orderDate: '23.03.2022',
			products: [
				'../assets/images/product-card/prod_front2.webp',
				'../assets/images/product-card/prod_front3.webp'
			],
			price: 1013,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '2'
		},
		{
			id: 5,
			orderNumber: 756713,
			orderDate: '12.10.2021',
			products: [
				'../assets/images/product-card/prod_front7.webp',
				'../assets/images/product-card/prod_front6.webp'
			],
			price: 3013,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '4'
		},
		{
			id: 6,
			orderNumber: 756714,
			orderDate: '19.04.2022',
			products: [
				'../assets/images/product-card/prod_front3.webp',
				'../assets/images/product-card/prod_front6.webp'
			],
			price: 1113,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '3'
		},
		{
			id: 7,
			orderNumber: 756715,
			orderDate: '03.12.2023',
			products: [
				'../assets/images/product-card/prod_front2.webp',
				'../assets/images/product-card/prod_front1.webp'
			],
			price: 1213,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '2'
		},
		{
			id: 8,
			orderNumber: 756716,
			orderDate: '10.06.2022',
			products: [
				'../assets/images/product-card/prod_front2.webp',
				'../assets/images/product-card/prod_front3.webp'
			],
			price: 2013,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '3'
		},
		{
			id: 9,
			orderNumber: 756717,
			orderDate: '15.07.2022',
			products: [
				'../assets/images/product-card/prod_front4.webp',
				'../assets/images/product-card/prod_front7.webp'
			],
			price: 1013,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '4'
		},
		{
			id: 10,
			orderNumber: 756718,
			orderDate: '24.09.2023',
			products: [
				'../assets/images/product-card/prod_front1.webp',
				'../assets/images/product-card/prod_front5.webp'
			],
			price: 1013,
			fullName: 'Зефір Зефіркович Зефіркин',
			phone: '+38068888888',
			status: '2'
		}
	]

	orders = this.initialOrders;

	statuses = [
		{
			id: 1,
			caption: 'Всі статуси'
		},
		{
			id: 2,
			caption: 'Новий'
		},
		{
			id: 3,
			caption: 'Доставлений'
		},
		{
			id: 4,
			caption: 'Не доставлений'
		}
	]

	dateRanges = [
		{
			id: 1,
			key: 'today',
			label: 'today',
			caption: 'Сьогодні',
			value: {
				startDate: startOfDay(new Date()),
				endDate: endOfDay(new Date()),
				key: 'selection'
			}
		},
		{
			id: 2,
			key: 'yesterday',
			label: 'yesterday',
			caption: 'Вчора',
			value: {
				startDate: startOfDay(addDays(new Date(), -1)),
				endDate: endOfDay(addDays(new Date(), -1)),
				key: 'selection'
			}
		},
		{
			id: 3,
			key: 'last7Days',
			label: 'last7Days',
			caption: '7 днів',
			value: {
				startDate: startOfDay(subDays(new Date(), 6)),
				endDate: endOfDay(new Date()),
				key: 'selection'
			}
		},
		{
			id: 4,
			key: 'last30Days',
			label: 'last30Days',
			caption: '30 днів',
			value: {
				startDate: startOfDay(subDays(new Date(), 29)),
				endDate: endOfDay(new Date()),
				key: 'selection'
			}
		},
		{
			id: 5,
			key: 'last90Days',
			label: 'last90Days',
			caption: '90 днів',
			value: {
				startDate: startOfDay(subDays(new Date(), 89)),
				endDate: endOfDay(new Date()),
				key: 'selection'
			}
		},
		{
			id: 6,
			key: 'customRange',
			label: 'customRange',
			caption: 'Задати діапазон дат',
			value: {
				startDate: startOfDay(new Date()),
				endDate: endOfDay(new Date()),
				key: 'selection'
			}
		}
	]

	// anchorEl = {
	// 	status: null,
	// 	date: null
	// }

	selectStatus = 1;

	selectDate = 1;

	// statusDate = {
	// 	status: 1,
	// 	date: this.dateRanges[0].id
	// }

	// open = {
	// 	status: false,
	// 	date: false
	// }

	dateValues = [this.dateRanges.find(range => range.id === this.selectDate).value]

	setDateValues = (item) => {
		this.dateValues = item;
		console.log(toJS(this.dateValues));
	}

	setOrders = (orders) => {
		this.orders = orders;
	}

	setStatuses = (statuses) => {
		this.statuses = statuses;
	}

	setSelectStatus = (selectStatus) => {
		this.selectStatus = selectStatus;
		if (Number(selectStatus) === 1) {
			this.setOrders(this.initialOrders);
		} else {
			const newOrders = this.initialOrders.filter(order => Number(order.status) === Number(selectStatus));
		this.setOrders(newOrders);
		}
		console.log(this.selectStatus);
	}

	setSelectDate = (selectDate) => {
		this.selectDate = selectDate;
		console.log(this.selectDate);
	}

	setAnchorEl = (currentTarget, typeButton) => {
		this.anchorEl[typeButton] = currentTarget;
	}

	setOpen = (typeButton) => {
		this.open[typeButton] = !this.open[typeButton];
	}

	handleToggle = (event) => {
		const typeButton = event.currentTarget.dataset.typebutton;
		const currentTarget = event.currentTarget;
    this.setAnchorEl(currentTarget, typeButton);
		this.setOpen(typeButton);
  }

	handleClose = (typeButton) => {
    this.setOpen(typeButton);
  }
}

export default OrdersSeller;
