import { useState } from 'react';
import { Button, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import clsx from 'clsx';

import ImageLoader from '../image-loader';

import useWindowSize from '../../hooks/window-size';

import styles from './ProductCard.module.scss';

const ProductCard = ({ productInfo }) => {
    const size = useWindowSize();
	const [isFavorite, setIsFavorite] = useState(false);

	const favClick = () => {
		setIsFavorite(!isFavorite);
	}

  return (
    <div className={styles['product-card']}>
        <ImageLoader className={clsx(styles['product-card__images'], styles['product-card__front'])} src={productInfo.imgFront} alt="" />
        {/* <img className={clsx(styles['product-card__images'], styles['product-card__front'])} src={productInfo.imgFront} alt="" /> */}
        { /*
            size.width > 1023 ? (
                <div className={styles['product-card__back']}>
                    <img className={styles['product-card__images']} src={productInfo.imgBack} alt="" />
                    <div className={styles['product-card__title']}>{productInfo.title}</div>
                    <div className={styles['product-card__bottom']}>
                        <div className={styles['product-card__price']}>{productInfo.price}</div>
                        <div className={styles['product-card__buttons']}>
                            <Button className={styles['product-card__buy']} disableRipple>Додати в кошик</Button>
                            <IconButton className={styles['product-card__favorite']} disableRipple onClick={favClick}>
                                { isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
                            </IconButton>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className={styles['product-card__mobile-bottom']}>
                        <div className={styles['product-card__mobile-bottom-title']}>{productInfo.title}</div>
                        <div className={styles['product-card__mobile-bottom-description']}>{productInfo.description}</div>
                        <div className={styles['product-card__mobile-bottom-other']}>
                            <div>{productInfo.options}</div>
                            <div>{productInfo.price}</div>
                        </div>
                    </div>
                    <IconButton className={styles['product-card__favorite']} disableRipple onClick={favClick}>
                        { isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
                    </IconButton>
                </>
            )
			*/ }
			<>
				<div className={styles['product-card__mobile-bottom']}>
					<div className={styles['product-card__mobile-bottom-title']}>{productInfo.title}</div>
					<div className={styles['product-card__mobile-bottom-description']}>{productInfo.description}</div>
					<div className={styles['product-card__mobile-bottom-other']}>
						<div>{productInfo.options}</div>
						<div>{productInfo.price}</div>
					</div>
				</div>
				<IconButton className={styles['product-card__favorite']} disableRipple onClick={favClick}>
					{ isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
				</IconButton>
			</>
    </div>
  )
}

export default ProductCard
