import React from 'react'
import { Box, Grid, Stack } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from 'clsx';

import useWindowSize from '../../../hooks/window-size';
import MainSliderItemDesktop from '../main-slider-item/desktop';
import MainSliderItemMobile from '../main-slider-item/mobile';

// import styles from './MainSlider.module.scss';
import './MainSlider.scss';

const arrayImagesFromDesktop = [
  [
    {
      id: 1,
      title: 'Сукні',
      url: '#',
      image: '../assets/images/main-slider/main-slider-1.webp'
    },
    {
      id: 2,
      title: 'Топи',
      url: '#',
      image: '../assets/images/main-slider/main-slider-2.webp'
    },
    {
      id: 3,
      title: 'Джинси',
      url: '#',
      image: '../assets/images/main-slider/main-slider-3.webp'
    }
  ],
  [
    {
      id: 1,
      title: 'Топи',
      url: '#',
      image: '../assets/images/main-slider/main-slider-2.webp'
    },
    {
      id: 2,
      title: 'Джинси',
      url: '#',
      image: '../assets/images/main-slider/main-slider-3.webp'
    },
    {
      id: 3,
      title: 'Сукні',
      url: '#',
      image: '../assets/images/main-slider/main-slider-1.webp'
    }
  ]
];

const arrayImagesFromMobSlider = [
  {
    id: 1,
    url: '#',
    image: '../assets/images/main-slider/main-slider-1.webp'
  },
  {
    id: 2,
    url: '#',
    image: '../assets/images/main-slider/main-slider-2.webp'
  },
  {
    id: 3,
    url: '#',
    image: '../assets/images/main-slider/main-slider-3.webp'
  }
];

const MainSlider = () => {

  const size = useWindowSize();

  const settingsDesktop = {
    fade: true,
    infinite: true,
    speed: 1500,
    // autoplay: true,
    // autoplaySpeed: 3000,
    slidesToShow: 1,
    arrows: false,
    pauseOnHover: true
  };

  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    arrows: false,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const settings = size.width > 1023 ? settingsDesktop : settingsMobile;

  return (
    <>
      <Grid container className='main-slider'>
        <Box sx={{ width: '100%' }}>
          <Slider {...settings}>
            {
              size.width > 1023 ? (
                arrayImagesFromDesktop.map((array, index) => (
                  <MainSliderItemDesktop key={index} arrayImages={array} />
                ))
              ) : (
                arrayImagesFromMobSlider.map(item => (
                  <MainSliderItemMobile objectImage={item} key={item.id} />
                ))
              )
            }
          </Slider>
        </Box>
      </Grid>
    </>
  )
}

export default MainSlider
