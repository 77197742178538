import React, { useState } from 'react'
import { Box, Grid, Typography, IconButton, Button, TextField, Container, FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tabs } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SendIcon from '@mui/icons-material/Send';
import { StyledEngineProvider } from '@mui/material/styles';
import styled from '@emotion/styled';
import Rating from '@mui/material/Rating';

import Layout from '../../components/layout'
import BreadcrumbsPage from '../../components/breadcrumbs';
// import ProductSlider from '../../components/product-slider';
import ProductProperty from '../../components/product-property';
import ProductSlickSlider from '../../components/product-slick-slider';
// import ProductGallery from '../../components/product-gallery';
import CustomSlider from '../../components/custom-slider';
import SliderOfProducts from '../../components/slider-of-products';
import ProductCard from '../../components/product-card';

import styles from './ProductPage.module.scss';

import { novaPoshta, ukrPoshta, deliveryReturn, securePayment, sellerCard, cashMoney } from '../../assets/svg';

const breadcrumbs = [
	{
		label: 'Головна',
		url: '/'
	},
	{
		label: 'Жінкам',
		url: '/women'
	},
	{
		label: 'Взуття',
		url: '/women/zhenskaya-obuv'
	},
	{
		label: 'Кеди',
		url: '/women/zhenskaya-obuv/kedy'
	}
];

const WriteMessageTextarea = styled(TextField)({
	width: '100%',
	'& .MuiFilledInput-root': {
		borderRadius: '5px',
		border: '1px solid #bdbdbd'
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: '#424242'
	}
});

const imagesSwiper = [
	'../assets/images/kedy1.jpg',
	'../assets/images/kedy2.jpg',
	'../assets/images/kedy3.jpg',
	'../assets/images/kedy4.jpg',
	'../assets/images/kedy5.jpg',
	'../assets/images/kedy6.jpg',
	'../assets/images/kedy7.jpg'
]

const imagesSlickSlider = [
	{
		itemImageSrc: '../assets/images/kedy1.jpg',
		thumbnailImageSrc: '../assets/images/kedy1.jpg',
		alt: 'Description for Image 1',
		title: 'Title 1'
	},
	{
		itemImageSrc: '../assets/images/kedy2.jpg',
		thumbnailImageSrc: '../assets/images/kedy2.jpg',
		alt: 'Description for Image 2',
		title: 'Title 2'
	},
	{
		itemImageSrc: '../assets/images/kedy3.jpg',
		thumbnailImageSrc: '../assets/images/kedy3.jpg',
		alt: 'Description for Image 3',
		title: 'Title 3'
	},
	{
		itemImageSrc: '../assets/images/kedy4.jpg',
		thumbnailImageSrc: '../assets/images/kedy4.jpg',
		alt: 'Description for Image 4',
		title: 'Title 4'
	},
	{
		itemImageSrc: '../assets/images/kedy5.jpg',
		thumbnailImageSrc: '../assets/images/kedy5.jpg',
		alt: 'Description for Image 5',
		title: 'Title 5'
	},
	{
		itemImageSrc: '../assets/images/kedy6.jpg',
		thumbnailImageSrc: '../assets/images/kedy6.jpg',
		alt: 'Description for Image 6',
		title: 'Title 6'
	}
]

const images = [
	{
		original: '../assets/images/kedy1.jpg',
		thumbnail: '../assets/images/kedy1.jpg'
	},
	{
		original: '../assets/images/kedy2.jpg',
		thumbnail: '../assets/images/kedy2.jpg'
	},
	{
		original: '../assets/images/kedy3.jpg',
		thumbnail: '../assets/images/kedy3.jpg'
	},
	{
		original: '../assets/images/kedy4.jpg',
		thumbnail: '../assets/images/kedy4.jpg'
	},
	{
		original: '../assets/images/kedy5.jpg',
		thumbnail: '../assets/images/kedy5.jpg'
	},
	{
		original: '../assets/images/kedy6.jpg',
		thumbnail: '../assets/images/kedy6.jpg'
	}
]

const productProperties = [
	{
		propertyName: 'Розмір',
		propertyValue: 'L/44',
		propertyType: 'text'
	},
	{
		propertyName: 'Стан',
		propertyValue: 'Новий',
		propertyType: 'text'
	},
	{
		propertyName: 'Колір',
		typeProperty: 'color',
		propertyValue: [
			{
				propertyName: '',
				propertyValue: '#715696'
			},
			{
				propertyName: '',
				propertyValue: '#ededed'
			}
		],
		propertyType: 'media'
	},
	{
		propertyName: 'Параметри',
		propertyValue: [
			{
				propertyName: 'Напівобхват грудей',
				propertyValue: '43 см'
			},
			{
				propertyName: 'Напівобхват талії',
				propertyValue: '39,5 см'
			},
			{
				propertyName: 'Напівобхват стегон',
				propertyValue: '39,5 см'
			}
		],
		propertyType: 'text'
	}
];

const productColors = [
	{
		id: 1,
		caption: 'Темно синій',
		value: '#2149b2'
	},
	{
		id: 2,
		caption: 'Бірюзовий',
		value: '#21b2a1'
	}
];

const productSizes = [
	{
		id: 1,
		caption: 'S',
		value: 's'
	},
	{
		id: 2,
		caption: 'M',
		value: 'm'
	},
	{
		id: 3,
		caption: 'L',
		value: 'l'
	}
];

const recommendedProducts = [
  {
    id: 1,
    image: '../assets/images/product-card/prod_front1.webp',
    title: 'Топ з відкритими плечима',
    price: '33 ₴',
    priceOld: '100 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 0
          },
          {
            id: 2,
            value: 'M',
            isActive: 1
          },
          {
            id: 3,
            value: 'L',
            isActive: 0
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#316586',
            isActive: 1
          },
          {
            id: 2,
            value: '#ffb1bf',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 2,
    image: '../assets/images/product-card/prod_front2.webp',
    title: 'Сукня міді в рубчик',
    price: '93 ₴',
    priceOld: '100 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 1
          },
          {
            id: 2,
            value: 'M',
            isActive: 0
          },
          {
            id: 3,
            value: 'L',
            isActive: 0
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#FFB1BF',
            isActive: 1
          },
          {
            id: 2,
            value: '#FF8300',
            isActive: 0
          },
          {
            id: 3,
            value: '#FF5074',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 3,
    image: '../assets/images/product-card/prod_front4.webp',
    title: 'Джинси мом з високою посадкою',
    price: '97 ₴',
    priceOld: '120 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 0
          },
          {
            id: 2,
            value: 'M',
            isActive: 0
          },
          {
            id: 3,
            value: 'L',
            isActive: 1
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#316586',
            isActive: 1
          },
          {
            id: 2,
            value: '#FFB1BF',
            isActive: 0
          },
          {
            id: 3,
            value: '#FF8300',
            isActive: 0
          },
          {
            id: 4,
            value: '#FF5074',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 4,
    image: '../assets/images/product-card/prod_front6.webp',
    title: 'Топ з відкритими плечима',
    price: '33 ₴',
    priceOld: '100 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 0
          },
          {
            id: 2,
            value: 'M',
            isActive: 1
          },
          {
            id: 3,
            value: 'L',
            isActive: 0
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#316586',
            isActive: 1
          },
          {
            id: 2,
            value: '#ffb1bf',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 5,
    image: '../assets/images/product-card/prod_front5.webp',
    title: 'Сукня міді в рубчик',
    price: '93 ₴',
    priceOld: '100 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 1
          },
          {
            id: 2,
            value: 'M',
            isActive: 0
          },
          {
            id: 3,
            value: 'L',
            isActive: 0
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#FFB1BF',
            isActive: 1
          },
          {
            id: 2,
            value: '#FF8300',
            isActive: 0
          },
          {
            id: 3,
            value: '#FF5074',
            isActive: 0
          }
        ]
      }
    ]
  },
  {
    id: 6,
    image: '../assets/images/product-card/prod_front8.webp',
    title: 'Джинси мом з високою посадкою',
    price: '97 ₴',
    priceOld: '120 ₴',
    options: [
      {
        id: 1,
        name: 'Розмір',
        type: 'text',
        values: [
          {
            id: 1,
            value: 'S',
            isActive: 0
          },
          {
            id: 2,
            value: 'M',
            isActive: 0
          },
          {
            id: 3,
            value: 'L',
            isActive: 1
          },
          {
            id: 4,
            value: 'XL',
            isActive: 0
          }
        ]
      },
      {
        id: 2,
        name: 'Колір',
        type: 'media',
        values: [
          {
            id: 1,
            value: '#316586',
            isActive: 1
          },
          {
            id: 2,
            value: '#FFB1BF',
            isActive: 0
          },
          {
            id: 3,
            value: '#FF8300',
            isActive: 0
          },
          {
            id: 4,
            value: '#FF5074',
            isActive: 0
          }
        ]
      }
    ]
  }
];

const categoryProductsInfo = [
  {
    id: 1,
    title: 'Джинсовий костюм',
    description: 'Джинси блакитні з високою посадкою',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front1.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '930 ₴'
  },
  {
    id: 2,
    title: 'Біла сукня',
    description: 'Джинси блакитні з високою посадкою',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front2.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '780 ₴'
  },
  {
    id: 3,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front3.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '330 ₴'
  },
  {
    id: 4,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front4.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '350 ₴'
  },
  {
    id: 5,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front5.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '450 ₴'
  },
  {
    id: 6,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front6.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '620 ₴'
  },
  {
    id: 7,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front7.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '790 ₴'
  },
  {
    id: 8,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front8.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '240 ₴'
  }
];

const popularProductsInfo = {
  items: [
    {
      id: 1,
      title: 'Джинсовий костюм',
      description: 'Джинси блакитні з високою посадкою',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front1.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '930 ₴'
    },
    {
      id: 2,
      title: 'Біла сукня',
      description: 'Джинси блакитні з високою посадкою',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front2.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '780 ₴'
    },
    {
      id: 3,
      title: 'Джинсова сукня',
      description: 'Джинсова сукня з відкритими плечима',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front3.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '330 ₴'
    },
    {
      id: 4,
      title: 'Джинсова сукня',
      description: 'Джинсова сукня з відкритими плечима',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front4.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '350 ₴'
    },
    {
      id: 5,
      title: 'Джинсова сукня',
      description: 'Джинсова сукня з відкритими плечима',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front5.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '450 ₴'
    },
    {
      id: 6,
      title: 'Джинсова сукня',
      description: 'Джинсова сукня з відкритими плечима',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front6.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '620 ₴'
    },
    {
      id: 7,
      title: 'Джинсова сукня',
      description: 'Джинсова сукня з відкритими плечима',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front7.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '790 ₴'
    },
    {
      id: 8,
      title: 'Джинсова сукня',
      description: 'Джинсова сукня з відкритими плечима',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front8.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '240 ₴'
    },
    {
      id: 9,
      title: 'Джинсова сукня',
      description: 'Джинсова сукня з відкритими плечима',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front8.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '240 ₴'
    },
    {
      id: 10,
      title: 'Джинсова сукня',
      description: 'Джинсова сукня з відкритими плечима',
      options: '38/M/46',
      imgFront: '../assets/images/product-card/prod_front8.webp',
      imgBack: '../assets/images/product-card/prod_back.webp',
      price: '240 ₴'
    }
  ],
  countOfProducts: 12
};

const BpColor = styled('span')(({ color }) => ({
	backgroundColor: `${color}`,
	width: '25px',
	height: '25px',
	borderRadius: '50%',
	padding: '2px',
	outline: '1px solid transparent',
	transition: 'outline .2s, outlineOffset .2s',
	'input:hover ~ &': {
    outlineColor: '#6fcce5',
		outlineOffset: 2
  }
	// '@media screen and (min-width: 1024px)': {
	// 	width: '25px',
	// 	height: '25px',
	// }
}));

const BpColorChecked = styled(BpColor)(({ color }) => ({
	backgroundColor: `${color}`,
	'input:checked ~ &': {
    outlineColor: '#6fcce5',
		outlineOffset: 2
  }
}));

const BpText = styled('span')(({ text }) => ({
	'&:before': {
		position: 'absolute',
		content: `'${text}'`,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		color: '#000000',
		fontSize: '1.25rem',
		lineHeight: 1
	}
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProductPage = () => {
	const [isFavorite, setIsFavorite] = useState(false);
	const [selectProductColor, setSelectProductColor] = useState(1);
	const [selectProductSize, setSelectProductSize] = useState(1);
	const [tabValue, setTabValue] = useState(0);

  const changeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

	const changeProductColor = (event) => {
		setSelectProductColor(event.target.value);
	}

	const changeProductSize = (event) => {
		setSelectProductSize(event.target.value);
	}

	// const handleFavorite = useMemo(() => {
	// 	setIsFavorite(!isFavorite);
	// }, [isFavorite]);

	const handleFavorite = (e) => {
		e.preventDefault();
		setIsFavorite(!isFavorite);
	}

	const viewedSliderOptions = {
		rows: 1,
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	const popularSliderOptions = {
		rows: 2,
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	return (
		<StyledEngineProvider injectFirst>
			<Layout>
				<Container maxWidth='xmg'>
					<Box sx={{ width: '100%' }}>
						<BreadcrumbsPage items={breadcrumbs} />
					</Box>
					<Box sx={{ flexGrow: 1, mt: 2, mb: 2 }} className={styles['product-wrapper']}>
						<Grid container>
							<Grid item xxs={12} md={9}>
								<Grid container>
									<Grid item xxs={12} md={6} sx={{ position: 'relative', mb: { xxs: 4, md: 0 } }}>
										{/* <ProductSlider images={imagesSwiper} /> */}
										<ProductSlickSlider images={imagesSwiper} />
										{/* <ProductGallery images={images} /> */}
										<Box className={styles['product-favorite']} onClick={handleFavorite}>
											<IconButton disableRipple className={styles['product-favorite__button']}>
												{ isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
											</IconButton>
										</Box>
									</Grid>
									<Grid item xxs={12} md={6} sx={{ pl: { md: 3 }, width: '100%' }}>
										<Typography gutterBottom className={styles['product-title']}>Оригінал! кросівки кеди nike.</Typography>
										<Typography gutterBottom className={styles['product-subtitle']}>New Look</Typography>
										<Grid container sx={{ mt: 2 }}>
											<Grid item xs={6}>
												<Box className={styles['product-prices']}>
													<Box className={styles['product-prices__price']}>369₴</Box>
													<Box className={styles['product-prices__sale']}>649₴</Box>
												</Box>
											</Grid>
											{ /* <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Button disableRipple className={styles['button-buy']} variant="contained">Купити</Button>
											</Grid> */ }
										</Grid>
										<Box className={styles['product-properties']}>
											<Box sx={{ display: 'flex', mt: 2 }}>
												<Typography>Стан:</Typography>
												<Typography sx={{ ml: 1 }}>Новий</Typography>
											</Box>
											<Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
												<Box sx={{ display: 'flex' }}>
													<Typography>Колір:</Typography>
													<Typography sx={{ ml: 1 }}>{productColors.find(item => Number(item.id) === Number(selectProductColor)).caption}</Typography>
												</Box>
												<Box>
													<FormControl>
														<RadioGroup
															row
															name="color"
															className={styles['product-properties__colors']}
															value={selectProductColor}
															onChange={(e) => changeProductColor(e)}
														>
															{
																productColors.map(radio => (
																	<FormControlLabel
																		key={radio.id}
																		value={radio.id}
																		name='color'
																		control={
																			<Radio
																				className='radio-button'
																				disableRipple
																				icon={<BpColor color={radio.value} />}
																				checkedIcon={<BpColorChecked color={radio.value} />}
																			/>
																		}
																	/>
																))
															}
														</RadioGroup>
													</FormControl>
												</Box>
											</Box>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography>Розмір:</Typography>
												<Box sx={{ display: 'flex', alignItems: 'center' }}>
													<FormControl>
														<RadioGroup
															row
															name="size"
															value={selectProductSize}
															onChange={(e) => changeProductSize(e)}
															className={styles['product-properties__sizes']}
														>
															{
																productSizes.map(radio => (
																	<FormControlLabel
																		key={radio.id}
																		value={radio.id}
																		name='size'
																		control={
																			<Radio
																				className='radio-button'
																				disableRipple
																				icon={<BpText text={radio.caption} />}
																				checkedIcon={<BpText text={radio.caption} />}
																			/>
																		}
																		// className={styles['product-properties__sizes-label']}
																		className='radio-button__label-product-size'
																	/>
																))
															}
														</RadioGroup>
													</FormControl>
												</Box>
											</Box>
											<Box sx={{ mt: 2 }}>
												<Typography>Таблиця розмірів</Typography>
											</Box>
											<Box sx={{ display: 'flex', flexDirection: 'column', mt: 3, justifyContent: { xxs: 'center', def: 'flex-start' } }}>
												<Button className='button button-blue' variant="contained" disableRipple sx={{ maxWidth: 300 }}>Додати товар у кошик</Button>
												<Button className='button button-light' variant="contained" disableRipple sx={{ maxWidth: 300, mt: 2 }}>Написати продавцеві</Button>
											</Box>
										{ /*
											productProperties.map((property, index) => (
												<ProductProperty key={index} properties={property} />
											))
										*/ }
										</Box>
										{ /* <Box className={styles['product-write-message']}>
											<WriteMessageTextarea
												InputProps={{ disableUnderline: true }}
												id="outlined-multiline-static"
												label="Повідомлення"
												multiline
												rows={4}
												variant="filled"
											/>
											<Button disableRipple className={styles['product-write-message__button']} variant="contained" endIcon={<SendIcon />}>
												Відправити
											</Button>
										</Box> */ }
									</Grid>
								</Grid>
								<Box sx={{ width: '100%', mt: 2 }}>
									<Box>
										<Tabs value={tabValue} onChange={changeTabValue} TabIndicatorProps={{ hidden: true }}>
											<Tab label="Опис" className='tab tab-product-card' disableRipple />
											<Tab label="Склад" className='tab tab-product-card' disableRipple />
										</Tabs>
									</Box>
									<CustomTabPanel value={tabValue} index={0} className='tab-panel'>
										<ul>
											<li>склад головної тканини: 99% ПОЛІЕСТЕР, 1% МЕТАЛІЗОВАНЕ ВОЛОКНО</li>
											<li>ПРАТИ В ПРАЛЬНІЙ МАШИНІ ПРИ МАКС. ТЕМП.30°C - ПРОГРАМА ДЛЯ НІЖНИХ ТКАНИН</li>
											<li>НЕ ВІДБІЛЮВАТИ</li>
											<li>НЕ СУШИТИ В СУШАРЦІ БАРАБАННОГО ТИПУ</li>
											<li>ПРАСУВАТИ ПРИ МАКС. ТЕМП.110°C - БЕЗ ПАРИ</li>
											<li>НЕ ЧИСТИТИ ХІМІЧНО</li>
										</ul>
									</CustomTabPanel>
									<CustomTabPanel value={tabValue} index={1} className='tab-panel'>
										Item Two
									</CustomTabPanel>
								</Box>
							</Grid>
							{/* <Grid item xs={12} md={3}></Grid> */}

							<Grid item xxs={12} md={3} sx={{ pl: { md: 2 }, mt: { xxs: 3, md: 0 }, display: { xxs: 'none', def: 'flex' } }}>
								<Box className={styles['product-delivery']}>
									<Box className={styles['product-delivery__seller']}>
										<Box className={styles['product-delivery__seller-image']}>
											<img src="../assets/images/product-card/prod_front2.webp" alt="seller image" />
										</Box>
										<Box className={styles['product-delivery__seller-info']}>
											<Box className={styles['seller-name']}>Аліса Мілонська</Box>
											<Box className={styles['seller-rating']}>
												<Box className={styles['seller-rating__stars']}><Rating name="read-only" value='4.9' readOnly size='small' /></Box>
												<Box className={styles['seller-rating__value']}>4.9</Box>
											</Box>
										</Box>
									</Box>
									<Box className={styles['product-delivery__title']}>Спосіб доставки</Box>
									<Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
										<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
											<Grid item xxs={2} className={styles['product-delivery__icon']}>
												<img src={novaPoshta} alt="" />
											</Grid>
											<Grid item xxs={10} sx={{ ml: 2 }}>Нова пошта</Grid>
										</Grid>
										<Grid item sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
											<Grid item xxs={2} className={styles['product-delivery__icon']}>
												<img src={ukrPoshta} alt="" />
											</Grid>
											<Grid item xxs={10} sx={{ ml: 2 }}>Укрпошта</Grid>
										</Grid>
									</Box>

									<Box className={styles['product-delivery__title']} sx={{ mt: 2 }}>Оплата:</Box>
									<Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
										<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
											<Grid item xxs={2} className={styles['product-delivery__icon']}>
												<img src={securePayment} alt="" />
											</Grid>
											<Grid item xxs={10} sx={{ ml: 2 }}>Безпечна оплата</Grid>
										</Grid>
										<Grid item sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
											<Grid item xxs={2} className={styles['product-delivery__icon']}>
												<img src={cashMoney} alt="" />
											</Grid>
											<Grid item xxs={10} sx={{ ml: 2 }}>Накладений платіж</Grid>
										</Grid>
										<Grid item sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
											<Grid item xxs={2} className={styles['product-delivery__icon']}>
												<img src={sellerCard} alt="" />
											</Grid>
											<Grid item xxs={10} sx={{ ml: 2 }}>Продавцю на карту або готівкою</Grid>
										</Grid>
									</Box>

									<Box className={styles['product-delivery__title']} sx={{ mt: 2 }}>Повернення:</Box>
									<Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
										<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
											<Grid item xxs={2} className={styles['product-delivery__icon']}>
												<img src={deliveryReturn} alt="" />
											</Grid>
											<Grid item xxs={10} sx={{ ml: 2 }}>Протягом 14 днів після отримання товару</Grid>
										</Grid>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
				{
					Array.isArray(recommendedProducts) && recommendedProducts.length > 0 && (
						<Grid sx={{ mt: 6, mb: 6 }}>
							<CustomSlider title='Рекомендуємо' arrayFromSlider={recommendedProducts} sliderType='recommended' />
						</Grid>
					)
				}
				<Container maxWidth='xmg'>
					<Grid container>
						{
							Array.isArray(categoryProductsInfo) && categoryProductsInfo.length > 0 && (
								<Grid sx={{ lg: { pl: 3 }, width: '100%' }}>
									<SliderOfProducts
										title='Переглянуті'
										productsInfo={categoryProductsInfo}
										customSliderSettings={viewedSliderOptions}
										showButtomMore={false}
									/>
								</Grid>
							)
						}
						{
							Array.isArray(popularProductsInfo.items) && popularProductsInfo.items.length > 0 && (
								<Grid sx={{ lg: { pl: 3 }, pt: 4, width: '100%', mt: 2 }}>
									<Box className={styles['product-popular']}>
										<Box className={styles['product-popular__title']}>Популярні</Box>
										<Box className={styles['product-popular__products']}>
											{
												popularProductsInfo.items.map((prod) => (
													<ProductCard productInfo={prod} key={prod.id} />
												))
											}
										</Box>
										{
											popularProductsInfo.countOfProducts > 10 && (
												<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
													<Button disableRipple className='button button-blue' variant="contained">Більше</Button>
												</Box>
											)
										}
									</Box>
								</Grid>
							)
						}
					</Grid>
				</Container>
			</Layout>
		</StyledEngineProvider>
	)
}

export default ProductPage
