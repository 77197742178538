import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import styles from './MySizesBlock.module.scss';

const SizeItem = ({ caption, sizes, onEditSize, onDeleteSize }) => {
	return (
		<Box className={styles['size-item']}>
			<Grid container className={styles['size-item__item']}>
				<Grid item xxs={12} ms={3} className={styles['size-item__name']}>{caption}</Grid>
				<Grid item xxs={12} ms={9} className={styles['size-item__limits']}>
					{
						sizes.map(size => (
							<Box className={styles['size-item__size']} key={size.key}>
								<Box className={styles['name']}>{size.caption}</Box>
								<Box className={styles['limits']}>{size.limits.map(limit => limit.value).join(' - ')}</Box>
							</Box>
						))
					}
				</Grid>
			</Grid>
			<Box className={styles['size-item__function']}>
				<IconButton disableRipple className={styles['button-function']} onClick={onEditSize}><EditRoundedIcon /></IconButton>
				<IconButton disableRipple className={styles['button-function']} onClick={onDeleteSize}><DeleteRoundedIcon /></IconButton>
			</Box>
		</Box>
	)
}

export default SizeItem;
