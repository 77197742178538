import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import withAuth from '../../../hoc/withAuth';
import { Context } from '../../../..';

import styles from './SubscriptionBlock.module.scss';

const SubscriptionBlock = () => {
	return (
		<Box className={styles['subscription']}>
			<Box className={styles['subscription-title']}>Мої підписки</Box>
			<Box className={styles['subscription-list']}>
				<Box className={styles['subscription-list__item']}>
					<Box className={styles['item-image']}>
						<img src="../assets/images/product-card/prod_front2.jpg" />
					</Box>
					<Box className={styles['item-description']}>
						<Box>
							<Box className={styles['user-name']}>Аліса Мілонська</Box>
							<Box className={styles['shop-name']}>Магазин вінтажних речей</Box>
						</Box>
						<Box sx={{ ml: { xxs: 2, md: 0 } }}>
							<Button className={clsx('button button-blue', styles['button-unsubscribe'])} variant='contained' disableRipple sx={{ alignSelf: 'start' }}>Відписатися</Button>
						</Box>
					</Box>
				</Box>
				<Box className={styles['subscription-list__item']}>
					<Box className={styles['item-image']}>
						<img src="../assets/images/product-card/prod_front2.jpg" />
					</Box>
					<Box className={styles['item-description']}>
						<Box>
							<Box className={styles['user-name']}>Аліса Мілонська</Box>
							<Box className={styles['shop-name']}>Магазин вінтажних речей</Box>
						</Box>
						<Box sx={{ ml: { xxs: 2, md: 0 } }}>
							<Button className={clsx('button button-blue', styles['button-unsubscribe'])} variant='contained' disableRipple sx={{ alignSelf: 'start' }}>Відписатися</Button>
						</Box>
					</Box>
				</Box>
				<Box className={styles['subscription-list__item']}>
					<Box className={styles['item-image']}>
						<img src="../assets/images/product-card/prod_front2.jpg" />
					</Box>
					<Box className={styles['item-description']}>
						<Box>
							<Box className={styles['user-name']}>Аліса Мілонська</Box>
							<Box className={styles['shop-name']}>Магазин вінтажних речей</Box>
						</Box>
						<Box sx={{ ml: { xxs: 2, md: 0 } }}>
							<Button className={clsx('button button-blue', styles['button-unsubscribe'])} variant='contained' disableRipple sx={{ alignSelf: 'start' }}>Відписатися</Button>
						</Box>
					</Box>
				</Box>
				<Box className={styles['subscription-list__item']}>
					<Box className={styles['item-image']}>
						<img src="../assets/images/product-card/prod_front2.jpg" />
					</Box>
					<Box className={styles['item-description']}>
						<Box>
							<Box className={styles['user-name']}>Аліса Мілонська</Box>
							<Box className={styles['shop-name']}>Магазин вінтажних речей</Box>
						</Box>
						<Box sx={{ ml: { xxs: 2, md: 0 } }}>
							<Button className={clsx('button button-blue', styles['button-unsubscribe'])} variant='contained' disableRipple sx={{ alignSelf: 'start' }}>Відписатися</Button>
						</Box>
					</Box>
				</Box>
				<Box className={styles['subscription-list__item']}>
					<Box className={styles['item-image']}>
						<img src="../assets/images/product-card/prod_front2.jpg" />
					</Box>
					<Box className={styles['item-description']}>
						<Box>
							<Box className={styles['user-name']}>Аліса Мілонська</Box>
							<Box className={styles['shop-name']}>Магазин вінтажних речей</Box>
						</Box>
						<Box sx={{ ml: { xxs: 2, md: 0 } }}>
							<Button className={clsx('button button-blue', styles['button-unsubscribe'])} variant='contained' disableRipple sx={{ alignSelf: 'start' }}>Відписатися</Button>
						</Box>
					</Box>
				</Box>
				<Box className={styles['subscription-list__item']}>
					<Box className={styles['item-image']}>
						<img src="../assets/images/product-card/prod_front2.jpg" />
					</Box>
					<Box className={styles['item-description']}>
						<Box>
							<Box className={styles['user-name']}>Аліса Мілонська</Box>
							<Box className={styles['shop-name']}>Магазин вінтажних речей</Box>
						</Box>
						<Box sx={{ ml: { xxs: 2, md: 0 } }}>
							<Button className={clsx('button button-blue', styles['button-unsubscribe'])} variant='contained' disableRipple sx={{ alignSelf: 'start' }}>Відписатися</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box sx={{ mt: { xxs: 2, md: 4 }, display: 'flex', justifyContent: 'center' }}>
				<Button className={clsx('button button-blue', styles['show-more'])} variant='contained' disableRipple>Більше</Button>
			</Box>
		</Box>
	)
}

export default  withAuth(observer(SubscriptionBlock));
