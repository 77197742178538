import React, { useEffect, useContext, useState, useMemo } from 'react';
import { Box, Stack, Button, InputLabel, OutlinedInput, FormHelperText, FormControl, Grid, RadioGroup, FormControlLabel, Radio, MenuItem, Select, InputAdornment, FormGroup, Checkbox, IconButton, Autocomplete, TextField } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { FormProvider, useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaAddProduct } from '../../../validator';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
// import InputAdornment from '@mui/material/InputAdornment';
// import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

import withAuth from '../../../hoc/withAuth';

import CategoryList from './CategoryList';
// import CategoryListPopup from './CategoryListPopup';

// import LoadingSpinner from '../../../loading-spinner';
import { Context } from '../../../..';

import styles from './AddProductBlock.module.scss';
// import AddImage from './AddImage';
import AddFiles from './AddFiles';
// const jumper = '../assets/images/svg/jumper.svg';

const check = '../assets/images/svg/check.svg'

const BpIcon = styled('span')(({ svgOrText, typeProp }) => {
	let obj = {};

	if (typeProp === 'svg') {
		obj = {
			background: `url('${svgOrText}') no-repeat center`,
			backgroundSize: '65px',
			width: '100%',
			height: '100%'
		}
	} else if (typeProp === 'text') {
		obj = {
			'&:before': {
				position: 'absolute',
				content: `'${svgOrText}'`,
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				textAlign: 'center'
			}
		}
	}

	return obj;
});

const BpText = styled('span')(({ text }) => ({
	'&:before': {
		position: 'absolute',
		content: `'${text}'`,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		color: '#35322e'
	}
}));

const BpColor = styled('span')(({ color }) => ({
	backgroundColor: `${color}`,
	width: '30px',
	height: '30px',
	borderRadius: '50%',
	padding: '2px',
	outline: '1px solid transparent',
	transition: 'outline .2s, outlineOffset .2s',
	'input:hover ~ &': {
    outlineColor: '#6fcce5',
		outlineOffset: 2
  },
	'@media screen and (min-width: 1024px)': {
		width: '50px',
		height: '50px',
	}
}));

const BpColorChecked = styled(BpColor)(({ color }) => ({
	backgroundColor: `${color}`,
	'input:checked ~ &': {
    outlineColor: '#6fcce5',
		outlineOffset: 2
  }
}));

const BpCheckbox = styled('span')({
	width: '32px',
	height: '32px',
	border: '2px solid #9e9e9e',
	transition: 'border-color .2s',
	'input:hover ~ &': {
		borderColor: '#616161'
	}
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#6fcce5',
  '&:before': {
    display: 'block',
		width: '32px',
		height: '32px',
    background: `url(${check}) no-repeat center`,
		backgroundSize: '32px',
    content: '""',
  }
});

// const radioSections = [
// 	{
// 		id: 1,
// 		svg: '../assets/images/svg/jumper.svg',
// 		value: 'jumper'
// 	},
// 	{
// 		id: 2,
// 		svg: '../assets/images/svg/blouse.svg',
// 		value: 'blouse'
// 	},
// 	{
// 		id: 3,
// 		svg: '../assets/images/svg/trousers.svg',
// 		value: 'trouser'
// 	}
// ];

const radioSizes = [
	{
		id: 1,
		caption: 'XXS/40',
		value: 'xxs_40'
	},
	{
		id: 2,
		caption: 'XS/42',
		value: 'xs_42'
	},
	{
		id: 3,
		caption: 'S/40',
		value: 's_40'
	},
	{
		id: 4,
		caption: 'M/42',
		value: 'm_42'
	},
	{
		id: 5,
		caption: 'L/42',
		value: 'l_42'
	},
	{
		id: 6,
		caption: 'XL/42',
		value: 'xl_42'
	},
	{
		id: 7,
		caption: 'XXL/42',
		value: 'xxl_42'
	}
];

const measuresValues = [
	{
		id: 1,
		label: "Об'єм грудей",
		name: 'breast'
	},
	{
		id: 2,
		label: "Об'єм талії",
		name: 'waist'
	},
	{
		id: 3,
		label: "Об'єм стегон",
		name: 'thigh'
	},
	{
		id: 4,
		label: "Довжина ступні",
		name: 'foot'
	}
];

const radioColors = [
	{
		id: 1,
		label: 'Помаранчевий',
		value: '#eb7a13'
	},
	{
		id: 2,
		label: 'Зелений',
		value: '#5fb7ca'
	},
	{
		id: 3,
		label: 'Червоний',
		value: '#d7da4e'
	},
	{
		id: 4,
		label: 'Помаранчевий',
		value: '#85cf72'
	},
	{
		id: 5,
		label: 'Помаранчевий',
		value: '#b155d1'
	},
	{
		id: 6,
		label: 'Помаранчевий',
		value: '#c24a4a'
	},
	{
		id: 7,
		label: 'Помаранчевий',
		value: '#f412d0'
	},
	{
		id: 8,
		label: 'Помаранчевий',
		value: '#6957d8'
	}
];

const checkboxSeasons = [
	{
		id: 1,
		value: 'spring',
		label: 'Весна'
	},
	{
		id: 2,
		value: 'summer',
		label: 'Літо'
	},
	{
		id: 3,
		value: 'autumn',
		label: 'Осінь'
	},
	{
		id: 4,
		value: 'winter',
		label: 'Зима'
	}
];

const typeOfImages = [
	{
		id: 0,
		type: 'image'
	},
	{
		id: 1,
		type: 'image'
	},
	{
		id: 2,
		type: 'image'
	},
	{
		id: 3,
		type: 'image'
	},
	{
		id: 4,
		type: 'image'
	},
	{
		id: 5,
		type: 'video'
	}
]

const AddProductBlock = () => {
	const store = useContext(Context);
	const { fetchFlatTree, hierarchyTree, setCustomValue, formData, submitFormData, addValueForFieldName, categories, childCategories, brands, conditions, searchInputBrand, setSearchInputBrand, expandedCategory, categoryLevel, tempCategories, sizes, setSelectSizes, allColors, allSeasons, allMaterials } = store.rootStore.addProductStore;
	const [files, setFiles] = useState([]);
	const [errorFile, setErrorFile] = useState(null);

	const { caption, description, price, count, condition, brand, colors, selectSizes, material, print, seasons, categoryValue } = formData;

	useEffect(() => {
		const fetchCateg = async () => {
			fetchFlatTree();
		}
		fetchCateg();
	}, [fetchFlatTree]);

	const { register, formState: { errors }, handleSubmit, control } = useForm({
		resolver: yupResolver(schemaAddProduct),
	});

	// const { register, formState: { errors }, control, handleSubmit, getValues } = useForm({
	// 	resolver: yupResolver(schemaAddProduct),
	// 	context: { presence: getValues() },
	// });

	// const { register, formState: { errors }, control, handleSubmit, getValues } = methods;

	// const handleSelectCategory = (value) => {
	// 	// console.log('value >', value);
	// 	// console.log('level >', level);
	// 	// setCategory(value);
	// }

	// const categoryList = useMemo(() => <CategoryList categories={ hierarchyTree } />, [hierarchyTree]);

	const textAreaRows = isMobile ? 5 : 10;

	/* const MeasureInput = ({ label, name, value, handleChangeMeasureItem }) => {
		return (
			<Box className="form-control">
				<InputLabel htmlFor={`${name}-product`} className='form-control__label'>{label}</InputLabel>
				<FormControl>
					<OutlinedInput
						name={name}
						{...register(`${name}`)}
						error={Boolean(errors[name])}
						autoComplete='off'
						// id="user-surname"
						className="form-input__text"
						aria-describedby={`${name}-product-helper-text`}
						value={value}
						onChange={handleChangeMeasureItem}
						type="number"
						endAdornment={<InputAdornment position="end">см</InputAdornment>}
					/>
					<FormHelperText id={`${name}-product-helper-text`} className='form-helper-text'>{errors[name] ? errors[name].message : ''}</FormHelperText>
				</FormControl>
			</Box>
		);
	} */

	const submitForm = (data) => {
		console.log('submit form, data', data);
		submitFormData();
		// console.log('data >', data);
	}

	// const factorial = useMemo(() => Factorial(number), [number]);

	// const handleSelectSection = (e) => {
	// 	selectSection(e.target.value);
	// 	// setSection(e.target.value);
	// 	// console.log('section.value >', e.target.value);
	// }

	// const handleSelectSubCategory = (e) => {
	// 	setSubCategory(e.target.value);
	// }

	// const handleSelectSize = (e) => {
	// 	setSize(e.target.value);
	// }

	// const handleChangeMeasureItem = (e) => {
	// 	console.log(e.target.value);
	// 	setMeasures({
	// 		...measures,
	// 		[e.target.name]: e.target.value
	// 	});
	// }

	// const handleSelectColor = (e) => {
	// 	setColor(e.target.value);
	// }

	// const handleSelectSeason = (e) => {
	// 	setSeason(e.target.value);
	// }

	// const titleOfCategories = ['Розділ товару', 'Категорія товару', 'Підкатегорія товару'];

	// const handleSetImage = (index, file) => {
	// 	setImage(index, file);
	// 	// console.log(e);
	// 	// console.log(toJS(images));
	// }

	// const handleDeleteImage = (index) => {
	// 	deleteImage(index);
	// }

	// console.log('files >', files);

	// const selectedBrand = useMemo(
	// 	() => brands.filter((v) => v.selected),
	// 	[brands],
	// );
	// return <Autocomplete multiple value={selectedValues} />;

	// const handleSetSearchInputBrand = (e) => {
	// 	setSearchInputBrand(e.target.value);
	// }

	return (
		<Stack className={styles['add-product']}>
			{/* <FormProvider { ...methods }> */}
				<form onSubmit={handleSubmit(submitForm)}>
					<Grid container>
						<Grid item xxs={12} lg={6} sx={{ pr: { lg: 3 } }}>
							<Box className="form-control">
								<FormControl>
									<OutlinedInput
										name='caption'
										{...register('caption')}
										placeholder="Назва товару"
										error={Boolean(errors.caption)}
										autoComplete='off'
										// id="user-surname"
										className="form-input__text"
										aria-describedby="caption-product-helper-text"
										value={caption}
										onChange={(e) => setCustomValue(e.target.name, e.target.value)}
									/>
									<FormHelperText id="caption-product-helper-text" className='form-helper-text'>{errors.caption ? errors.caption.message : ''}</FormHelperText>
								</FormControl>
							</Box>
							<Box className="form-control" sx={{ mt: { xxs: 3, lg: 4 } }}>
								<FormControl>
									<OutlinedInput
										name='description'
										{...register('description')}
										placeholder="Опис товару"
										error={Boolean(errors.description)}
										autoComplete='off'
										// id="user-surname"
										className="form-input__text"
										aria-describedby="description-product-helper-text"
										value={description}
										onChange={(e) => setCustomValue(e.target.name, e.target.value)}
										multiline
										rows={textAreaRows}
									/>
									<FormHelperText id="description-product-helper-text" className='form-helper-text'>{errors.description ? errors.description.message : ''}</FormHelperText>
								</FormControl>
							</Box>
						</Grid>
						<Grid item xxs={12} lg={6} sx={{ pl: { lg: 3 }, mt: { xxs: 3, lg: 0 } }}>
							<Box className={styles['add-product__photos']}>
								<AddFiles control={control} errors={errors} name='files' register={register} />
							</Box>
						</Grid>
					</Grid>
					<Grid container className={styles['add-product__price']}>
						<Grid item xxs={12} lg={6} sx={{ pr: { lg: 3 } }}>
							<Box className="form-control">
								<InputLabel htmlFor="price-product" className='form-control__label'>Ціна товару</InputLabel>
								<FormControl>
									<OutlinedInput
										name='price'
										{...register('price')}
										// placeholder="Назва товару"
										error={errors.price ? Boolean(errors.price) : undefined}
										autoComplete='off'
										// id="user-surname"
										className="form-input__text form-input__number"
										aria-describedby="price-product-helper-text"
										value={price}
										onChange={(e) => setCustomValue(e.target.name, e.target.value)}
										endAdornment={<InputAdornment position="end">грн</InputAdornment>}
										type="number"
									/>
									<FormHelperText id="price-product-helper-text" className='form-helper-text'>{errors.price?.message}</FormHelperText>
								</FormControl>
							</Box>
						</Grid>
						<Grid item xxs={12} lg={6} sx={{ pl: { lg: 3 }, mt: { xxs: 3, lg: 0 } }}>
							<Box className="form-control">
								<InputLabel htmlFor="count-product" className='form-control__label'>Кількість товару</InputLabel>
								<FormControl>
									<OutlinedInput
										name='count'
										{...register('count')}
										// placeholder="Назва товару"
										error={Boolean(errors.count)}
										autoComplete='off'
										// id="user-surname"
										className="form-input__text form-input__number"
										aria-describedby="count-product-helper-text"
										value={count}
										onChange={(e) => setCustomValue(e.target.name, e.target.value)}
										endAdornment={<InputAdornment position="end">шт.</InputAdornment>}
										type="number"
									/>
									<FormHelperText id="count-product-helper-text" className='form-helper-text'>{errors.count ? errors.count.message : ''}</FormHelperText>
								</FormControl>
							</Box>
						</Grid>
					</Grid>

					<Grid container className={styles['add-product__categories']}>
						<Box>Розділ товару</Box>
						<CategoryList categories={hierarchyTree} />
						{/* <FormControl>
							<FormGroup
								row
								value={categoryValue}
								aria-describedby="product-section-helper-text"
								name="categoryValue"
								// {...register('categoryValue')}
								// error={errors.categoryValue ? errors.categoryValue : undefined}
								>
									<CategoryList categories={hierarchyTree} />
							</FormGroup>
							<FormHelperText id="product-section-helper-text" className='form-helper-text'>{errors.categoryValue ? errors.categoryValue.message : ''}</FormHelperText>
						</FormControl> */}
					</Grid>
					{
						(childCategories.length === 0 && categoryValue > 0) && (
							<Grid container className={styles['add-product__condition']}>
								<Grid item xxs={12} def={6} sx={{ pr: { def: 4 } }}>
									<Box className="form-control">
										<FormControl>
											<Select
												name='condition'
												{...register('condition')}
												error={errors.condition ? Boolean(errors.condition) : undefined}
												autoComplete='off'
												className="form-input__select"
												aria-describedby="condition-product-helper-text"
												value={condition}
												onChange={(e) => setCustomValue(e.target.name, e.target.value)}
												displayEmpty
											>
												<MenuItem disabled value="">Стан</MenuItem>
												{
													conditions.length > 0 && (
														conditions.map(({ valueID, caption }) => (
															<MenuItem key={valueID} value={valueID}>{caption}</MenuItem>
														))
													)
												}
											</Select>
											<FormHelperText id="condition-product-helper-text" className='form-helper-text'>{errors.condition ? errors.condition.message : ''}</FormHelperText>
										</FormControl>
									</Box>
								</Grid>
								<Grid item xxs={12} def={6} sx={{ pl: { def: 4 }, mt: { xxs: 3, def: 0 } }}>
									<Box className="form-control">
										<FormControl>
											<Autocomplete
												{...register('brand')}
												name="brand"
												aria-describedby="brand-product-helper-text"
												error={errors.brand ? Boolean(errors.brand) : undefined}
												disablePortal
												options={brands}
												renderInput={(params) => (
													<TextField
														{...params}
														label=""
														placeholder="Бренд"
														name="brand"
													/>
												)}
												className="form-input__autocomplete"
												onChange={(event, newValue) => { setCustomValue('brand', (newValue ? newValue.valueID : '')) }}
												getOptionLabel={(option) => { return option.caption }}
												value={ brand ? brands.find(option => { return brand === option.valueID}) ?? null : null }
											/>
											<FormHelperText id="brand-product-helper-text" className='form-helper-text'>{errors.brand ? errors.brand.message : ''}</FormHelperText>
										</FormControl>
									</Box>
								</Grid>
							</Grid>
						)
					}
					{
						condition.length > 0 && brand.length > 0 && (
							<Box className={styles['add-product__measurement']}>
								<Box className={styles['add-product__size']}>
									{
										sizes.length > 0 &&
										sizes.map(({ attributeID, caption, items, systemName }) => (
											<Box className="form-control" key={attributeID} sx={{ mt: 4 }}>
												<InputLabel htmlFor="product-size" className='form-control__label'>{caption}</InputLabel>
												<FormControl>
													<RadioGroup row className={styles['add-product__radio-group']}>
														{
															items.length > 0 && items.map(({ valueID, caption }) => (
																<FormControlLabel
																	{...register(`${systemName}`)}
																	// ref={register}
																	aria-describedby="product-size-helper-text"
																	error={errors[systemName] ? Boolean(errors[systemName]) : undefined}
																	// onChange={(e) => setCustomValue(e.target.name, e.target.value)}
																	onChange={(e) => setSelectSizes(e.target.name, valueID)}
																	key={valueID}
																	value={valueID}
																	name={systemName}
																	className={styles['add-product__radiobutton-label']}
																	control={
																		<Radio
																			className='radio-button radio-button__add-product'
																			disableRipple
																			icon={<BpText text={caption} />}
																			checkedIcon={<BpText text={caption} />}
																		/>
																	}
																/>
															))
														}
													</RadioGroup>
													<FormHelperText id="product-size-helper-text" className='form-helper-text'>{errors[systemName] ? errors[systemName].message : ''}</FormHelperText>
												</FormControl>
											</Box>
										))
									}
								</Box>
								{
								<Box className={styles['add-product__measures']}>
									<InputLabel htmlFor="measures-content" className='form-control__label'>Заміри</InputLabel>
									<Box className={styles['measures-content']}>
										{
											measuresValues.map(({ id, label, name }) => (
												<Box className="form-control" key={id}>
													<InputLabel htmlFor={`${name}-product`} className='form-control__label' sx={{ fontSize: '14px !important' }}>{label}</InputLabel>
													<FormControl>
														<OutlinedInput
															name={name}
															{...register(`${name}`)}
															error={errors[name] ? Boolean(errors[name]) : undefined}
															autoComplete='off'
															id={`${name}-product`}
															className="form-input__text form-input__number"
															aria-describedby={`${name}-product-helper-text`}
															value={formData[name]}
															onChange={(e) => setCustomValue(e.target.name, e.target.value)}
															type="number"
															endAdornment={<InputAdornment position="end">см</InputAdornment>}
														/>
														<FormHelperText id={`${name}-product-helper-text`} className='form-helper-text'>{errors[name] ? errors[name].message : ''}</FormHelperText>
													</FormControl>
												</Box>
											))
										}
									</Box>
								</Box>
								}
							</Box>
						)
					}
					{
						selectSizes.length > 0 && (
							<Box className={styles['add-product__colors']}>
								<Box className="form-control">
									<InputLabel htmlFor="product-color" className='form-control__label'>Колір</InputLabel>
									<FormControl>
										<FormGroup
											row
											name="colors"
											className={styles['colors-content']}
											value={colors}
										>
											{
												allColors.map(({ valueID, caption, extension }) => (
													<FormControlLabel
														{...register('colors')}
														error={errors.colors ? errors.colors : undefined}
														aria-describedby="product-color-helper-text"
														key={valueID}
														value={valueID}
														// value={colors ? brands.find(option => { return brand === option.valueID}) ?? null : null}
														label={caption}
														name="colors"
														onChange={(e) => addValueForFieldName(e)}
														control={
															<Checkbox
																className='radio-button'
																icon={<BpColor color={extension} />}
																checkedIcon={<BpColorChecked color={extension} />}
																disableRipple
																// checked={ (colors.length && colors.find(colorSt => Number(colorSt.id) === Number(radio.id))) ? 'checked' : '' }
															/>
														}
														className={styles['add-product__radiobutton-label']}
													/>
												))
											}
										</FormGroup>
										<FormHelperText id="product-color-helper-text" className='form-helper-text'>{errors.colors?.message}</FormHelperText>
									</FormControl>
								</Box>
							</Box>
						)
					}
					{
						colors.length > 0 && (
							<Box className={styles['add-product__mat-print']}>
								<Grid container>
									<Grid item xxs={12} lg={6} sx={{ pr: { lg: 4 } }}>
										<Box className="form-control">
											<FormControl>
												<Select
													name='material'
													{...register('material')}
													error={Boolean(errors.material)}
													autoComplete='off'
													className="form-input__select"
													aria-describedby="material-product-helper-text"
													value={material}
													onChange={(e) => setCustomValue(e.target.name, e.target.value)}
													displayEmpty
												>
													<MenuItem disabled value="">Матеріал</MenuItem>
													{
														allMaterials.map(({ valueID, caption }) => (
															<MenuItem value={valueID} key={valueID}>{caption}</MenuItem>
														))
													}
												</Select>
												<FormHelperText id="material-product-helper-text" className='form-helper-text'>{errors.material ? errors.material.message : ''}</FormHelperText>
											</FormControl>
										</Box>
									</Grid>
									<Grid item xxs={12} lg={6} sx={{ pl: { lg: 4 }, mt: { xxs: 3, lg: 0 } }}>
										<Box className="form-control">
											<FormControl>
												<Select
													name='print'
													{...register('print')}
													error={Boolean(errors.print)}
													autoComplete='off'
													className="form-input__select"
													aria-describedby="print-product-helper-text"
													value={print}
													onChange={(e) => setCustomValue(e.target.name, e.target.value)}
													displayEmpty
												>
													<MenuItem disabled value="">Прінт</MenuItem>
													<MenuItem value="print1">Прінт 1</MenuItem>
													<MenuItem value="print2">Прінт 2</MenuItem>
													<MenuItem value="print3">Прінт 3</MenuItem>
													<MenuItem value="print4">Прінт 4</MenuItem>
													<MenuItem value="print5">Прінт 5</MenuItem>
												</Select>
												<FormHelperText id="print-product-helper-text" className='form-helper-text'>{errors.print ? errors.print.message : ''}</FormHelperText>
											</FormControl>
										</Box>
									</Grid>
								</Grid>
								<Box className="form-control" sx={{ mt: 4 }}>
									<InputLabel htmlFor="product-season" className='form-control__label'>Сезон</InputLabel>
									<FormControl>
										<FormGroup
											row
											name="seasons"
											value={seasons}
											className={styles['add-product__seasons']}
										>
											{
												allSeasons.map(({ valueID, caption }) => (
													<FormControlLabel
														aria-describedby="product-season-helper-text"
														key={valueID}
														value={valueID}
														{...register('seasons')}
														onChange={(e) => addValueForFieldName(e)}
														name="seasons"
														control={
															<Checkbox
																disableRipple
																icon={<BpCheckbox />}
																checkedIcon={<BpCheckedIcon />}
															/>
														}
														label={caption}
														sx={{ m: 0 }}
													/>
												))
											}
										</FormGroup>
										<FormHelperText id="product-season-helper-text" className='form-helper-text'>{errors.seasons?.message}</FormHelperText>
									</FormControl>
								</Box>
							</Box>
						)
					}
					<Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
						<Button className='button button-blue' variant='contained' disableRipple type="submit">Зберегти</Button>
					</Box>
				</form>
			{/* </FormProvider> */}
		</Stack>
	)
}

export default withAuth(observer(AddProductBlock));
