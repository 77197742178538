import { useContext } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import './MyInfoHeader.scss';
import { NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { Context } from '../../../..';
import LoadingSpinner from '../../../loading-spinner';

const userNoAvatar = '../assets/images/no-avatar.png';
// const userAvatar = '../assets/images/avatar-profile.png';

const MyInfoHeader = ({ title, pageName, showTabs = false }) => {
	const store = useContext(Context);

	const { photo, firstname, surname, isLodingInfo } = store.rootStore.myInfoStore.clientInfo;

	const fullName = (surname ? surname : '') + (firstname ? ' ' + firstname : '');

	const coma = fullName.length > 0 ? ',' : '!';

	return (
		<Box className='my-info-header'>
			<Box className='my-info-header__title'>
				<Box className='title-text'>{title}</Box>
				{
					pageName !== 'myInfo' && (
						<Box className='user-info'>
							<Box className='user-info__name'>
								<Box className='welcome-phrase'>Вітаємо{coma}</Box>
								<Box className='user-name'>{fullName}</Box>
							</Box>
							<Box className='user-info__image'>
								{
									isLodingInfo ? <LoadingSpinner /> : (
										<img src={photo ? `https://zefir.store/${photo}` : userNoAvatar} alt="user profile" />
									)
								}
							</Box>
						</Box>
					)
				}
			</Box>
			{
				showTabs && (
					<Box className='my-info-header__tabs'>
						<NavLink to="/shopper/my-info" className='tab-button'>
							{ isMobile ? <Person2RoundedIcon /> : 'Загальна інформація' }
						</NavLink>
						<NavLink to="/shopper/delivery-address" className='tab-button'>
							{ isMobile ? <LocalShippingRoundedIcon /> : 'Адреса доставки' }
						</NavLink>
						<NavLink to="/shopper/security" className='tab-button'>
							{ isMobile ? <SecurityRoundedIcon /> : 'Безпека'}
						</NavLink>
					</Box>
				)
			}
		</Box>
	)
}

export default observer(MyInfoHeader);
