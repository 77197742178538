import AddProductBlock from '../../../../components/admin/seller/add-product-block';
import LayoutSeller from '../../../../components/admin/layout';

const AddProduct = () => {
  return (
    <LayoutSeller typeInfo='client-info'>
      <AddProductBlock />
    </LayoutSeller>
  )
}

export default AddProduct;
