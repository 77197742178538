import React, { useContext, useRef } from 'react'
import { Box, Button, InputLabel, OutlinedInput, FormHelperText, FormControl, Grid } from '@mui/material'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaInfo } from '../../../validator';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';
import { isMobile } from 'react-device-detect';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
// import { toJS } from 'mobx';

import MyInfoHeader from '../my-info-header';
import { Context } from '../../../..';
import styles from './MyInfoBlock.module.scss';
import clsx from 'clsx';
import withAuth from '../../../hoc/withAuth';
import LoadingSpinner from '../../../loading-spinner';

// const userAvatar = '../assets/images/avatar-profile.png';
const userNoAvatar = '../assets/images/no-avatar.png';

const MyInfoBlock = () => {
	const inputRef = useRef(null);
	const { register, formState: { errors }, handleSubmit } = useForm({
	// const { register, formState: { errors } } = useForm({
		resolver: yupResolver(schemaInfo),
	});

	const store = useContext(Context);
	// const { clientData } = store.rootStore.authStore;
	const { _jwt: jwt, validateJWT, clientData } = store.rootStore.authStore;
	const { clientInfo, handleSaveMyInfo, uploadPhoto, isLoadingInfo, setClientInfo } = store.rootStore.myInfoStore;

	const handleChangeFirstname = (e) => {
		clientInfo.firstname = e.target.value;
	}

	const handleChangeSurname = (e) => {
		clientInfo.surname = e.target.value;
	}

	const handleChangeEmail = (e) => {
		clientInfo.email = e.target.value;
	}

	const handleChangePhone = (e) => {
		clientInfo.phone = e.target.value;
	}

	// useEffect(() => {
	// 	const fetchClientInfo = async () => {
	// 		// if (Object.keys(toJS(clientData)).length !== 0) {
	// 		await setClientInfo(clientData);
	// 		// }
	// 	};
	// 	fetchClientInfo();
	// 	// if (Object.keys(toJS(clientData)).length !== 0) {
	// 	// 	setClientInfo(clientData);
	// 	// }
	// }, [setClientInfo, clientData]);

	// console.log('clientInfo.photo >>>', clientInfo.photo);

	const submitForm = () => {
		const userAvatar = clientInfo.photo;
		clientInfo.typeInfo = 'myInfo';
		clientInfo.jwt = jwt;
		delete clientInfo.photo;
    handleSaveMyInfo(clientInfo);
		clientInfo.photo = userAvatar;
  }

	const handleUploadClick = () => {
    inputRef.current?.click();
  };

	const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    uploadPhoto(e.target.files[0]);
  };

  return (
		<Box className={styles['my-info']}>
			<MyInfoHeader title="Моя інформація" pageName="myInfo" showTabs={true} />
      {/* <Box className={styles['my-info__title']}>Моя інформація</Box> */}
			<Box className={styles['my-info__image']}>
				<Box className={styles['image-info']}>
					<Box className={styles['image-avatar']}>
						{
							isLoadingInfo ? <LoadingSpinner /> : (
								<img src={ clientInfo.photo ? `https://zefir.store/${clientInfo.photo}` : userNoAvatar} alt="user avatar" />
							)
						}
					</Box>
					<Box className={styles['image-description']}>
						{
							(clientInfo.firstname || clientInfo.surname) && (
								<Box className={styles['user-name']}>{clientInfo.surname + ' ' + clientInfo.firstname}</Box>
							)
						}
						<Box className={styles['user-register']}><Box>Дата реєстрації:</Box><Box sx={{ ml: { xs: 1} }}>{clientInfo.date_create}</Box></Box>
					</Box>
				</Box>
				<Box className={styles['image-button']}>
					<Button
						className='button button-light'
						variant='contained'
						disableRipple
						onClick={handleUploadClick}
					>
						{ isMobile ? <EditRoundedIcon /> : 'Редагувати фото' }
					</Button>
					<input
						type="file"
						ref={inputRef}
						onChange={handleFileChange}
						style={{ display: 'none' }}
						// value={myInfoStore.photo ? myInfoStore.photo : ''}
						value=''
					/>
				</Box>
			</Box>
        <Box className={styles['my-info__form']}>
            <form onSubmit={handleSubmit(submitForm)}>
							<Grid container sx={{ justifyContent: 'space-between' }}>
									<Grid item xxs="12" ms="6" sx={{ pr: { ms: 1 } }}>
										<Box className="form-control">
											<InputLabel htmlFor="user-surname" className='form-control__label'>Прізвище</InputLabel>
											<FormControl>
												<OutlinedInput
													name='surname'
													{...register('surname')}
													placeholder="Введіть Ваше прізвище"
													error={Boolean(errors.surname)}
													autoComplete='off'
													id="user-surname"
													className="form-input__text"
													aria-describedby="user-surname-helper-text"
													value={clientInfo.surname || ''}
													onChange={handleChangeSurname}
												/>
												<FormHelperText id="user-name-helper-text" className='form-helper-text'>{errors.surname ? errors.surname.message : ''}</FormHelperText>
											</FormControl>
										</Box>
									</Grid>
									<Grid item xxs="12" ms="6" sx={{ pl: { ms: 1 }, mt: { xxs: 2, ms: 0 } }}>
										<Box className="form-control">
											<InputLabel htmlFor="user-firstname" className='form-control__label'>Ім'я</InputLabel>
											<FormControl>
												<OutlinedInput
													name='firstname'
													{...register('firstname')}
													placeholder="Введіть Ваше ім'я"
													error={Boolean(errors.firstname)}
													autoComplete='off'
													id="user-firstname"
													className="form-input__text"
													aria-describedby="user-firstname-helper-text"
													value={clientInfo.firstname || ''}
													onChange={handleChangeFirstname}
												/>
												<FormHelperText id="user-firstname-helper-text" className='form-helper-text'>{errors.firstname ? errors.firstname.message : ""}</FormHelperText>
											</FormControl>
										</Box>
									</Grid>
							</Grid>
							<Grid container sx={{ justifyContent: 'space-between', mt: { xxs: 3, ms: 5 } }}>
									<Grid item xxs="12" ms="6" sx={{ pr: { ms: 1 } }}>
										<Box className="form-control">
											<InputLabel htmlFor="user-email" className='form-control__label'>E-mail</InputLabel>
											<FormControl>
												<OutlinedInput
													name="email"
													id="user-email"
													{...register('email')}
													error={Boolean(errors.email)}
													autoComplete='off'
													className="form-input__text"
													placeholder="Введіть Ваш e-mail"
													aria-describedby="user-email-helper-text"
													value={clientInfo.email || ''}
													onChange={handleChangeEmail}
												/>
												<FormHelperText id="user-email-helper-text" className='form-helper-text'>{errors.email ? errors.email.message : ''}</FormHelperText>
											</FormControl>
										</Box>
									</Grid>
									<Grid item xxs="12" ms="6" sx={{ pl: { ms: 1 }, mt: { xxs: 2, ms: 0 } }}>
											<Box className="form-control">
													<InputLabel htmlFor="user-phone" className='form-control__label'>Номер телефону</InputLabel>
													<FormControl>
														<InputMask
															mask="+38 (999) 999-99-99"
															maskChar="_"
															autoComplete="off"
															{...register('phone')}
															error={Boolean(errors.phone)}
															placeholder="+38 (___) ___-__-__"
															value={clientInfo.phone || ''}
															onChange={handleChangePhone}
														>
															{(inputProps) => (
																<OutlinedInput className="form-input__text" {...inputProps} aria-describedby="user-phone-helper-text" id="user-phone" />
															)}
														</InputMask>
														<FormHelperText id="user-phone-helper-text" className='form-helper-text'>{errors.phone ? errors.phone.message : ''}</FormHelperText>
													</FormControl>
											</Box>
									</Grid>
							</Grid>
							<Box className={styles['my-info__form-button']}>
								<Button type="submit" className={clsx('button button-blue', styles['button-submit'])} variant='contained' disableRipple>Зберегти</Button>
							</Box>
            </form>
        </Box>
				<Box className={styles['my-info__remove']}>
					<Box className={styles['my-info__remove-description']}>
						<Box className={styles['remove-title']}>Видалити аккаунт</Box>
						<Box className={styles['remove-description']}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</Box>
					</Box>
					<Button className='button button-light' variant='contained' disableRipple>Видалити</Button>
				</Box>
    </Box>
  )
}

export default withAuth(observer(MyInfoBlock));
