import React, { useContext, useState } from 'react';
import { Box, InputLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
// import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaAddProduct } from '../../../validator';
import { useFormContext, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
// import clsx from 'clsx';
import { toJS, autorun, computed } from 'mobx';

import styles from './AddProductBlock.module.scss';

import { Context } from '../../../..';
import { isMobile } from 'react-device-detect';

const BpIcon = styled('span')(({ svgOrText, typeProp }) => {
	let obj = {};

	if (typeProp === 'svg') {
		obj = {
			background: `url('${svgOrText}') no-repeat center`,
			backgroundSize: '65px',
			width: '100%',
			height: '100%'
		}
	} else if (typeProp === 'text') {
		obj = {
			width: '100%',
			height: '100%',
			'&:before': {
				position: 'absolute',
				content: `'${svgOrText}'`,
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				textAlign: 'center',
				// color: '#35322e'
				color: '#ffffff',
				fontSize: '14px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '100%'
			},
			'@media screen and (min-width: 1024px)': {
				'&:before': {
					color: '#35322e',
					fontSize: '16px'
				}
			}
		}
	}

	return obj;
});

const CategoryList = ({ categories, level = 0 }) => {
	const [expandedCategory, setExpandedCategory] = useState(null);
	// const [localCategories, setLocalCategories] = useState([]);
	// const { control, register, formState: { errors } } = useFormContext({
	// const { control, register } = useFormContext({
	// 	resolver: yupResolver(schemaAddProduct),
	// });

	const store = useContext(Context);
	const { formData, setSelectChildCategories, setCategories } = store.rootStore.addProductStore;

	// const { categoryValue } = formData;

  const handleSelectCategory = (e) => {
		// setCategoriesValues(e.target.name, e.target.value);
		setCategories(categories);
		setExpandedCategory(e.target.value);
		setSelectChildCategories(e.target.value);
  };

	// console.log('categoryValue >', categoryValue);

	const titleOfCategories = ['Розділ товару', 'Категорія товару', 'Підкатегорія товару', 'Підкатегорія товару 2', 'Підкатегорія товару 3'];
	const nameOfRadioGroup = ['section', 'category', 'subCategory'];

	return (
		<>
			<Box className={styles['product-section']}>
				<Box className="form-control">
					{/* <InputLabel htmlFor="product-section" className='form-control__label'>{titleOfCategories[level]}</InputLabel> */}
					<FormControl>
						<RadioGroup
								row
								// {...field}
								// aria-describedby="product-section-helper-text"
								className={styles['add-product__radio-group']}
								// value={`${categoriesValues[nameOfRadioGroup[level]]}`}
								// value={`${categoriesValues[level]}`}
								id={`category-level-${level}`}
							>
								{
									categories.map(categ => (
										<FormControlLabel
											key={categ.id}
											value={categ.id}
											name='categoryValue'
											// {...register('categoryValue')}
											// error={errors.categoryValue ? errors.categoryValue : undefined}
											onChange={(e) => handleSelectCategory(e)}
											control={
												<Radio
													className='radio-button radio-button__add-product'
													icon={<BpIcon svgOrText={categ.caption} typeProp="text" />}
													checkedIcon={<BpIcon svgOrText={categ.caption} typeProp="text" />}
													disableRipple
												/>
											}
											className={styles['add-product__radiobutton-label']}
										/>
									))
								}
							</RadioGroup>
						{ /* <Controller
							name={`category-level-${level}`}
							control={control}
							refs={{...register(`category-level-${level}`)}}
							render={({ field }) => (
								<RadioGroup
									row
									{...field}
									aria-describedby="product-section-helper-text"
									className={styles['add-product__radio-group']}
									// value={`${categoriesValues[nameOfRadioGroup[level]]}`}
									// value={`${categoriesValues[level]}`}
									onChange={(e) => handleSelectCategory(e)}
									id={`category-level-${level}`}
									name="categoryValue"
								>
									{
										categories.map(categ => (
											<FormControlLabel key={categ.id} value={categ.id} control={<Radio className='radio-button radio-button__add-product' icon={<BpIcon svgOrText={categ.caption} typeProp="text" />} checkedIcon={<BpIcon svgOrText={categ.caption} typeProp="text" />} disableRipple />} className={styles['add-product__radiobutton-label']} />
										))
									}
								</RadioGroup>
							)}
						/> */ }
					</FormControl>
				</Box>
			</Box>
			{
				expandedCategory > 0
				&& categories.find((category) => Number(category.id) === Number(expandedCategory)).childs?.length > 0 &&
				(
					<CategoryList categories={categories.find((category) => Number(category.id) === Number(expandedCategory)).childs} level={level + 1} />
				)
			}
		</>

	)
}

export default observer(CategoryList);
