import { Grid, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import React, { memo } from 'react'

const BlockWithMedia = styled('div')(({ bgColor }) => ({
  backgroundColor: bgColor,
	width: '20px',
	height: '20px',
	borderRadius: '50%',
	'&:not(:first-child)': {
		marginLeft: '10px'
	}
}));

const PropertyTypesBlock = ({ propertyName = '', propertyValue = '', propertyType = '' }) => {
	let returnBlock = '';
	if (propertyValue.length) {
		if (propertyType === 'media') {
			returnBlock = <BlockWithMedia bgColor={propertyValue} />;
		} else if (propertyType === 'text') {
			returnBlock = (
				propertyName.length ? (
					<Box sx={{ display: 'flex' }}>
						<Typography>{propertyName}:</Typography>
						<Typography sx={{ ml: 1 }}>{propertyValue}</Typography>
					</Box>
				) : (
					<Typography>{propertyValue}</Typography>
				)
			);
		}
	}
	return returnBlock;
}

const ReturnTypeBlock = (props) => {
	if (props.propType === 'media')
		return <Box sx={{ display: 'flex' }}>{props.children}</Box>
	else
		return <Box>{props.children}</Box>
}

const ProductProperty = memo(({ properties }) => {
	const { propertyName, propertyValue, propertyType } = properties;

  return (
			<Grid container sx={{ mt: 2 }}>
				<Grid xs={4} md={3} item>
					<Typography sx={{ fontWeight: 600 }}>{propertyName}:</Typography>
				</Grid>
				<Grid xs={8} md={9} item>
					{
						Array.isArray(propertyValue) ?
						 	(
								<ReturnTypeBlock propType={propertyType}>
									{
										propertyValue.map(({ propertyName, propertyValue }, index) => (
											<PropertyTypesBlock key={index} propertyName={propertyName} propertyValue={propertyValue} propertyType={propertyType} />
										))
									}
								</ReturnTypeBlock>
					 	) : (
								<PropertyTypesBlock propertyValue={propertyValue} propertyType={propertyType} />
	 					)
					}
				</Grid>
			</Grid>
  )
});

export default ProductProperty