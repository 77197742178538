import React, { useContext, useState } from 'react';
import { Box, Button, InputLabel, OutlinedInput, FormHelperText, FormControl, Grid, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaAddress } from '../../../validator';
// import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';
import { toJS } from 'mobx';

import MyInfoHeader from '../my-info-header';
// import { Context } from '../../../..';
import withAuth from '../../../hoc/withAuth';
import styles from './DeliveryAddressBlock.module.scss';

import { Context } from '../../../..';

const DeliveryAddressBlock = () => {
	const store = useContext(Context);
	const { addresses, handleSaveMyInfo } = store.rootStore.myInfoStore;

	const { _jwt } = store.rootStore.authStore;

	// const [city, setCity] = useState(addresses.city);
	// const [street, setStreet] = useState(addresses.street);
	// const [apartment, setApartment] = useState(addresses.apartment);
	// const [building, setBuilding] = useState(addresses.building);
	// const [zip, setZip] = useState(addresses.zip);
	// const [postService, setPostService] = useState(addresses.post_services);
	// const [postOffice, setPostOffice] = useState(addresses.post_office);
	// const [typeDelivery, setTypeDelivery] = useState(addresses.type_delivery);

	const [address, setAddress] = useState(addresses);

	const { register, formState: { errors }, handleSubmit } = useForm({
		resolver: yupResolver(schemaAddress),
	});

	// function handleChangeField (field, value) {
	// 	addresses[field] = value;
	// }

	const handleChangeInput = (e) => {
		// const newAddress = address;
		// address
		setAddress({
			...address,
			[e.target.name]: e.target.value
		});
		addresses[e.target.name] = e.target.value;
	}

	// const handleChangeCity = (e) => {
	// 	console.log('event >>>', e);
	// 	setCity(e.target.value);
	// 	handleChangeField(e.target.name, e.target.value);
	// };

	// const handleChangeStreet = (e) => {
	// 	addresses.street = e.target.value;
	// };

	// const handleChangeBuilding	= (e) => {
	// 	addresses.building = e.target.value;
	// };

	// const handleChangeApartment	= (e) => {
	// 	addresses.apartmant = e.target.value;
	// };

	// const handleChangeZip	= (e) => {
	// 	addresses.zip = e.target.value;
	// };

	// const handleChangePostOffice	= (e) => {
	// 	addresses.post_office = e.target.value;
	// };

	// const handleChangePostService = (e) => {
	// 	console.log(e.target.value);
  //   addresses.post_services = e.target.value;
	// 	console.log('addresses >', toJS(addresses));
  // };

	// const handleChangeTypeDelivery = (e) => {
  //   addresses.type_delivery = e.target.value;
  // };

	// console.log('typeof addresses.type_delivery', typeof addresses.type_delivery);

	// const store = useContext(Context);
	// const { myInfoStore } = store.rootStore;

	const submitForm = () => {
		if (addresses.type_delivery.length === 0) {
			addresses.type_delivery = 'Self';
		}
		if (addresses.post_services.length === 0) {
			addresses.post_services = 'NovaPoshta';
		}
		// console.log(addresses);
		addresses.jwt = _jwt;
		addresses.typeInfo = 'address';
    handleSaveMyInfo(addresses);
  }
  return (
    <Box className={styles['delivery-address']}>
			<MyInfoHeader title="Адреса доставки" pageName="deliveryAddress" showTabs={true} />
			<Box className={styles['delivery-address__form']}>
				<form onSubmit={handleSubmit(submitForm)}>
					<Grid container sx={{ justifyContent: 'center' }}>
						<Grid item xxs={12} xs={11} lg={9} xl={8} mg={7}>
							<Grid container sx={{ alignItems: 'center' }}>
								<Grid item xxs={12} xs={2} ms={3}>
									<InputLabel htmlFor="address-city" className='form-control__label'>Місто</InputLabel>
								</Grid>
								<Grid item xxs={12} xs={10} ms={9}>
									<FormControl sx={{ width: '100%' }}>
										<OutlinedInput
											name='city'
											{...register('city')}
											placeholder="Введіть місто"
											error={Boolean(errors.city)}
											autoComplete='off'
											id="address-city"
											className="form-input__text"
											aria-describedby="address-city-helper-text"
											value={address.city}
											onChange={handleChangeInput}
										/>
										<FormHelperText id="address-city-helper-text" className='form-helper-text'>{errors.city ? errors.city.message : ""}</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container sx={{ alignItems: 'center', mt: { xxs: 2, ms: 4 } }} className={styles['delivery-address__post-service']}>
								<Grid item xxs={12} def={3}>
									<InputLabel htmlFor="post-service" className='form-control__label'>Почтовий сервіс</InputLabel>
								</Grid>
								<Grid item xxs={12} def={9}>
								<RadioGroup row aria-labelledby="post-service" name="post_services" onChange={handleChangeInput} value={address.post_services || 'NovaPoshta'} defaultValue='NovaPoshta'>
									<FormControlLabel className="form-input__radio-label" value="NovaPoshta" control={<Radio disableRipple className="form-input__radio" />} label="Нова Пошта" />
									<FormControlLabel className="form-input__radio-label" value="UkrPoshta" control={<Radio disableRipple className="form-input__radio" />} label="Укрпошта" />
								</RadioGroup>
								</Grid>
							</Grid>
							<Grid container sx={{ alignItems: 'center', mt: { xxs: 2, ms: 4 } }} className={styles['delivery-address__type-delivery']}>
								<Grid item xxs={12} def={3}>
									<InputLabel htmlFor="type-delivery" className='form-control__label'>Тип доставки</InputLabel>
								</Grid>
								<Grid item xxs={12} def={9}>
									<RadioGroup row aria-labelledby="type-delivery" name="type_delivery" onChange={handleChangeInput} value={address.type_delivery || 'Self'}>
										<FormControlLabel className="form-input__radio-label" value="Self" control={<Radio disableRipple className="form-input__radio" />} label="Відділення" />
										<FormControlLabel className="form-input__radio-label" value="Courier" control={<Radio disableRipple className="form-input__radio" />} label="Кур'єром" />
									</RadioGroup>
								</Grid>
							</Grid>
							{
								(address.type_delivery || 'Self') === 'Self' ? (
									<Grid container sx={{ alignItems: 'center', mt: { xxs: 2, ms: 4 } }}>
										<Grid item xxs={12} def={3}>
											<InputLabel htmlFor="address-department" className='form-control__label'>Відділення</InputLabel>
										</Grid>
										<Grid item xxs={12} def={9}>
											{/* <Grid container>

											</Grid> */}
											<FormControl sx={{ width: '100%' }}>
												<OutlinedInput
													name='post_office'
													{...register('post_office')}
													placeholder="Відділення"
													error={Boolean(errors.post_office)}
													autoComplete='off'
													id="address-department"
													className="form-input__text"
													aria-describedby="address-department-helper-text"
													value={address.post_office}
													onChange={handleChangeInput}
												/>
												<FormHelperText id="address-department-helper-text" className='form-helper-text'>{errors.post_office ? errors.post_office.message : ""}</FormHelperText>
											</FormControl>
										</Grid>
									</Grid>
								) : (
									<>
									{
										address.post_services === 'UkrPoshta' && (
											<Grid container sx={{ alignItems: 'center', mt: { xxs: 2, ms: 4 } }}>
												<Grid item xxs={12} def={3}>
													<InputLabel htmlFor="zip-code" className='form-control__label'>Поштовий індекс</InputLabel>
												</Grid>
												<Grid item xxs={12} def={9}>
													<FormControl sx={{ width: '100%' }}>
														<InputMask
															mask="99999"
															maskChar="X"
															autoComplete="off"
															{...register('zip')}
															error={Boolean(errors.zip)}
															placeholder="12345"
															name='zip'
															value={address.zip}
															onChange={handleChangeInput}
														>
															{(inputProps) => (
																<OutlinedInput className="form-input__text" {...inputProps} aria-describedby="zip-code-helper-text" id="zip-code" />
															)}
														</InputMask>
														<FormHelperText id="zip-code-helper-text" className='form-helper-text'>{errors.zip ? errors.zip.message : ''}</FormHelperText>
													</FormControl>
												</Grid>
											</Grid>
										)
									}
										<Grid container sx={{ alignItems: 'center', mt: { xxs: 2, ms: 4 } }}>
											<Grid item xxs={12} def={3}>
												<InputLabel htmlFor="address-street" className='form-control__label'>Вулиця</InputLabel>
											</Grid>
											<Grid item xxs={12} def={9}>
												<FormControl sx={{ width: '100%' }}>
													<OutlinedInput
														name='street'
														{...register('street')}
														placeholder="Введіть вулицю"
														error={Boolean(errors.street)}
														autoComplete='off'
														id="address-street"
														className="form-input__text"
														aria-describedby="address-street-helper-text"
														value={address.street}
														onChange={handleChangeInput}
													/>
													<FormHelperText id="address-street-helper-text" className='form-helper-text'>{errors.street ? errors.street.message : ""}</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>

										<Grid container sx={{ alignItems: 'center', mt: { xxs: 2, ms: 4 } }}>
											<Grid item xxs={12} def={3}>
												<InputLabel htmlFor="address-building" className='form-control__label'>Будинок/квартира</InputLabel>
											</Grid>
											<Grid item xxs={12} def={9}>
												<Grid container>
													<Grid item xxs={6} sx={{ pr: 1 }}>
														<FormControl sx={{ width: '100%' }}>
															<OutlinedInput
																name='building'
																{...register('building')}
																placeholder="Введіть номер будинку"
																error={Boolean(errors.building)}
																autoComplete='off'
																id="address-building"
																className="form-input__text"
																aria-describedby="address-building-helper-text"
																value={address.building}
																onChange={handleChangeInput}
															/>
															<FormHelperText id="address-building-helper-text" className='form-helper-text'>{errors.building ? errors.building.message : ""}</FormHelperText>
														</FormControl>
													</Grid>
													<Grid item xxs={6} sx={{ pl: 1 }}>
														<FormControl sx={{ width: '100%' }}>
															<OutlinedInput
																name='apartment'
																{...register('apartment')}
																placeholder="Введіть номер квартири"
																error={Boolean(errors.apartment)}
																autoComplete='off'
																id="address-apartment"
																className="form-input__text"
																aria-describedby="address-apartment-helper-text"
																value={address.apartment}
																onChange={handleChangeInput}
															/>
															<FormHelperText id="address-apartment-helper-text" className='form-helper-text'>{errors.apartment ? errors.apartment.message : ""}</FormHelperText>
														</FormControl>
													</Grid>
												</Grid>

											</Grid>
										</Grid>
									</>
								)
							}

							<Grid container sx={{ alignItems: 'center', mt: { xxs: 3, ms: 4 } }}>
								<Grid item xxs={12} def={3} sx={{ display: { xxs: 'none', def: 'flex' } }}></Grid>
								<Grid item xxs={12} def={9}>
									<Button type="submit" className='button button-blue' variant='contained' disableRipple sx={{ width: '100%' }}>Зберегти</Button>
								</Grid>
							</Grid>

							<input type='hidden' name='addressID' value={address.addressID > 0 ? address.addressID : ''} />

						</Grid>
					</Grid>
				</form>
			</Box>
    </Box>
  )
}

export default withAuth(DeliveryAddressBlock);
