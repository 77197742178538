import { useState, useContext } from 'react';

import { Box, Button, InputLabel, OutlinedInput, FormHelperText, FormControl, Grid, FormControlLabel, Radio, RadioGroup, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaSecurity } from '../../../validator';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';
// import { toJS } from 'mobx';

import MyInfoHeader from "../my-info-header";
import styles from './SecurityBlock.module.scss';

import { Context } from '../../../..';

const SecurityBlock = () => {
	const store = useContext(Context);
	const { clientInfo, setCustomNameAndValue, saveSecurityInfo } = store.rootStore.myInfoStore;
	const { loginEmail, loginPhone, password } = clientInfo;
	const [showInputs, setShowInputs] = useState({
		inpEmail: false,
		inpPhone: false,
		inpPassword: false
	});

	const { register, formState: { errors }, handleSubmit } = useForm({
		resolver: yupResolver(schemaSecurity),
	});

	const handleShowInputs = (inpName) => {
		setShowInputs({
			...showInputs,
			[inpName]: !showInputs[inpName]
		});
		// if (showInputs[inpName]) {
		// 	handleSubmit();
		// 	// saveSecurityInfo();
		// }
	}

	const submitForm = (data) => {
		// saveSecurityInfo();
		console.log('data >>>', data);
	}

	return (
		<Box className={styles['security']}>
			<MyInfoHeader title="Безпека" pageName="security" showTabs={true} />
			<Box className={styles['security-form']}>
				<form onSubmit={handleSubmit(submitForm)}>
				{/* <form> */}
					<Grid container sx={{ justifyContent: 'center', minHeight: '60px', alignItems: 'center' }}>
						<Grid item xxs={12} def={11} lg={9} xl={8}>
							<Grid container sx={{ alignItems: 'center' }}>
								<Grid item xxs={12} def={4}>
									<InputLabel htmlFor="login-email">Логін (електронна пошта)</InputLabel>
								</Grid>
								<Grid item xxs={12} def={7}>
									{
										showInputs.inpEmail ? (
											<FormControl className="form-input__text-label">
												<OutlinedInput
													name='loginEmail'
													{...register('loginEmail')}
													placeholder="Введіть e-mail"
													error={Boolean(errors.email)}
													autoComplete='off'
													id="login-email"
													className="form-input__text"
													aria-describedby="login-email-helper-text"
													value={loginEmail}
													onChange={(e) => setCustomNameAndValue(e.target.name, e.target.value)}
													type='email'
												/>
												<FormHelperText id="login-email-helper-text" className='form-helper-text'>{errors.loginEmail ? errors.loginEmail.message : ""}</FormHelperText>
											</FormControl>
										) : (
											<Box className={styles['security-link']}>{loginEmail}</Box>
										)
									}
								</Grid>
								<Grid item xxs={1}>
									{
										showInputs.inpEmail ? (
											<IconButton aria-label="checked" onClick={() => handleShowInputs('inpEmail')} disableRipple>
												<DoneOutlineIcon />
											</IconButton>
										) : (
											<IconButton aria-label="edit" onClick={() => handleShowInputs('inpEmail')} disableRipple>
												<EditIcon />
											</IconButton>
										)
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>

				<Grid container sx={{ justifyContent: 'center', minHeight: '60px', alignItems: 'center', mt: 1 }}>
					<Grid item xxs={12} def={11} lg={9} xl={8}>
						<Grid container sx={{ alignItems: 'center' }}>
							<Grid item xxs={12} def={4}>
								<InputLabel htmlFor="login-phone">Логін (телефон)</InputLabel>
							</Grid>
							<Grid item xxs={12} def={7}>
								{
									showInputs.inpPhone ? (
										<FormControl className="form-input__text-label">
											<InputMask
												mask="+38 (999) 999-99-99"
												maskChar="_"
												autoComplete="off"
												{...register('loginPhone')}
												error={Boolean(errors.phone)}
												placeholder="+38 (___) ___-__-__"
												value={loginPhone}
												onChange={(e) => setCustomNameAndValue(e.target.name, e.target.value)}
											>
												{(inputProps) => (
													<OutlinedInput className="form-input__text" {...inputProps} aria-describedby="user-phone-helper-text" id="user-phone" />
												)}
											</InputMask>
											<FormHelperText id="login-phone-helper-text" className='form-helper-text'>{errors.loginPhone ? errors.loginPhone.message : ""}</FormHelperText>
										</FormControl>
									) : (
										<Box className={styles['security-link']}>{loginPhone}</Box>
									)
								}
							</Grid>
							<Grid item xxs={1}>
								{
									showInputs.inpPhone ? (
										<IconButton aria-label="checked" onClick={() => handleShowInputs('inpPhone')} disableRipple>
											<DoneOutlineIcon />
										</IconButton>
									) : (
										<IconButton aria-label="edit" onClick={() => handleShowInputs('inpPhone')} disableRipple>
											<EditIcon />
										</IconButton>
									)
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid container sx={{ justifyContent: 'center', minHeight: '60px', alignItems: 'center', mt: 1 }}>
					<Grid item xxs={12} def={11} lg={9} xl={8}>
						<Grid container sx={{ alignItems: 'center' }}>
							<Grid item xxs={12} def={4}>
								<InputLabel htmlFor="pass">Пароль</InputLabel>
							</Grid>
							<Grid item xxs={12} def={7}>
								{
									showInputs.inpPassword ? (
										<FormControl className="form-input__text-label">
											<OutlinedInput
												name='password'
												{...register('password')}
												placeholder="*******"
												error={Boolean(errors.password)}
												autoComplete='off'
												id="pass"
												className="form-input__text"
												aria-describedby="pass-helper-text"
												value={password}
												onChange={(e) => setCustomNameAndValue(e.target.name, e.target.value)}
												type='text'
											/>
											<FormHelperText id="pass-helper-text" className='form-helper-text'>{errors.password ? errors.password.message : ""}</FormHelperText>
										</FormControl>
									) : (
										<Box className={styles['security-link']} sx={{ fontSize: '1.5rem' }}>{password.replace(/[\s\S]/g, "*")}</Box>
									)
								}
							</Grid>
							<Grid item xxs={1}>
								{
									showInputs.inpPassword ? (
										<IconButton aria-label="checked" onClick={() => handleShowInputs('inpPassword')} disableRipple>
											<DoneOutlineIcon />
										</IconButton>
									) : (
										<IconButton aria-label="edit" onClick={() => handleShowInputs('inpPassword')} disableRipple>
											<EditIcon />
										</IconButton>
									)
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{/* </form> */}
			</Box>
		</Box>
	)
}

export default observer(SecurityBlock);
