import React, { useState } from 'react';
import { Box, Stack, TextField, Rating, IconButton, Button, Grid } from '@mui/material';
import styled from '@emotion/styled';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { isDesktop, isMobile } from 'react-device-detect';
import clsx from 'clsx';

import styles from './FeedbackBlock.module.scss';

const WriteMessageTextarea = styled(TextField)({
	width: '100%',
	maxWidth: '400px',
	'& .MuiFilledInput-root': {
		borderRadius: '5px',
		border: '1px solid #bdbdbd',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
			borderColor: '#6fcce5'
		},
		'&:focus': {
			borderColor: '#6fcce5'
		}
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: '#424242',
		backgroundColor: 'transparent',
	}
});

const FeedbackBlock = () => {
	const [rating, setRating] = useState(2);
	const [messageBlock, setMessageBlock] = useState(false);

	const showHideMessageBlock = () => {
		setMessageBlock(!messageBlock);
	}

	return (
		<Stack spacing={2}>
			<Box className={styles['feedback-block']}>

				<Box className={styles['feedback-block__image']}>
					<Box className={styles['feedback-block__product']}>
						<img src='../assets/images/product-card/prod_front2.jpg' />
						{
							isDesktop && (
								<Box className={styles['product-info']}>
									<Box className={styles['product-info__title']}>NA-KD</Box>
									<Box className={styles['product-info__description']}>Легка сукня білого кольору </Box>
									<Box className={styles['product-info__bottom']}>
										<Box>38/M/46</Box>
										<Box className={styles['product-info__price']}>1099 ₴</Box>
									</Box>
								</Box>
							)
						}
					</Box>
					{
						isMobile && (
							<Box className={styles['product-info']}>
								<Box className={styles['product-info__title']}>Сукня від бренду NA-KD з застібкою</Box>
								<Box className={styles['product-info__bottom']}>
									<Box>38/M/46</Box>
									<Box className={styles['product-info__price']}>1099 ₴</Box>
								</Box>
								<Button className={clsx('button button-blue', styles['button-feedback'])} variant='contained' disableRipple onClick={showHideMessageBlock}>Відгук</Button>
							</Box>
						)
					}
				</Box>
				{
					((isMobile && messageBlock) || isDesktop) && (
						<Box className={styles['feedback-block__info']}>
							<Box sx={{ display: 'flex', justifyContent: { xxs: 'space-between', def: 'unset' }, flexDirection: { xxs: 'row', def: 'column' } }}>

								<Box className={styles['feedback-block__info-user']}>
									{
										isMobile && (
											<Box className={styles['user-title']}>Продавець</Box>
										)
									}
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Box className={styles['user-avatar']}><img src="../assets/images/avatar-profile.png" alt="" /></Box>
										<Box className={styles['user-name']}>Аліса Мілонська</Box>
									</Box>
								</Box>

								<Box className={styles['feedback-block__info-rating']}>
									{
										isMobile && (
											<Box className={styles['rating-title']}>Оцінка</Box>
										)
									}
									<Rating
										name="product-rating"
										sx={{ mt: { def: 4 }, alignSelf: 'flex-start' }}
										value={rating}
										onChange={(event, newValue) => {
											setRating(newValue);
										}}
									/>
								</Box>

							</Box>

							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Box sx={{ display: 'flex', mt: { xxs: 2, def: 4 }, justifyContent: 'flex-start' }}>
									<WriteMessageTextarea
										InputProps={{ disableUnderline: true }}
										id="outlined-multiline-static"
										label="Текст відгуку"
										multiline
										rows={4}
										variant="filled"
									/>
									<Box>
										<IconButton className={styles['remove-message']} disableRipple>
											<DeleteRoundedIcon />
										</IconButton>
									</Box>
								</Box>
								<Box sx={{ display: 'flex', justifyContent: 'flex-end', maxWidth: 400, mt: { xxs: 2, def: 3 } }}>
									<Button className='button button-blue' variant='contained' disableRipple>Опублікувати</Button>
								</Box>
							</Box>
						</Box>
					)
				}


			</Box>
		</Stack>
	)
}

export default FeedbackBlock
