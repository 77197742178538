import React, { createContext } from 'react';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import ReactDOM from 'react-dom/client';

import { GoogleOAuthProvider } from '@react-oauth/google';

import App from './App';
import RootStore from './store';
import './styles/index.scss';

const rootStore = new RootStore();

export const Context = createContext(rootStore);

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif'
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 480,
      sm: 568,
      ms: 640,
      md: 768,
      def: 1024,
      lg: 1280,
      xl: 1366,
      xxl: 1440,
      mg: 1560,
      xmg: 1668,
      hd: 1920
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<GoogleOAuthProvider clientId="1001872593487-4t0s1j9809sktj74lgmmejei39h65fr8.apps.googleusercontent.com">
    <ThemeProvider theme={theme}>
      <Context.Provider value={{rootStore}}>
        <App />
      </Context.Provider>
    </ThemeProvider>
	</GoogleOAuthProvider>
);

