import FeedbackBlock from '../../../../components/admin/shopper/feedback-block';
import LayoutSeller from '../../../../components/admin/layout';

const Feedback = () => {
  return (
    <LayoutSeller typeInfo='messages'>
      <FeedbackBlock />
    </LayoutSeller>
  )
}

export default Feedback;
