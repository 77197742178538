import React, { useState, useContext, cloneElement } from 'react';
import { MenuItem, MenuList, AppBar, Box, Toolbar, IconButton, Typography, useScrollTrigger, Drawer, Badge, Button } from '@mui/material';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
// import useScrollTrigger from '@mui/material/useScrollTrigger';
// import Drawer from '@mui/material/Drawer';
// import Badge from '@mui/material/Badge';
// import Button from '@mui/material/Button';
// import PersonIcon from '@mui/icons-material/Person';
import Search from '@mui/icons-material/Search';

import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import PersonIcon from '@mui/icons-material/Person';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
// import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';

import Divider from '@mui/material/Divider';
import { Stack, CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { clsx } from 'clsx';
import { useNavigate, NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

// import { useTranslation } from 'react-i18next'; тут теж потім треба буде для переводів
import i18n from '../../i18n';

import styles from './Header.module.scss';

import SearchInput from '../search';
import Auth from '../auth';

import { Context } from '../..';
import LeftBar from '../left-bar';

import LoginMenuClient from '../login-menu-client';

const menuItems = [
	// {
	// 		id: 1,
	// 		title: 'Моя інформація',
	// 		url: '/seller/my-info',
	// 		icon: <Person2RoundedIcon />
	// },
	// {
	// 		id: 2,
	// 		title: 'Адреса доставки',
	// 		url: '/seller/delivery-address',
	// 		icon: <LocalShippingRoundedIcon />
	// },
	// {
	// 		id: 3,
	// 		title: 'Безпека',
	// 		url: '/seller/security',
	// 		icon: <SecurityRoundedIcon />
	// },
	// {
	// 		id: 4,
	// 		title: 'Мої розміри',
	// 		url: '/seller/sizes',
	// 		icon: <StraightenRoundedIcon />
	// },
	{
			id: 1,
			title: 'Замовлення',
			url: '/shopper/orders',
			icon: <ShoppingBagRoundedIcon />
	},
	{
			id: 2,
			title: 'Повідомлення',
			url: '/shopper/message',
			icon: <MessageRoundedIcon />
	},
	{
			id: 3,
			title: 'Відгуки',
			url: '/shopper/feedback',
			icon: <StarRoundedIcon />
	},
	{
			id: 4,
			title: 'Підписки',
			url: '/shopper/subscription',
			icon: <AssignmentTurnedInRoundedIcon />
	},
	{
			id: 5,
			title: 'Обране',
			url: '/shopper/favorites',
			icon: <FavoriteRoundedIcon />
	},
	{
			id: 6,
			title: 'Переглянуте',
			url: '/shopper/reviews',
			icon: <RemoveRedEyeRoundedIcon />
	},
	{
		id: 7,
		title: 'Додати товар',
		url: '/seller/add-product',
		icon: <AddBusinessRoundedIcon />
	},
	{
		id: 8,
		title: 'Замовлення',
		url: '/seller/orders',
		icon: <InventoryRoundedIcon />
	}
];

function ElevationScroll({ children }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

const badgeStyle = {
  "& .MuiBadge-badge": {
    backgroundColor: '#6fcce5',
    color: '#ffffff'
  }
}

// const navItems = ['Жінкам', 'Чоловікам', 'Дитячі речі', 'Для дому', 'Краса і здоров\'я', 'Дитячі товари', 'Спорт і відпочинок'];

export default observer(function SearchAppBar(props) {
  const [showHideSearch, setShowHideSearh] = useState(false);
  const [openCatalog, setOpenCatalog] = useState(false);
  const [invisible, setInvisible] = useState(false);
	const [openMobCabinetLinks, setOpenMobCabinetLinks] = useState(false);
  // const [openMobSearch, setOpenMobSearch] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

	const navigate = useNavigate();

  const store = useContext(Context);

  const { authStore, langStore } = store.rootStore;

  // const { t } = useTranslation();//потім треба буде для переводів

  const handleLenguageChange = (attrLanguage) => {
    i18n.changeLanguage(attrLanguage);
    langStore.setKey('language');
    langStore.changeLanguage(attrLanguage);
  };

  const handleClickOpenDialog = (getIsLogin) => {
    setOpenDialog(true);
    authStore.setIsLogin(getIsLogin);
		authStore.setLogin('');
		authStore.setPassword('');
		authStore.setErrorMessage('');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };

  // const handleShowHideMobSearch = () => {
  //   setOpenMobSearch(!openMobSearch);
  // };

  // const handleClickAway = () => {
  //   setOpen(false)
  // }

  const handleSearch = () => {
    setShowHideSearh(!showHideSearch);
  }

  // const showMobSearch = openMobSearch ? '' : 'none';// true
  // const hideMobIcons = !openMobSearch ? '' : 'none';//!true

  // const handleScroll = () => {
	// 	if (window.pageYOffset > 400) {
	// 		setAppFixed(true);
	// 	} else {
	// 		setAppFixed(false);
	// 	}
	// };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll)
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenCatalog(open);
  };

	const handleOpenPopupCabinetOrLinks = () => {
		if (authStore.isAuth) {
			setOpenMobCabinetLinks(!openMobCabinetLinks);
		} else {
			// handleClickOpenDialog(true);
			setOpenDialog(true);
			authStore.setIsLogin(true);
			authStore.setLogin('');
			authStore.setPassword('');
			authStore.setErrorMessage('');
		}
	}

	const handleLinkToMyInfo = () => {
		const location = window.location.pathname;
		if (location.includes('my-info')) {
			setOpenMobCabinetLinks(false);
		} else {
			navigate('/seller/my-info');
		}
	}

	const handleLogout = () => {
		authStore.logoutAuth();
		navigate('/');
	}

  return (
    <StyledEngineProvider injectFirst>
      <Box className={styles['header']}>
        <CssBaseline />
        <ElevationScroll {...props}>
        {/* <HideOnScroll {...props} appFixed={appFixed}> */}
        {/* <Slide direction="down" in={appFixed}> */}
          <AppBar className={styles['header-app-bar']}>
            <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', minHeight: { xxs: '54px', sm: '64px', def: '80px' }, paddingLeft: { xxs: '10px', sm: '26px' }, paddingRight: { xxs: '10px', sm: '26px' } }}>
              {
                isMobile && (
                  <>
                    <Stack direction="row" alignItems="center">
                      <IconButton className={styles['header-burger']} disableRipple onClick={toggleDrawer(true)}>
                        <MenuIcon className={styles['header-burger__icon']} />
                      </IconButton>
                    </Stack>
                    <Link to='/' className={styles['header-logo']}>MarkeT</Link>
                    <Drawer
											anchor='left'
											open={openCatalog}
											onClose={toggleDrawer(false)}
											sx={{
												'& .MuiDrawer-paper': {
													width: 270,
													boxSizing: 'border-box',
													padding: '1rem'
												}
											}}
										>
											<LeftBar />
										</Drawer>
                  </>
                )
              }
              {
                isMobile ? (
                  <Box className={styles['header-bottom']}>
                    <Box className={clsx(styles['header-bottom__search'], `${showHideSearch ? styles['is-open'] : ''}`)}>
                      <IconButton disableRipple sx={{ p: 1 }} onClick={handleSearch}>
                        <ArrowBackIosNewRoundedIcon />
                      </IconButton>
                      <Box width='100%'>
                        <SearchInput />
                      </Box>
                    </Box>
                    <Box className={clsx(styles['header-bottom__icons'], `${showHideSearch ? styles['is-hide'] : ''}`)}>
                      { /*
                        headerBottomElems.map(item => (
                          <Box className={styles['header-bottom__item']} key={item.id}>
                            <IconButton disableRipple className={styles['header-icons__item']} onClick={item.props.clickHandler}>
                            {
                              item.badgeContent ? (
                                <Badge badgeContent={item.badgeContent} sx={badgeStyle}>{item.icon}</Badge>
                              ) : (
                                item.icon
                              )
                            }
                            </IconButton>
                            <Typography variant='body' className={styles['header-bottom__item-title']}>{item.title}</Typography>
                          </Box>
                        ))
                      */ }
                      <Box className={styles['header-bottom__item']}>
                        <IconButton disableRipple className={styles['header-icons__item']}>
                          <Badge badgeContent='2' sx={badgeStyle}><FavoriteBorderRoundedIcon /></Badge>
                        </IconButton>
                        <Typography variant='body' className={styles['header-bottom__item-title']}>Збережене</Typography>
                      </Box>
                      <Box className={styles['header-bottom__item']} onClick={handleSearch}>
                        <IconButton disableRipple className={styles['header-icons__item']}>
                          <Search />
                        </IconButton>
                        <Typography variant='body' className={styles['header-bottom__item-title']}>Пошук</Typography>
                      </Box>
                      <Box className={styles['header-bottom__item']}>
                        <IconButton disableRipple className={styles['header-icons__item']}>
                          <ShoppingCartOutlinedIcon />
                        </IconButton>
                        <Typography variant='body' className={styles['header-bottom__item-title']}>Кошик</Typography>
                      </Box>
                      <Box className={styles['header-bottom__item']}>
                        <IconButton disableRipple className={styles['header-icons__item']}>
                          <SmsOutlinedIcon />
                        </IconButton>
                        <Typography variant='body' className={styles['header-bottom__item-title']}>Повідомлення</Typography>
                      </Box>
                      <Box className={styles['header-bottom__item']}>
                        <IconButton disableRipple className={styles['header-icons__item']} onClick={handleOpenPopupCabinetOrLinks}>
                          <PersonOutlineOutlinedIcon />
                        </IconButton>
                        <Typography variant='body' className={styles['header-bottom__item-title']}>Профіль</Typography>
												<Drawer
													anchor='left'
													open={openMobCabinetLinks}
													onClose={() => setOpenMobCabinetLinks(false)}
													hideBackdrop
													sx={{
														'& .MuiDrawer-paper': {
															width: '100%',
															bottom: '70px',
															height: 'auto',
															boxShadow: 'none'
														},
														'&.MuiDrawer-root': {
															bottom: '70px'
														}
													}}
												>
													<MenuList
														id="composition-menu"
														aria-labelledby="composition-button"
														className='login-menu__list'
													>
														<MenuItem className='login-menu__header' disableRipple>
															<Box sx={{ display: 'flex', alignItems: 'center' }}>
																<Box className='login-menu__header-user'>
																	<Box className='login-menu__header-image'><img src={'https://zefir.store/' + authStore.clientData.photo} alt="user avatar" /></Box>
																	<Box className='login-menu__header-star'>
																		<Box className='count-raiting'>4.95</Box>
																		<StarRoundedIcon />
																	</Box>
																</Box>
																<Box className='login-menu__header-user-name'>
																	<Box sx={{ fontWeight: 600 }}>Вітаємо,</Box>
																	<Box>{(authStore.clientData.surname ? (authStore.clientData.surname + ' ') : '') + authStore.clientData.firstname}</Box>
																</Box>
															</Box>
															{ /* <Box className='login-menu__header-close'>
																<IconButton onClick={() => setOpenMobCabinetLinks(false)} className='button-close' variant='contained' disableRipple>
																	<ClearRoundedIcon />
																</IconButton>
															</Box> */ }
															<Box>
																<IconButton disableRipple onClick={handleLinkToMyInfo}><ArrowForwardIosRoundedIcon /></IconButton>
															</Box>
														</MenuItem>
														{
															menuItems.map(item => (
																<MenuItem className='login-menu__item' key={item.id} disableRipple>
																	<NavLink to={item.url} className='login-menu__list-item'>
																		<Box sx={{ display: 'flex', alignItems: 'center' }}>
																			<IconButton className='login-menu__list-icon' disableRipple>{item.icon}</IconButton>
																			<Box className='login-menu__list-text'>{item.title}</Box>
																		</Box>
																		<Box>
																			<IconButton><ArrowForwardIosRoundedIcon /></IconButton>
																		</Box>
																	</NavLink>
																</MenuItem>
															))
														}
														<MenuItem className='login-menu__item login-menu__item-bottom' onClick={handleLogout}>
															<Box className='login-menu__list-item' sx={{ justifyContent: 'unset !important' }}>
																<IconButton className='login-menu__list-icon' disableRipple><LogoutRoundedIcon /></IconButton>
																<Box className='login-menu__list-text'>Вихід</Box>
															</Box>
														</MenuItem>
													</MenuList>
												</Drawer>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Stack flexDirection='row'>
                      <Link to='/' className={styles['header-logo']}>MarkeT</Link>
                      <Stack direction="row" divider={<Divider orientation="vertical" flexItem variant='middle' />} spacing={0.5} sx={{ ml: 4, display: { xxs: 'none', def: 'flex' } }}>
                        <Button className={clsx(styles['header-language'], langStore.language === 'uk' && styles['is-active'])} disableRipple onClick={() => handleLenguageChange('uk')}>UA</Button>
                        <Button className={clsx(styles['header-language'], langStore.language === 'ru' && styles['is-active'])} disableRipple onClick={() => handleLenguageChange('ru')}>RU</Button>
                      </Stack>
                    </Stack>
                    <Box sx={{ display: 'block', width: '700px' }}>
                      <SearchInput />
                    </Box>
                    <Box sx={{ ml: { def: 2 } }} className={styles['header-icons']}>
                      <IconButton disableRipple className={styles['header-icons__item-search']} sx={{ display: { xxs: 'flex', def: 'none' } }} onClick={handleSearch}>
                        <Search />
                      </IconButton>
                      <IconButton disableRipple className={styles['header-icons__item']} onClick={handleBadgeVisibility}>
                        <Badge variant="dot" sx={badgeStyle} invisible={invisible}>
                          <NotificationsNoneRoundedIcon />
                        </Badge>
                      </IconButton>
                      <IconButton disableRipple={true} className={styles['header-icons__item']}>
                        <MailOutlineRoundedIcon />
                      </IconButton>
                      <IconButton disableRipple className={styles['header-icons__item']}>
                        <Badge badgeContent={2} sx={badgeStyle}>
                          <FavoriteBorderRoundedIcon />
                        </Badge>
                      </IconButton>
                      <IconButton disableRipple className={styles['header-icons__item']}>
                        <VisibilityRoundedIcon />
                      </IconButton>
                      <Box sx={{ ml: { def: 4, xl: 7 }, display: 'flex' }}>
                        <IconButton disableRipple className={styles['header-icons__item']}>
                          <Badge badgeContent={0} sx={badgeStyle}>
                            <ShoppingCartIcon />
                          </Badge>
                        </IconButton>
												{
													authStore.isAuth ? (
														<LoginMenuClient />
													) : (
														<IconButton disableRipple className={styles['header-icons__item']} onClick={handleOpenPopupCabinetOrLinks}>
															<PersonIcon />
														</IconButton>
													)
												}
                      </Box>
                      { /* <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={0.5} sx={{ ml: 2, display: { xxs: 'none', def: 'flex' } }}>
                        <Button
                          className={styles['header-auth__link']}
                          sx={{ minWidth: 'unset' }}
                          onClick={() => handleClickOpenDialog(true)}
                          disableRipple={true}
                        >
                          {t('Вхід')}
                        </Button>
                        <Button
                          className={styles['header-auth__link']}
                          onClick={() => handleClickOpenDialog(false)}
                          disableRipple={true}
                        >
                          {t('Реєстрація')}
                        </Button>
                      </Stack> */ }
                    </Box>
                  </>
                )
              }

            </Toolbar>
            { /* <Toolbar className={styles['header-catalog']} sx={{ display: { xs: 'none', md: 'flex' } }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box className={styles['header-catalog__box']}>
                  <Button
                    disableRipple={true}
                    startIcon={<MenuIcon />}
                    className={`${styles['header-catalog__button']}${open ? ` ${styles['is-active']}` : ''}`}
                    onClick={handleClick}
                  >{t('Каталог')}</Button>

                  <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                          <Typography sx={{ p: 2 }}>
                            <Link to="/product">Непонятный продукт</Link>
                          </Typography>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>

                  {navItems.map((item) => (
                    <Link key={item} to="#" className={styles['header-catalog__link']} >
                      {item}
                    </Link>
                  ))}
                </Box>
              </ClickAwayListener>
            </Toolbar> */ }
            {/* <Toolbar /> */}
          </AppBar>
        </ElevationScroll>
        {/* </Slide> */}
        {/* </HideOnScroll> */}

        <Toolbar sx={{ minHeight: { xxs: '54px', sm: '64px', def: '80px' } }} />
        {/* <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }} /> */}
        <Auth openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
      </Box>
    </StyledEngineProvider>
  );
});
