import React, { useContext } from 'react';
import { Box, IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';

import { useNavigate } from 'react-router-dom';

import { Context } from '../../..';

import './LeftBarAdmin.scss';

// const userAvatar = '../assets/images/avatar-profile.png';
// const userNoAvatar = '../assets/images/no-avatar.png';

const menuItems = [
  [
      {
          id: 1,
          title: 'Моя інформація',
          url: '/shopper/my-info',
          icon: <Person2RoundedIcon />
      },
      {
          id: 2,
          title: 'Адреса доставки',
          url: '/shopper/delivery-address',
          icon: <LocalShippingRoundedIcon />
      },
      {
          id: 3,
          title: 'Безпека',
          url: '/shopper/security',
          icon: <SecurityRoundedIcon />
      },
      {
          id: 4,
          title: 'Мої розміри',
          url: '/shopper/sizes',
          icon: <StraightenRoundedIcon />
      }
  ],
  [
      {
          id: 5,
          title: 'Замовлення',
          url: '/shopper/orders',
          icon: <ShoppingBagRoundedIcon />
      },
      {
          id: 6,
          title: 'Повідомлення',
          url: '/shopper/message',
          icon: <MessageRoundedIcon />
      },
      {
          id: 7,
          title: 'Відгуки',
          url: '/shopper/feedback',
          icon: <StarRoundedIcon />
      }
  ],
  [
      {
				id: 8,
				title: 'Підписки',
				url: '/shopper/subscription',
				icon: <AssignmentTurnedInRoundedIcon />
      },
      {
				id: 9,
				title: 'Обране',
				url: '/shopper/favorites',
				icon: <FavoriteRoundedIcon />
      },
      {
				id: 10,
				title: 'Переглянуте',
				url: '/shopper/reviews',
				icon: <RemoveRedEyeRoundedIcon />
      }
  ],
	[
		{
			id: 11,
			title: 'Додати товар',
			url: '/seller/add-product',
			icon: <AddBusinessRoundedIcon />
		},
		{
			id: 12,
			title: 'Замовлення',
			url: '/seller/orders',
			icon: <InventoryRoundedIcon />
		}
]
];

const LeftBarAdmin = () => {
	const navigate = useNavigate();
	const store = useContext(Context);
	const { logoutAuth } = store.rootStore.authStore;
	const { photo } = store.rootStore.myInfoStore;

	const handleLogout = () => {
		logoutAuth();
		navigate('/');
	}

  return (
    <Box className='leftbar-admin'>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
				{
					photo && (
						<Box className='leftbar-admin__avatar'>
							<img src={photo} alt='user avatar' />
						</Box>
					)
				}
        {
          menuItems.map((itemGroup, index) => (
						<Box className='leftbar-admin__group' key={index}>
							{
								itemGroup.map(item => (
									<NavLink to={item.url} className='leftbar-admin__item' key={item.id}>
										<IconButton className='leftbar-admin__icon-button' disableRipple>{item.icon}</IconButton>
										<Box className='leftbar-admin__item-text'>{item.title}</Box>
									</NavLink>
								))
							}
						</Box>
          ))
        }
      </Box>
      <Box className='leftbar-admin__bottom'>
        <Box className='leftbar-admin__bottom-item'>
          <IconButton className='leftbar-admin__bottom-item-icon'>
            <HelpRoundedIcon />
          </IconButton>
          <Box className='leftbar-admin__bottom-item-text'>Допомога</Box>
        </Box>
        <Box className='leftbar-admin__bottom-item'>
          <IconButton className='leftbar-admin__bottom-item-icon'>
            <LogoutRoundedIcon />
          </IconButton>
          <Box className='leftbar-admin__bottom-item-text' onClick={handleLogout}>Вийти</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LeftBarAdmin;
