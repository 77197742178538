import { Container } from '@mui/system';
import React from 'react';

import Layout from '../components/layout'

const About = () => {
  return (
    <Layout>
      <Container>
        About Us
      </Container>
    </Layout>
  )
}

export default About