import LayoutSeller from '../../../../components/admin/layout';
import DeliveryAddressBlock from '../../../../components/admin/shopper/delivery-address-block';

const DeliveryAddress = () => {
  return (
      <LayoutSeller typeInfo='client-info'>
        <DeliveryAddressBlock />
      </LayoutSeller>
  )
}

export default DeliveryAddress;
