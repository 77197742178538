import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import { Box, IconButton, Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './ProductSlickSlider.scss';

const ProductSlickSlider = ({ images }) => {
	const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
	let slider2 = useRef(null);

	const setSlider2Ref = (currSlider) => {
		slider2 = currSlider;
		setNav2(slider2);
	}

	// const previousSlider2 = () => {
	// 	slider2.slickPrev();
	// }

	// const nextSlider2 = () => {
	// 	slider2.slickNext();
	// }

	const settingsMainSlider = {
		infinite: true,
		// speed: 500,
		slidesToShow: 1,
		// slidesToScroll: 1,
		arrows: false,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					dots: true,
					swipe: true
				}
			}
		]
	};
	const settingsNavSlider = {
		asNavFor: nav1,
		ref: setSlider2Ref,
		slidesToShow: 3,
		// swipeToSlide: true,
		focusOnSelect: true,
		arrows: false,
		vertical: true,
		verticalSwiping: true,
		autoplay: false,
	};

	return (
		<Box className="product-slick-slider">
			<Grid container>
				<Box sx={{ display: { xxs: 'none', ms: 'flex' }, pr: 2, width: '20%', alignItems: 'center' }}>
					<Box className="product-slick-slider__second">
						<Slider {...settingsNavSlider}>
							{
								images.map((image, index) => (
									<Box className="product-slick-slider__second-image"><img key={index} src={image} alt={`product-${index}`} /></Box>
								))
							}
						</Slider>
						{ /* <IconButton onClick={previousSlider2} className="product-slick-slider__second-button button-prev" disableRipple>
							<ArrowBackIosNewIcon />
						</IconButton>
						<IconButton onClick={nextSlider2} className="product-slick-slider__second-button button-next" disableRipple>
							<ArrowForwardIosIcon />
						</IconButton> */ }
					</Box>
				</Box>
				<Box sx={{ pl: { xxs: 0, ms: 2 }, width: { xxs: '100%', ms: '80%' } }}>
					<Slider
						{...settingsMainSlider}
						asNavFor={nav2}
						ref={(slider1) => setNav1(slider1)}
						className="product-slick-slider__main"
					>
						{
							images.map((image, index) => (
								<Box className="product-slick-slider__main-image"><img key={index} src={image} alt={`product-${index}`} /></Box>
							))
						}
					</Slider>
				</Box>
			</Grid>
		</Box>
	);
}

export default ProductSlickSlider
