import React from 'react'
import { Container, Box, Stack, Divider, IconButton, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { Instagram, Facebook, YouTube } from '@mui/icons-material';
import { StyledEngineProvider } from '@mui/material/styles';

import styles from './Footer.module.scss';
import ButtonUp from '../button-up';

import { novaPoshta, ukrPoshta } from '../../assets/svg';

const Footer = () => {
  const currentTime = new Date();
  const year = currentTime.getFullYear();

  return (
    <Box className={styles['footer']}>
      <Container maxWidth='mg'>
        <Grid container>
          <Grid item xxs='12' def='4' className={styles['footer-links']}>
            <Box>
              <Link to='/' style={{ textDecoration: 'none' }}>
                <Typography
                  variant="h6"
                  component="div"
                  className={styles['footer-logo']}
                >
                  MarkeT
                </Typography>
              </Link>
            </Box>
            <Link to="#" className={styles['footer-links__item']}>Карта сайту</Link>
            <Link to="#" className={styles['footer-links__item']}>Угода користувача</Link>
            <Link to="#" className={styles['footer-links__item']}>Договір публічної оферти</Link>
          </Grid>
          <Grid item xxs='12' def='4' className={styles['footer-delivery']}>
            <Box className={styles['footer-title']}>Способи доставки</Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, gridGap: 25 }}>
              <Box><Link to='#'><img className={styles['footer-delivery__icon']} src={novaPoshta} alt="nova poshta" /></Link></Box>
              <Box><Link to='#'><img className={styles['footer-delivery__icon']} src={ukrPoshta} alt="ukr poshta" /></Link></Box>
            </Box>
          </Grid>
          <Grid item xxs='12' def='4' className={styles['footer-socials']}>
            <Box component='span'>Наші соц. мережі</Box>
            <Box sx={{ mt: 1 }}>
              <Link to="https://instagram.com" target='_blank' className={styles['footer-socials__link']}>
                <IconButton className={styles['footer-socials__button']} disableRipple={true}>
                  <Instagram fontSize='large' />
                </IconButton>
              </Link>
              <Link to="https://facebook.com" target='_blank' className={styles['footer-socials__link']}>
                <IconButton className={styles['footer-socials__button']} disableRipple={true}>
                  <Facebook fontSize='large' />
                </IconButton>
              </Link>
              <Link to='https://youtube.com' target='_blank' className={styles['footer-socials__link']}>
                <IconButton className={styles['footer-socials__button']} disableRipple={true}>
                  <YouTube fontSize='large' />
                </IconButton>
              </Link>
            </Box>
          </Grid>
        </Grid>
        { /* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 7 }} justifyContent='center' sx={{ position: 'relative' }}>
          <Grid xs={12} className={styles['footer-links']} item>
            <Link to="/about" className={styles['footer-links__item']}>Про нас</Link>
            <Link to="#" className={styles['footer-links__item']}>Політика конфіденційності</Link>
            <Link to="#" className={styles['footer-links__item']}>Договір оферта</Link>
            <Link to="/contacts" className={styles['footer-links__item']}>Контакти</Link>
          </Grid>
          <Divider variant='middle' orientation='vertical' flexItem component='div' sx={{ display: { xs: 'none', sm: 'flex' } }} />
          <Divider variant='middle' orientation='horizontal' component='div' sx={{ display: { xs: 'flex', sm: 'none' } }} />
          <Grid xs={12} item>Україна, 58000,<br />м.Чернівці,<br />вул. Головна, 777</Grid>
          <Divider variant='middle' orientation='vertical' flexItem component='div' sx={{ display: { xs: 'none', sm: 'flex' } }} />
          <Divider variant='middle' orientation='horizontal' component='div' sx={{ display: { xs: 'flex', sm: 'none' } }} />
          <Grid xs={12} className={styles['footer-socials']} item>
            <Box component='span'>Ми в соц.мережах</Box>
            <Box sx={{ mt: 1 }}>
              <Link to="https://instagram.com" target='_blank' className={styles['footer-socials__link']}>
                <IconButton className={styles['footer-socials__button']} disableRipple={true}>
                  <Instagram />
                </IconButton>
              </Link>
              <Link to="https://facebook.com" target='_blank' className={styles['footer-socials__link']}>
                <IconButton className={styles['footer-socials__button']} disableRipple={true}>
                  <Facebook />
                </IconButton>
              </Link>
              <Link to='https://youtube.com' target='_blank' className={styles['footer-socials__link']}>
                <IconButton className={styles['footer-socials__button']} disableRipple={true}>
                  <YouTube />
                </IconButton>
              </Link>
            </Box>
          </Grid>
        </Stack>
      */ }
      </Container>
      <Box className={styles['footer-copy']}>
        <Box component="span">&copy; {year}</Box>
        <Link to="/" className={styles['footer-copy__link']}>market.ua</Link>
      </Box>
      <ButtonUp />
    </Box>
  )
}

export default Footer
