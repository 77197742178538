import React, { useState, useEffect } from 'react'
import { Box, IconButton, Fade } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import styles from './ButtonUp.module.scss';

const ButtonUp = () => {
	const [isVisible, setIsVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	
	const handleScroll = () => {
		if (window.pageYOffset > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};
	
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Box className='button--up'>
      <Fade in={isVisible}>
        <IconButton
          className={styles['button-up']}
          onClick={scrollToTop}
          color="primary"
          aria-label="Scroll to top"
					disableRipple={true}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      </Fade>
    </Box>
	)
}

export default ButtonUp;