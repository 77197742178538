import React from 'react';
import { Box, Badge, Avatar, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import clsx from 'clsx';
import styles from './MessageBlock.module.scss';

const badgeStyleConnectionType = {
  "& .MuiBadge-badge": {
    backgroundColor: '#87ba65',
    color: '#ffffff',
		bottom: '8px',
    right: '8px'
  }
}

const sendButton = {
	'&.MuiInputAdornment-root': {
		cursor: 'pointer'
	},
	'& .MuiSvgIcon-root': {
		transition: 'fill .3s'
	},
	'&:hover .MuiSvgIcon-root': {
		fill: '#6fcce5'
	}
}

const MessageInfo = ({ activeUserMessages }) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<Box className={styles['message-content__header']}>
				<Badge color="secondary" sx={badgeStyleConnectionType} variant="dot" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} invisible={activeUserMessages.connectionType === 'online' ? false : true}>
					<Avatar alt="message user avatar" src={activeUserMessages.avatar} className={styles['message-item__avatar']} />
				</Badge>
				<Box className={styles['message-item__description']}>
					<Box className={styles['author-name']}>{activeUserMessages.authorName}</Box>
					<Box className={styles['last-message']}>{activeUserMessages.connectionType}</Box>
				</Box>
			</Box>
			<Box className={styles['message-content__wrapper']}>
				<Box className={styles['message-content__wrapper-container']}>
					{
						activeUserMessages.messages.map(mess => (
							<Box className={clsx(mess.from === 'me' ? styles['message-gray'] : styles['message-white'])} key={mess.id}>
								<Box className={styles['message-content__wrapper-text']}>{mess.text}</Box>
								<Box className={styles['message-content__wrapper-time']}>{mess.time}</Box>
							</Box>
						))
					}
				</Box>
			</Box>
			<form className={styles['message-content__send']}>
				<FormControl variant="standard" sx={{ width: '100%' }}>
					<OutlinedInput
						id="input-with-icon-adornment"
						className={clsx('form-input__text', styles['form-input__text-send'])}
						placeholder='Повідомлення'
						endAdornment={
							<InputAdornment position="end" sx={sendButton}>
								<SendIcon />
							</InputAdornment>
						}
					/>
				</FormControl>
			</form>
		</Box>
	)
}

export default MessageInfo;
