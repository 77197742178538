import Lang from "./lang";
import Auth from "./auth";
import Categories from "./categories";
import Search from "./search";
import MyInfo from "./my-info";
import AddProduct from "./add-product";
import OrdersSeller from "./ordersSeller";
import Catalog from "./catalog";

class RootStore {
	authStore;
	langStore;
	categoriesStore;
	searchStore;
	myInfoStore;
	addProductStore;
	ordersSeller;
	catalog;

	constructor () {
		this.authStore = new Auth(this);
		this.langStore = new Lang(this);
		this.categoriesStore = new Categories(this);
		this.searchStore = new Search(this);
		this.myInfoStore = new MyInfo(this);
		this.addProductStore = new AddProduct(this);
		this.ordersSeller = new OrdersSeller(this);
		this.catalogStore = new Catalog(this);
	}
}

export default RootStore;
