import { Typography, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import withAuth from '../../../hoc/withAuth';
import { isDesktop } from 'react-device-detect';

import FavoriteReviewCard from './FavoriteReviewCard';

import styles from './FavoriteReviewBlock.module.scss';
import PaginationSeller from '../pagination-seller';

const favRevProd = {
	favorites: [
		{
			id: 1,
			image: '../assets/images/product-card/prod_front1.jpg',
			title: 'Топ з відкритими плечима',
			price: '33 ₴',
			priceOld: '100 ₴',
			options: [
				{
					id: 1,
					name: 'Розмір',
					type: 'text',
					values: [
						{
							id: 1,
							value: 'S',
							isActive: 0
						},
						{
							id: 2,
							value: 'M',
							isActive: 1
						},
						{
							id: 3,
							value: 'L',
							isActive: 0
						},
						{
							id: 4,
							value: 'XL',
							isActive: 0
						}
					]
				},
				{
					id: 2,
					name: 'Колір',
					type: 'media',
					values: [
						{
							id: 1,
							value: '#316586',
							isActive: 1
						},
						{
							id: 2,
							value: '#ffb1bf',
							isActive: 0
						}
					]
				}
			]
		},
		{
			id: 2,
			image: '../assets/images/product-card/prod_front2.jpg',
			title: 'Сукня міді в рубчик',
			price: '93 ₴',
			priceOld: '100 ₴',
			options: [
				{
					id: 1,
					name: 'Розмір',
					type: 'text',
					values: [
						{
							id: 1,
							value: 'S',
							isActive: 1
						},
						{
							id: 2,
							value: 'M',
							isActive: 0
						},
						{
							id: 3,
							value: 'L',
							isActive: 0
						},
						{
							id: 4,
							value: 'XL',
							isActive: 0
						}
					]
				},
				{
					id: 2,
					name: 'Колір',
					type: 'media',
					values: [
						{
							id: 1,
							value: '#FFB1BF',
							isActive: 1
						},
						{
							id: 2,
							value: '#FF8300',
							isActive: 0
						},
						{
							id: 3,
							value: '#FF5074',
							isActive: 0
						}
					]
				}
			]
		},
		{
			id: 3,
			image: '../assets/images/product-card/prod_front4.jpg',
			title: 'Джинси мом з високою посадкою',
			price: '97 ₴',
			priceOld: '120 ₴',
			options: [
				{
					id: 1,
					name: 'Розмір',
					type: 'text',
					values: [
						{
							id: 1,
							value: 'S',
							isActive: 0
						},
						{
							id: 2,
							value: 'M',
							isActive: 0
						},
						{
							id: 3,
							value: 'L',
							isActive: 1
						},
						{
							id: 4,
							value: 'XL',
							isActive: 0
						}
					]
				},
				{
					id: 2,
					name: 'Колір',
					type: 'media',
					values: [
						{
							id: 1,
							value: '#316586',
							isActive: 1
						},
						{
							id: 2,
							value: '#FFB1BF',
							isActive: 0
						},
						{
							id: 3,
							value: '#FF8300',
							isActive: 0
						},
						{
							id: 4,
							value: '#FF5074',
							isActive: 0
						}
					]
				}
			]
		},
		{
			id: 4,
			image: '../assets/images/product-card/prod_front6.jpg',
			title: 'Топ з відкритими плечима',
			price: '33 ₴',
			priceOld: '100 ₴',
			options: [
				{
					id: 1,
					name: 'Розмір',
					type: 'text',
					values: [
						{
							id: 1,
							value: 'S',
							isActive: 0
						},
						{
							id: 2,
							value: 'M',
							isActive: 1
						},
						{
							id: 3,
							value: 'L',
							isActive: 0
						},
						{
							id: 4,
							value: 'XL',
							isActive: 0
						}
					]
				},
				{
					id: 2,
					name: 'Колір',
					type: 'media',
					values: [
						{
							id: 1,
							value: '#316586',
							isActive: 1
						},
						{
							id: 2,
							value: '#ffb1bf',
							isActive: 0
						}
					]
				}
			]
		},
		{
			id: 5,
			image: '../assets/images/product-card/prod_front5.jpg',
			title: 'Сукня міді в рубчик',
			price: '93 ₴',
			priceOld: '100 ₴',
			options: [
				{
					id: 1,
					name: 'Розмір',
					type: 'text',
					values: [
						{
							id: 1,
							value: 'S',
							isActive: 1
						},
						{
							id: 2,
							value: 'M',
							isActive: 0
						},
						{
							id: 3,
							value: 'L',
							isActive: 0
						},
						{
							id: 4,
							value: 'XL',
							isActive: 0
						}
					]
				},
				{
					id: 2,
					name: 'Колір',
					type: 'media',
					values: [
						{
							id: 1,
							value: '#FFB1BF',
							isActive: 1
						},
						{
							id: 2,
							value: '#FF8300',
							isActive: 0
						},
						{
							id: 3,
							value: '#FF5074',
							isActive: 0
						}
					]
				}
			]
		},
		{
			id: 6,
			image: '../assets/images/product-card/prod_front8.jpg',
			title: 'Джинси мом з високою посадкою',
			price: '97 ₴',
			priceOld: '120 ₴',
			options: [
				{
					id: 1,
					name: 'Розмір',
					type: 'text',
					values: [
						{
							id: 1,
							value: 'S',
							isActive: 0
						},
						{
							id: 2,
							value: 'M',
							isActive: 0
						},
						{
							id: 3,
							value: 'L',
							isActive: 1
						},
						{
							id: 4,
							value: 'XL',
							isActive: 0
						}
					]
				},
				{
					id: 2,
					name: 'Колір',
					type: 'media',
					values: [
						{
							id: 1,
							value: '#316586',
							isActive: 1
						},
						{
							id: 2,
							value: '#FFB1BF',
							isActive: 0
						},
						{
							id: 3,
							value: '#FF8300',
							isActive: 0
						},
						{
							id: 4,
							value: '#FF5074',
							isActive: 0
						}
					]
				}
			]
		}
	]
};

const FavoriteReviewBlock = ({ typePage }) => {
	const textNotProd = typePage === 'favorites' ? ' обраних ' : ' переглянутих ';
	const siblingCountPagination = isDesktop ? 1 : 0;
	return (
		<>
		{
			favRevProd[typePage] !== undefined && favRevProd[typePage].length ? (
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
					<Box className={styles['container-products']}>
						{
							favRevProd[typePage].map(arrayItem => (
								<FavoriteReviewCard productInfo={arrayItem} key={arrayItem.id} />
							))
						}
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
						<PaginationSeller count={10} shape="rounded" defaultPage={1} siblingCount={siblingCountPagination} />
						{/* <Pagination count={10} shape="rounded" defaultPage={4} /> */}
					</Box>
				</Box>

			) : (
				<Typography>У Вас поки що немає {textNotProd} товарів!</Typography>
			)
		}
		</>
	)
}

export default withAuth(observer(FavoriteReviewBlock));
