import React, { useState } from 'react';
import clsx from 'clsx';

import LoadingSpinner from '../loading-spinner';

import styles from './ImageLoader.module.scss';

const ImageLoader = ({ src, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className={styles['preloader']}>
          <LoadingSpinner />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoaded}
        className={clsx(styles['preloader-image'], (className !== '' ? className : ''), ( isLoading ? '' : styles['preloader-image__active'] ))}
      />
    </>
  );
};

export default ImageLoader;