import { Box, Typography, Rating, Grid } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';

import styles from './TopSeller.module.scss';

const labels = {
	0.5: '0.5',
	1: '1.0',
	1.5: '1.5',
	2: '2.0',
	2.5: '2.5',
	3: '3.0',
	3.5: '3.5',
	4: '4.0',
	4.5: '4.5',
	5: '5.0',
};

const TopSeller = ({ sellerInfo }) => {
  return (
		<Box sx={{ pl: { def: 2 }, pr: { def: 2 } }}>
			{ /* <Grid container className={styles['top-seller']}>
				<Grid item xxs='4'><img className={styles['top-seller__image']} src={sellerInfo.image} alt="seller-avatar" /></Grid>
				<Grid item xxs='8' className={styles['top-seller__description']}>
					<Typography fontWeight={600}>{sellerInfo.name}</Typography>
					<Box className={styles['top-seller__description-text']}>
						<Box className={styles['top-seller__reviews']}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Rating name="half-rating-read" defaultValue={sellerInfo.rating} precision={0.5} readOnly size="small" />
								<Box sx={{ ml: 1 }}>{labels[sellerInfo.rating]}</Box>
							</Box>
							<Box sx={{ ml: { def: 1 } }}>({sellerInfo.countReviews} відгука)</Box>
						</Box>
						<Box sx={{ mt: 1 }}>
							{
								sellerInfo.efficiencies.map(efficiency => (
									<Box sx={{ display: 'flex', alignItems: { def: 'center' } }}>
										<SquareIcon sx={{ fontSize: '.7rem', color: '#d9d9d9', mt: { xxs: '5px', def: 0 } }} />
										<Box sx={{ ml: 1 }}>{efficiency}</Box>
									</Box>
								))
							}
						</Box>
					</Box>
				</Grid>
			</Grid> */ }
			<Box className={styles['top-seller']}>
				{/* <Box className={styles['top-seller__image']}> */}
				<img className={styles['top-seller__image']} src={sellerInfo.image} alt="seller-avatar" />
				{/* </Box> */}
				<Box className={styles['top-seller__description']}>
					<Typography fontWeight={600}>{sellerInfo.name}</Typography>
					<Box className={styles['top-seller__description-text']}>
						<Box className={styles['top-seller__reviews']}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Rating name="half-rating-read" defaultValue={sellerInfo.rating} precision={0.5} readOnly size="small" />
								<Box sx={{ ml: 1 }}>{labels[sellerInfo.rating]}</Box>
							</Box>
							<Box sx={{ ml: { def: 1 } }}>({sellerInfo.countReviews} відгука)</Box>
						</Box>
						<Box sx={{ mt: 1 }}>
							{
								sellerInfo.efficiencies.map((efficiency, index) => (
									<Box sx={{ display: 'flex', alignItems: { def: 'center' } }} key={index}>
										<SquareIcon sx={{ fontSize: '.7rem', color: '#d9d9d9', mt: {xxs: '5px', def: 0 } }} />
										<Box sx={{ ml: 1 }}>{efficiency}</Box>
									</Box>
								))
							}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
  )
}

export default TopSeller