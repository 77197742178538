import { makeAutoObservable } from "mobx";

class Lang {
	key = 'language';
	language = 'uk';

	constructor () {
		makeAutoObservable(this);
		this.setLanguage(this.language);
	}

	setKey (key) {
		this.key = key;
	}

	changeLanguage (newLanguage) {
		try {
			localStorage.setItem(this.key, JSON.stringify(newLanguage))
		} catch (error) {
			console.log (error)
		}
		this.language = newLanguage;
	}

	setLanguage (language) {
		const value = localStorage.getItem(this.key);
		if (value) {
				this.language = JSON.parse(value);
		} else {
				this.language = language;
		}
	};
}

export default Lang;
