import { useState, useContext } from 'react';
import { Box, Stack, Button, MenuItem, Fade, ClickAwayListener, Popper, Paper, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
// import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

// import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { DateRange } from 'react-date-range';
import { uk } from 'date-fns/locale';
import { format } from 'date-fns';
import { numberWithSpaces, showProductPrice } from '../../../../utils/functions';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import withAuth from '../../../hoc/withAuth';
import { Context } from '../../../..';

import styles from './OrdersBlock.module.scss';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/primereact.css';
import './DataTablesCustom.scss';

const OrdersBlock = () => {
	const [anchorEl, setAnchorEl] = useState({
		status: null,
		date: null
	});
	const [open, setOpen] = useState({
		status: false,
		date: false
	});

	const store = useContext(Context);
	const { orders, statuses, dateRanges, selectStatus, selectDate, setSelectStatus, setSelectDate, dateValues, setDateValues } = store.rootStore.ordersSeller;

	// const [statusDate, setStatusDate] = useState({
	// 	status: 1,
	// 	date: dateRanges[0].id
	// });

	const [openDialogCustomRange, setOpenDialogCustomRange] = useState(false);
	// const [dateValues, setDateValues] = useState([dateRanges[0].value]);

	// for table
	// const [selectedOrder, setSelectedOrder] = useState(null);

	const [openFunctions, setOpenFunctions] = useState(false);
	const [anchorElFunctions, setAnchorElFunctions] = useState(null);
	// const buttonRefFunctions = useRef();

	// const [order, setOrder] = useState('asc');
  // const [orderBy, setOrderBy] = useState('calories');
  // const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  // const [dense, setDense] = useState(false);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleToggle = (event) => {
		const typeButton = event.currentTarget.dataset.typebutton;
    setAnchorEl({
			...anchorEl,
			[typeButton]: event.currentTarget
		});
		setOpen({
			...open,
			[typeButton]: (prev) => !prev[typeButton]
		});
  };

	const handleClose = (type) => {
    setOpen({
			...open,
			[type]: false
		});
  };

	const handleChange = (id, type) => {
		// setStatusDate({
		// 	...statusDate,
		// 	[type]: id
		// });
		setOpen({
			...open,
			[type]: false
		});
		if (type === 'date') {
			setDateValues([dateRanges.find(datess => datess.id === id).value]);
			setSelectDate(id)
		}
		if (type === 'status') {
			setSelectStatus(id);
		}
		if (id === 6) {
			setOpenDialogCustomRange(true);
		}
	}

  const handleCloseDialogCustomRange = () => {
    setOpenDialogCustomRange(false);
  };

	const imageBodyTemplate = (rowData) => {
		let returnImages = '';
		returnImages = rowData.products.map((prod, key) => {
			return <img src={`${prod}`} alt={`prod item ${key}`} key={key} className="p-datatable-tbody-image" />
		});
		return returnImages;
	};

	const showOrderNumber = ({ orderNumber }) => {
		return <Link to={`/seller/orders/${orderNumber}`}>{numberWithSpaces(orderNumber)}</Link>;
	}

	// const showProductPrice = ({ price }) => {
	// 	return `${price} ₴`;
	// }

	const showStatusName = ({ status }) => {
		return statuses.find(statuss => Number(statuss.id) === Number(status)).caption;
	}

	const template1 = {
		layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink',
		PrevPageLink: (options) => {
				return (
					<button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
						<KeyboardDoubleArrowLeftRoundedIcon />
						<span className="p-3">Назад</span>
					</button>
				);
		},
		NextPageLink: (options) => {
			return (
				<button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
					<span className="p-3">Далі</span>
					<KeyboardDoubleArrowRightRoundedIcon />
				</button>
			);
		}
	};

	const handleClickFunctions = (event, rowData) => {
    setAnchorElFunctions(event.currentTarget);
    // setAnchorElFunctions(true);
    setOpenFunctions((previousOpen) => !previousOpen);
		console.log('event > ', event);
  }

	// const canBeOpen = open && Boolean(anchorElFunctions);
  // const idFunctions = canBeOpen ? 'spring-popper' : undefined;

	const actionBodyTemplate = (rowData) => {
		return (
			<Box sx={{ position: 'relative' }}>
				<IconButton
					// id={rowData.id}
					aria-controls={openFunctions ? 'fade-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={openFunctions ? 'true' : undefined}
					aria-describedby={rowData.id}
					disableRipple
					onClick={(e) => handleClickFunctions(e, rowData)}
					// ref={buttonRefFunctions}
				>
					<MoreHorizRoundedIcon />
				</IconButton>
				<Popper
					// id={rowData.id}
					open={openFunctions}
					anchorEl={anchorElFunctions}
					// anchorEl={buttonRefFunctions.current}
					role={undefined}
					placement='bottom-end'
					transition
					disablePortal
					sx={{ width: '100%', zIndex: 2, minWidth: 250 }}
				>
					{({ TransitionProps }) => (
						<Fade {...TransitionProps} timeout={350} in={openFunctions}>
							<Paper>
								<ClickAwayListener onClickAway={() => setOpenFunctions(false)}>
									<Box className='orders-functions'>
										<Box className='accept-order'>
											<CheckCircleOutlineRoundedIcon />
											<Box sx={{ pl: 1 }}>Прийняти замовлення</Box>
										</Box>
										<Box className='cancel-order'>
											<HighlightOffRoundedIcon />
											<Box sx={{ pl: 1 }}>Відмінити замовлення</Box>
										</Box>
									</Box>
								</ClickAwayListener>
							</Paper>
						</Fade>
					)}
				</Popper>
			</Box>
		);
	};

	return (
		<Stack className={styles['orders']}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Box className={styles['orders-title']}>Замовлення</Box>
				<Box className={styles['orders-status-date']}>
					<Box className={styles['orders-status-date__item']}>
						<Button
							sx={{ width: '100%', justifyContent: 'space-between' }}
							id="fade-button"
							aria-controls={open.status ? 'fade-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open.status ? 'true' : undefined}
							onClick={handleToggle}
							endIcon={open.status ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
							startIcon={<FilterAltOutlinedIcon />}
							disableRipple
							className='button-blue__light'
							variant="contained" disableElevation
							data-typebutton="status"
						>
							{statuses.filter(statuss => statuss.id === selectStatus)[0].caption}
						</Button>
						<Popper
							open={open.status}
							anchorEl={anchorEl.status}
							role={undefined}
							placement='bottom-end'
							transition
							disablePortal
							sx={{ width: '100%', zIndex: 2 }}
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350} in={open.status}>
									<Paper className={styles['popup-menu']}>
										<ClickAwayListener onClickAway={() => handleClose('status')}>
											<Box sx={{ display: 'flex', flexDirection: 'column' }}>
												{
													statuses.map(statuss => (
														<MenuItem
															key={statuss.id}
															disableRipple
															className={clsx(styles['popup-menu__item'], (statuss.id === selectStatus ? styles['is-active'] : ''))}
															onClick={() => handleChange(statuss.id, 'status')}
														>
															{statuss.caption}
														</MenuItem>
													))
												}
											</Box>
										</ClickAwayListener>
									</Paper>
								</Fade>
							)}
						</Popper>
					</Box>
					<Box className={styles['orders-status-date__item']}>
						<Button
							sx={{ width: '100%', justifyContent: 'space-between' }}
							id="fade-button"
							aria-controls={open.date ? 'fade-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open.date ? 'true' : undefined}
							onClick={handleToggle}
							endIcon={open.date ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
							startIcon={<CalendarTodayOutlinedIcon />}
							disableRipple
							className='button-blue__light'
							variant="contained" disableElevation
							data-typebutton="date"
						>
							{
								Number(selectDate) === 6 ? (format(dateValues[0].startDate, 'dd.MM.yyyy') + ' - ' + format(dateValues[0].endDate, 'dd.MM.yyyy')) : (
									dateRanges.find(datess => datess.id === selectDate).caption
								)
							}
						</Button>
						<Popper
							open={open.date}
							anchorEl={anchorEl.date}
							role={undefined}
							placement='bottom-end'
							transition
							disablePortal
							sx={{ width: '100%', zIndex: 2 }}
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350} in={open.date}>
									<Paper className={styles['popup-menu']}>
										<ClickAwayListener onClickAway={() => handleClose('date')}>
											<Box sx={{ display: 'flex', flexDirection: 'column' }}>
											{
												dateRanges.map(datess => (
													<MenuItem
														key={datess.id}
														disableRipple
														className={clsx(styles['popup-menu__item'], (datess.id === selectDate ? styles['is-active'] : ''))}
														onClick={() => handleChange(datess.id, 'date')}
													>
														{datess.caption}
													</MenuItem>
												))
											}
											{/* <MenuItem
												disableRipple
												className={clsx(styles['popup-menu__item'], (customDateRange.id === statusDate.date ? styles['is-active'] : ''))}
												onClick={handleOpenDialogCustomRange}
											>
												{customDateRange.caption}
											</MenuItem> */}
											{/* <DateRange
												editableDateInputs={true}
												onChange={item => setCustomDate([item.selection])}
												moveRangeOnFirstSelection={false}
												ranges={customDate}
												locale={uk}
												dateDisplayFormat='dd.MM.yyyy'
											/> */}

												{/* <LocalizationProvider
													dateAdapter={AdapterDayjs}
													localeText={ukUA.components.MuiLocalizationProvider.defaultProps.localeText}
													adapterLocale="uk"
												>
													<DemoContainer
														components={[
															'DatePicker',
															'MobileDatePicker',
															'DesktopDatePicker',
															'StaticDatePicker',
															'MobileDateRangePicker'
														]}
													>
														<DemoItem label="Mobile variant" component="MobileDateRangePicker">
															<MobileDateRangePicker
																defaultValue={[dayjs('2022-04-17'), dayjs('2022-04-21')]}
															/>
														</DemoItem>
													</DemoContainer>
												</LocalizationProvider> */}

											</Box>
										</ClickAwayListener>
									</Paper>
								</Fade>
							)}
						</Popper>
						<Dialog
							open={openDialogCustomRange}
							onClose={handleCloseDialogCustomRange}
							aria-labelledby="responsive-dialog-title"
						>
							{/* <DialogTitle id="responsive-dialog-title">
								{"Use Google's location service?"}
							</DialogTitle> */}
							<DialogContent>
								<DateRange
									editableDateInputs={true}
									onChange={item => setDateValues([item.selection])}
									moveRangeOnFirstSelection={false}
									ranges={dateValues}
									locale={uk}
									dateDisplayFormat='dd.MM.yyyy'
								/>
							</DialogContent>
							<DialogActions>
								<Button className='button button-blue' onClick={handleCloseDialogCustomRange}>OK</Button>
							</DialogActions>
						</Dialog>
					</Box>
				</Box>
			</Box>
			<Box className={styles['orders-table']}>
				<DataTable
					value={orders}
					paginator
					rows={5}
					className='table-seller'
					paginatorTemplate={template1}
					currentPageReportTemplate="Відображено {last} із {totalRecords}"
				>
					<Column field="orderNumber" body={showOrderNumber} header="Номер замовлення" align='center' sortable></Column>
					<Column field="orderDate" header="Дата замовлення" align='center' sortable></Column>
					<Column field="products" header='Товари' body={imageBodyTemplate} align='center'></Column>
					<Column field="price" body={showProductPrice} header="Сума" align='center' sortable></Column>
					<Column field="fullName" header="ПІП" align='center' sortable></Column>
					<Column field="phone" header="Телефон" align='center' sortable></Column>
					<Column field="status" body={showStatusName} header="Статус" align='center' sortable></Column>
					<Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
				</DataTable>
			</Box>
		</Stack>
	)
}

export default OrdersBlock;
