import React from 'react';
import { Stack, Box, Grid, IconButton, Button } from '@mui/material';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';

import { numberWithSpaces, showProductPrice } from '../../../../utils/functions';

import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

import styles from './OrderItemBlock.module.scss';
import './OrderItemTable.scss';

// const avatarRecipient = '../../assets/images/avatar-profile.png';
// const userAvatar = '../assets/images/avatar-profile.png';
const userNoAvatar = '../../assets/images/no-avatar.png';

const recipientInfo = {
	image: '../../assets/images/avatar-profile.png',
	fullName: 'Баланюк Ксенія Павлівна',
	phone: '380688031838',
	email: 'kseniabalanyk@gmail.com',
	ttn: 'TTH 20450822763826',
	deliveryService: 'Самовивіз з Нової Пошти',
	deliveryAddress: 'Чернівці, 13, вул. Буковинська, б.25'
}

const orderItems = [
	{
		id: 1,
		image: '../../assets/images/product-card/prod_front1.jpg',
		caption: 'Джинсова куртка від бренду NAkD',
		options: [
			{
				id: 1,
				caption: 'Розмір',
				value: 'M'
			},
			{
				id: 2,
				caption: 'Колір',
				value: 'Синій'
			}
		],
		quantity: 2,
		price: 999
	},
	{
		id: 1,
		image: '../../assets/images/product-card/prod_front2.jpg',
		caption: 'В\'язана гачком сукня Boohoo Boho з широкими рукавами',
		options: [
			{
				id: 1,
				caption: 'Розмір',
				value: 'S'
			},
			{
				id: 2,
				caption: 'Колір',
				value: 'Білий'
			}
		],
		quantity: 1,
		price: 1999
	}
];

const historyOrder = [
	{
		id: 1,
		caption: 'Доставка замовлення',
		date: 1693313040000
	},
	{
		id: 2,
		caption: 'Доставка замовлення',
		date: Date.now()
	},
	{
		id: 3,
		caption: 'Замовлення опрацьоване',
		date: Date.now()
	},
	{
		id: 4,
		caption: 'Створення замовлення',
		date: Date.now()
	}
];

const OrderItemBlock = () => {
	const { id } = useParams();

	const showProductTotalPrice = ({ price, quantity }) => {
		const totalPrice = price * quantity;
		return (
			<>
				<strong>{totalPrice}</strong> ₴
			</>
		);
	}

	const productBodyTemplate = (rowData) => {
		return (
			<Box className={styles['product-template']}>
				<Box className={styles['product-template__image']}>
					<img src={`${rowData.image}`} alt={`product item ${rowData.id}`} />
				</Box>
				<Box className={styles['product-template__info']}>
					<Box>{rowData.caption}</Box>
					<Box sx={{ mt: 4 }}>
						{
							rowData.options.map(option => (
								<Box key={option.id} sx={{ display: 'flex' }}>
									<Box>{option.caption}:</Box>
									<Box sx={{ fontWeight: 600, ml: 2 }}>{option.value}</Box>
								</Box>
							))
						}
					</Box>
				</Box>
			</Box>
		);
	};

	const deleteOrderItem = (rowData) => {
		return <IconButton className={styles['product-template__delete']} disableRipple><HighlightOffRoundedIcon /></IconButton>;
	}

	return (
		<Stack className={styles['order-item']}>
			<Box className={styles['order-item__title']}>Замовлення №{numberWithSpaces(id)}</Box>
			<Stack spacing={2} direction='column' sx={{ mt: 2 }}>
				<Grid container>
					<Grid item xxs={12} def={3} sx={{ pr: { def: 1 } }}>
						<Box className={clsx(styles['order-item__recipient'], styles['order-item__paper'])}>
							<Box className={styles['recipient-image']}>
								<img src={recipientInfo.image ? recipientInfo.image : userNoAvatar} alt='avatar recipient' />
							</Box>
							<Box className={styles['recipient-info']}>
								<Box className={styles['recipient-info__title']}>Отримувач:</Box>
								<Box>{recipientInfo.fullName}</Box>
								<Box>{recipientInfo.phone}</Box>
								<Box>{recipientInfo.email}</Box>
								<Box className={styles['recipient-info__title']}>Доставка:</Box>
								<Box>{recipientInfo.ttn}</Box>
								<Box>{recipientInfo.deliveryService}</Box>
								<Box>{recipientInfo.deliveryAddress}</Box>
							</Box>
						</Box>
					</Grid>
					<Grid item xxs={12} def={9} sx={{ pl: { def: 1 } }}>
						<Box className={clsx(styles['order-item__products'], styles['order-item__paper'])}>
							<DataTable
								value={orderItems}
								className='table-seller__item'
								// paginator
								// rows={5}
								// className={styles['orders-table']}
								// paginatorTemplate={template1}
								// currentPageReportTemplate="Відображено {last} із {totalRecords}"
							>
								<Column body={productBodyTemplate} header="Товари"></Column>
								<Column field="quantity" header="Кількість" align='center'></Column>
								<Column field="price" body={showProductPrice} header="Ціна" align='center'></Column>
								<Column field="totalPrice" body={showProductTotalPrice} header="Загальна ціна" align='center'></Column>
								<Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={deleteOrderItem} />
							</DataTable>
						</Box>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xxs={12} def={3} sx={{ pr: { def: 1 } }}>
						<Box elevation={0} className={clsx(styles['order-item__history'], styles['order-item__paper'])}>
							<Box className={styles['history-title']}>Історія замовлення</Box>

							{/* <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', position: 'relative' }}>
								{
									historyOrder.map(histOrd => (

									))
								}
							</Box> */}
							<Box className={styles['history-body']}>
								{
									historyOrder.map(histOrd => (
										<Box key={histOrd.id} className={styles['history-body__item']}>
											<Box className={styles['delivery-dot']} key={histOrd.id}></Box>
											<Box>
												<Box className={styles['history-caption']}>{histOrd.caption}</Box>
												<Box>{format(histOrd.date, 'EEEEEE, dd MMMM yyyy, HH:mm', { locale: uk })}</Box>
											</Box>
										</Box>

									))
								}
							</Box>

						</Box>
					</Grid>
					<Grid item xxs={12} def={9} sx={{ pl: { def: 1 } }}>
						<Box elevation={0} className={clsx(styles['order-item__review'], styles['order-item__paper'])}>
							<Box><Button className='button button-blue' disableRipple>Написати клієнту</Button></Box>
							<Box><Button className='button button-blue' sx={{ ml: 2 }} disableRipple>Залишити відгук</Button></Box>
						</Box>
					</Grid>
				</Grid>
			</Stack>
		</Stack>
	)
}

export default OrderItemBlock;
