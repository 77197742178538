import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Stack, FormControl, OutlinedInput, InputAdornment, Button, Grid, Badge, IconButton, Typography, Avatar, Icon } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
// import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
// import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import clsx from 'clsx';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isDesktop, isMobile } from 'react-device-detect';

import MessageInfo from './MessageInfo';

import withAuth from '../../../hoc/withAuth';
import styles from './MessageBlock.module.scss';


const badgeStyleCabinet = {
  "& .MuiBadge-badge": {
    backgroundColor: '#9e9e9e',
    color: '#ffffff',
		top: '5px',
    right: '5px'
  }
}

const badgeStyleConnectionType = {
  "& .MuiBadge-badge": {
    backgroundColor: '#87ba65',
    color: '#ffffff',
		bottom: '8px',
    right: '8px'
  }
}

const messagesItems = [
	{
		id: 1,
		authorName: 'Cindy Evalina Putri',
		lastMessage: 'Ayo aja aku ikut, besok emang mau kemana sayang?',
		time: '10:58',
		avatar: '../assets/images/avatar-profile.png',
		typeMessage: 'delivered',
		connectionType: 'online',
		countNewMessages: 0,
		messages: [
			{
				id: 1,
				text: 'Sayang, besok kamu ada acara keluar ga?',
				from: 'me',
				time: '10:56'
			},
			{
				id: 2,
				text: 'Ngga ada acara buat besok, paling rebahan di rumah',
				from: 'client',
				time: '10:56'
			},
			{
				id: 3,
				text: 'Kalo gitu, besok temenin aku jalan yuk?',
				from: 'me',
				time: '10:57'
			},
			{
				id: 4,
				text: 'Ayo aja aku ikut, besok emang mau kemana sayang?',
				from: 'client',
				time: '10:58'
			}
		]
	},
	{
		id: 2,
		authorName: 'Ricko Ferdinand Malik',
		lastMessage: 'Siapp nuhun pisan brader',
		time: '10:48',
		avatar: '../assets/images/avatar-profile.png',
		connectionType: 'offline',
		typeMessage: 'delivered_read',
		countNewMessages: 0,
		messages: []
	},
	{
		id: 3,
		authorName: 'Muhammad Aliyudin Muhaimin',
		lastMessage: 'Tungguan brader saya otw kadinya ayena oge',
		time: '10:32',
		avatar: '../assets/images/avatar-profile.png',
		connectionType: 'online',
		countNewMessages: 1,
		typeMessage: 'delivered_not_read',
		messages: []
	},
	{
		id: 4,
		authorName: 'Matt Alexander',
		lastMessage: 'Yes, I will go to your home now. See you in your home',
		time: '14:56',
		avatar: '../assets/images/avatar-profile.png',
		connectionType: 'online',
		countNewMessages: 2,
		typeMessage: 'delivered_not_read',
		messages: []
	}
];

const MessageBlock = () => {
	const [activeMessageId, setActiveMessageId] = useState(null);
	const [activeMessageIndex, setActiveMessageIndex] = useState(null);
	const [showMessageContent, setShowMessageContent] = useState(false);
	// const [activeMessageObj, setActiveMessageObj] = useState(messagesItems.filter(item => ))
	// const [openMessages, setOpenMessages] = useState(false);
	// const handleOpenMessages = () => {
	// 	setOpenMessages(!openMessages);
	// }

	const changeActiveMessage = (id) => {
		setActiveMessageId(id);
		setActiveMessageIndex(messagesItems.findIndex(item => Number(item.id) === id));
		setShowMessageContent(true);
	}

	return (
		<Stack spacing={4} className={styles.message}>
			<Stack direction="row" justifyContent="space-between" alignItems='center'>
				<Grid container>
					<Grid item xxs={10} md={6}>
						<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
							<form className={styles['message-search-form']}>
								<FormControl variant="standard" sx={{ width: '100%' }}>
									<OutlinedInput
										id="input-with-icon-adornment"
										className={clsx('form-input__text', styles['form-input__text-search'])}
										placeholder='Пошук...'
										startAdornment={
											<InputAdornment position="start">
												<SearchOutlinedIcon />
											</InputAdornment>
										}
									/>
								</FormControl>
							</form>
							{ /* <Button className={clsx('button button-blue', styles['button-new-message'])} variant='contained' disableRipple>
								<AddOutlinedIcon />
							</Button> */ }
						</Box>
					</Grid>
					<Grid item xxs={2} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<IconButton className={styles['button-notification']} disableRipple>
							<Badge variant="dot" sx={badgeStyleCabinet} invisible={false}>
								<NotificationsNoneRoundedIcon />
							</Badge>
						</IconButton>
					</Grid>
				</Grid>
			</Stack>
			{
				messagesItems.length ? (
					<Stack className={styles['message-items']}>
						{
							showMessageContent && isMobile && (
								<Box onClick={() => setShowMessageContent(false)}>
									<IconButton disableRipple><ArrowBackIcon /></IconButton> назад
								</Box>
							)
						}
						<Grid container>
							{
								((!showMessageContent && isMobile) || isDesktop) && (
									<Grid item xxs={12} def={6} className={styles['all-messages']}>
										{ /* <Box className={styles['all-messages__header']}>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography>Усі повідомлення</Typography>
												<IconButton disableRipple onClick={() => handleOpenMessages()}>
													{!openMessages ? <KeyboardArrowDownOutlinedIcon /> : <KeyboardArrowUpOutlinedIcon />}
												</IconButton>
											</Box>
											<IconButton disableRipple>
												<MoreVertOutlinedIcon />
											</IconButton>
										</Box> */ }
										<Box className={styles['all-messages__items']}>
											{/* <Collapse in={openMessages}> */}
											{
												messagesItems.map(message => (
													<Box className={clsx(styles['message-item'], (Number(activeMessageId) === Number(message.id) ? styles['is-active'] : ''))} key={message.id} onClick={() => changeActiveMessage(message.id)}>
														<Box sx={{ display: 'flex', alignItems: 'center' }}>
															<Badge color="secondary" sx={badgeStyleConnectionType} variant="dot" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} invisible={message.connectionType === 'online' ? false : true}>
																<Avatar alt="message user avatar" src={message.avatar} className={styles['message-item__avatar']} />
																{ /* <Box className={styles['message-item__avatar']}>
																	<img src={message.avatar} alt="message user avatar" />
																</Box> */ }
															</Badge>
															<Box className={styles['message-item__description']}>
																<Box className={styles['author-name']}>{message.authorName}</Box>
																<Box className={styles['last-message']}>{message.lastMessage}</Box>
															</Box>
														</Box>
														<Box className={styles['message-item__time']}>
															<Box className={styles['message-time']}>{message.time}</Box>
															{
																message.countNewMessages > 0 ? (
																	<Box className={styles['count-messages']}>{message.countNewMessages}</Box>
																) : (
																	message.typeMessage === 'delivered' ? (
																		<Icon className={styles['type-message']}><CheckIcon /></Icon>
																	) : (
																		message.typeMessage === 'delivered-not-read' ? (
																			<Icon className={styles['type-message']}><DoneAllIcon /></Icon>
																		) : (
																			<Icon className={clsx(styles['type-message'], styles['is-active'])}><DoneAllIcon /></Icon>
																		)
																	)
																)
															}
														</Box>
													</Box>
												))
											}
											{/* </Collapse> */}
										</Box>
									</Grid>
								)
							}

							{/* <Divider orientation="vertical" flexItem /> */}
							{
								((showMessageContent && isMobile) || isDesktop) && (
									<Grid item xxs={12} def={6} className={styles['message-content']}>
									{
										(activeMessageId !== null && messagesItems[activeMessageIndex].messages.length > 0) ? (
											<MessageInfo activeUserMessages={messagesItems[activeMessageIndex]} />
										) : (
											<Typography sx={{ p: 2 }}>Повідомлень немає</Typography>
										)
									}
									</Grid>
								)
							}

						</Grid>
					</Stack>
				) : (
					<Typography>У Вас поки що немає повідомлень.</Typography>
				)
			}

		</Stack>
	);
}

export default withAuth(observer(MessageBlock));
