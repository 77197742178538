import MessageBlock from '../../../../components/admin/shopper/message-block';
import LayoutSeller from '../../../../components/admin/layout';

const Message = () => {
  return (
    <LayoutSeller typeInfo='messages'>
      <MessageBlock />
    </LayoutSeller>
  )
}

export default Message;
