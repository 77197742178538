import { makeAutoObservable } from "mobx";
import axios from "axios";

class Categories {

	constructor () {
		makeAutoObservable(this);
		// this.fetchCategories();
	}

	categories = [];
	isLoading = true;
	mainCategoriesCaption = [];
	error = '';

	setCategories (categories) {
		this.categories = categories;
	}

	setIsLoading (isLoading) {
		this.isLoading = isLoading;
	}

	setMainCategoriesCaption = (categories) => {
		this.mainCategoriesCaption = categories;
	}

	// setMainCategoriesCaption (mainCategories) {
	// 	this.mainCategoriesCaption = mainCategories;
	// }

	async fetchCategories () {
		try {
			const typeResponse = 'asTree';
			const { data } = await axios.post('https://zefir.store/php/catalog/getCategories.php', { typeResponse });
			this.setCategories(data);
			if (data.length > 0) {
				// console.log('data categ >', data);
				const categoriesCaption = data.map(item => {
					delete item.childs;
					return item;
				});
				this.setMainCategoriesCaption(categoriesCaption);
			}
			this.setIsLoading(false);
		} catch (e) {
			console.error('Помилка отримання категорій', e);
			this.error = 'Помилка';
		} finally {
			this.setIsLoading(false);
		}
	}
}

export default Categories;
