import { Box, Pagination } from "@mui/material"

import './PaginationSeller.scss';

const PaginationSeller = (props) => {
	return (
		<Box className="pagination-seller">
			<Pagination {...props} />
		</Box>
	)
}

export default PaginationSeller
