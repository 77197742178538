import { useRef } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography, IconButton, Button } from '@mui/material'
import Slider from 'react-slick';
import clsx from 'clsx';

import ProductCard from "../product-card"

import './SliderOfProducts.scss';

const SliderOfProducts = ({ title, productsInfo, customSliderSettings = {}, showButtomMore = true }) => {
	let sliderProducts = useRef(null);

	const setSliderProductsRef = (currSlider) => {
		sliderProducts = currSlider;
	}

	const previousSliderProducts = () => {
		sliderProducts.slickPrev();
	}

	const nextSliderProducts = () => {
		sliderProducts.slickNext();
	}

	const additionalSliderSettings = {
		rows: 2,
		slidesPerRow: 5,
		centerPadding: '60px',
		responsive: [{
			breakpoint: 1280,
			settings: {
				slidesPerRow: 3
			}
		}]
	};

	const sliderSettings = Object.keys(customSliderSettings).length > 0 ? customSliderSettings : additionalSliderSettings;

	const defaultSliderSettings = {
		className: 'center',
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		ref: setSliderProductsRef,
		arrows: false
	};

	const finalSliderSettings = Object.assign(defaultSliderSettings, sliderSettings);

  return (
    <Box className='slider-products'>
			<Box className='slider-products__title'>
				<Typography className='slider-products__title-title'>{title}</Typography>
				<Box className='slider-products__buttons'>
					<IconButton onClick={previousSliderProducts} className={clsx('slider-button', 'slider-button__prev')} disableRipple>
						<KeyboardArrowLeftIcon fontSize='small' />
					</IconButton>
					<IconButton onClick={nextSliderProducts} className={clsx('slider-button', 'slider-button__next')} disableRipple>
						<KeyboardArrowRightIcon fontSize='small' />
					</IconButton>
				</Box>
			</Box>

			<Box className='slider-products__slider'>
				<Slider {...finalSliderSettings}>
					{
						productsInfo.map((prod) => (
							<div key={prod.id}><ProductCard productInfo={prod} /></div>
						))
					}
					{/* це нижче "map" тимчасово */}
					{
						productsInfo.map((prod) => (
							<div key={prod.id}><ProductCard productInfo={prod} /></div>
						))
					}
				</Slider>
			</Box>
			{
			showButtomMore ?? (
				<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
					<Button disableRipple className='slider-products__show-more' variant="contained">Більше</Button>
				</Box>
				)
			}

    </Box>
  )
}

export default SliderOfProducts
