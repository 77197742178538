import { makeAutoObservable } from "mobx";
import axios from "axios";
import { toJS } from "mobx";
import Lang from "./lang";
// import _ from 'lodash';

class AddProduct {
	constructor () {
		makeAutoObservable(this);
	}

	treeList = [];
	hierarchyTree = [];
	categories = [];
	childCategories = [];
	brands = [];
	conditions = [];
	sizes = [];
	materials = [];
	allColors = [];
	allSeasons = [];
	allMaterials = [];
	errorFileMessage = '';
	searchInputBrand = '';
	expandedCategory = null;
	categoryLevel = 0;
	tempCategories = [];

	// childrenElementsSection = [];
	// childrenElementsCategory = [];

	formData = {
		caption: '',
		description: '',
		price: '',
		count: '',
		categoryValue: '',
		condition: '',
		brand: '',
		// size_by_age: '',
		// size_by_pair_1: '',
		// size_by_pair_2: '',
		// size_by_head: '',
		// size_by_foot: '',
		// size_child: '',
		// size_bra: '',
		selectSizes: [],
		size: '',
		breast: '',
		waist: '',
		thigh: '',
		foot: '',
		colors: [],
		material: '',
		print: '',
		seasons: [],
		files: []
	}

	// caption = '';
	// description = '';
	// price = '';
	// count = '';
	// condition = '';
	// brand = '';
	// size = '';
	// measures = {
	// 	breast: '',
	// 	waist: '',
	// 	thigh: '',
	// 	foot: ''
	// };
	// color = '';
	// material = '';
	// print = '';
	// season = '';

	// setImage = (index, image) => {
	// 	console.log('index >', index);
	// 	console.log('image >', image);
	// 	const newItem = { [index]: image };
	// 	this.formData.images = { ...this.formData.images, ...newItem };
	// 	console.log(toJS(this.formData.images));
	// }

	setSelectSizes = (sizeName, sizeID) => {
		const sizeObj = { sizeName, sizeID };
		const currentSelectSizes = [...this.formData.selectSizes];

		let newArr = [];
		if (currentSelectSizes.length) {
			for (let i in currentSelectSizes) {
				if (currentSelectSizes[i].sizeName == sizeName) {
					currentSelectSizes[i].sizeID = sizeID;
					newArr = currentSelectSizes;
				} else {
					newArr = [...currentSelectSizes, sizeObj];
				}
			}
		} else {
			newArr = [sizeObj];
		}

		this.formData.selectSizes = newArr;
	}

	fileChange = (selectedFiles) => {
		const totalFiles = [...this.formData.files, ...selectedFiles];
    // Обмеження на кількість файлів
    if (totalFiles.length > 6) {
      // alert('Можна вибрати не більше шести файлів.');
			this.setErrorFileMessage('Можна додати не більше шести файлів.');
      return;
    }

    const filteredFiles = totalFiles.filter(file => {
      const isImage = file.type.startsWith('image/');
      const isVideo = file.type.startsWith('video/');
      return isImage || isVideo;
    });

    // Обмеження на кількість фотографій
    if (filteredFiles.filter(file => file.type.startsWith('image/')).length > 5) {
			this.setErrorFileMessage('Можна додати не більше п\'яти фотографій.');
			return;
    }

		// Обмеження на кількість відео
    if (filteredFiles.filter(file => file.type.startsWith('video/')).length > 1) {
			this.setErrorFileMessage('Можна додати тільки одне відео.');
      return;
    }

    this.setFiles(filteredFiles);
		this.setErrorFileMessage('');
  };

	setFiles = (files) => {
		this.formData.files = files;
	}

	deleteFile = (index) => {
		const updatedFiles = [...this.formData.files];
    updatedFiles.splice(index, 1);
    this.setFiles(updatedFiles);
	}

	deleteAllFiles = () => {
		this.setFiles([]);
	}

	deleteImage = (index) => {
		// this.formData.images.filter(item => item.index !== index);
		delete this.formData.images[index];
		console.log(toJS(this.formData.images));
	}

	setErrorFileMessage = (message) => {
		this.errorFileMessage = message;
	}

	setTreeList (treeList) {
		this.treeList = treeList;
	}

	setCategories = (categories) => {
		this.categories = categories;
	}

	setChildCategories = (childCategories) => {
		this.childCategories = childCategories;
	}

	setConditions = (conditions) => {
		this.conditions = conditions;
	}

	setBrands = (brands) => {
		this.brands = brands;
	}

	setSearchInputBrand = (searchInputBrand) => {
		this.searchInputBrand = searchInputBrand;
	}

	setCategoryLevel = (categoryLevel) => {
		this.categoryLevel = categoryLevel;
	}

	setArrayOfSizes = (arrSizes) => {
		this.sizes = arrSizes;
	}

	setAllColors = (colors) => {
		this.allColors = colors;
	}

	setAllSeasons = (seasons) => {
		this.allSeasons = seasons;
	}

	setAllMaterials = (materials) => {
		this.allMaterials = materials;
	}

	setSelectChildCategories = async (categId, level) => {
		const selectedCategorie = this.categories.find((category) => Number(category.id) === Number(categId)).childs;
		this.setChildCategories(selectedCategorie);
		const arrSizes = [];
		if (selectedCategorie.length) {
			this.setCategories(selectedCategorie);
			this.setCategoryValue('');
			this.setCategoryLevel(level + 1);
			this.setTempCategories(selectedCategorie);
		} else {
			this.setCategoryValue(categId);
			// this.setCategoryLevel(level);
			if (this.tempCategories[level + 1]) {
				const newArr = this.tempCategories.filter((_, index) => index !== (level + 1));
				this.setTempCategories(newArr);
			}
			const lang = new Lang();
			const language = lang.language;
			try {
				const categoryID = this.formData.categoryValue;
				const { data } = await axios.post('https://zefir.store/php/catalog/getAttributes.php', { categoryID, language });

				if (Object.keys(data).length) {
					for (const key in data) {
						if (data[key].items?.length > 0) {
							if (data[key].attributeType === 'brand') {
								this.setBrands(data[key].items);
							}
							if (data[key].attributeType === 'condition') {
								this.setConditions(data[key].items);
							}
							// if (data[key].attributeType === 'size' && data[key].systemName !== 'size_child') {
							if (data[key].attributeType === 'size') {
								arrSizes.push(data[key]);
								this.setArrayOfSizes(arrSizes);
							}
							if (data[key].attributeType === 'color' && data[key].items.length) {
								this.setAllColors(data[key].items);
							}
							if (data[key].attributeType === 'season' && data[key].items.length) {
								this.setAllSeasons(data[key].items);
							}
							if (data[key].attributeType === 'material' && data[key].items.length) {
								this.setAllMaterials(data[key].items);
							}
						}
					}
				}
			} catch (e) {
				console.error('Дані не отримано :(', e);
			}
		}
	}

	setHierarchyTree (hierarchyTree) {
		this.hierarchyTree = hierarchyTree;
	}

	setCustomValue = (name, value) => {
		this.formData[name] = value;
	}

	setCategoryValue = (value) => {
		this.formData.categoryValue = value;
	}

	// setCategoriesValues = (name, value) => {
	// 	if (name === 'section') {
	// 		this.formData.categoriesValues = {
	// 			section: value,
	// 			category: '',
	// 			subCategory: ''
	// 		}
	// 	} else if (name === 'category') {
	// 		this.formData.categoriesValues = {
	// 			section: this.formData.categoriesValues.section,
	// 			category: value,
	// 			subCategory: ''
	// 		}
	// 	} else if (name === 'subCategory') {
	// 		this.formData.categoriesValues = {
	// 			section: this.formData.categoriesValues.section,
	// 			category: this.formData.categoriesValues.category,
	// 			subCategory: value
	// 		}
	// 	}

	// 	// this.setExpandedCategory(value);

	// 	// this.expandedCategory = value;
	// 	// autorun(() => {
	// 	// 	// this.expandedCategory = value;
	// 	// 	// const newExpand =
	// 	// 	this.setExpandedCategory(value);
	// 	// });

	// 	// this.formData.categoriesValues = {
	// 	// 	section: '',
	// 	// 	category: '',
	// 	// 	subCategory: ''
	// 	// }
	// 	// this.formData.categoriesValues[`${name}`] = value;
	// }

	// setChildrenElementsSection () {
	// 	if (typeof this.hierarchyTree.filter(categ => categ.id === this.formData.categoriesValues.section).children !== 'undefined') {
	// 		this.childrenElementsSection = this.hierarchyTree.filter(categ => categ.id === this.formData.categoriesValues.section).children
	// 	}
	// }

	// setChildrenElementsCategory () {
	// 	if (typeof this.hierarchyTree.filter(categ => categ.id === this.formData.categoriesValues.category).children !== 'undefined') {
	// 		this.childrenElementsCategory = this.hierarchyTree.filter(categ => categ.id === this.formData.categoriesValues.category).children
	// 	}
	// }

	setExpandedCategory = (expandedCategory) => {
		this.expandedCategory = expandedCategory;
		console.log('childs categ >>>', toJS(this.categories.find((category) => Number(category.id) === Number(expandedCategory))));
		console.log('expanded category >', expandedCategory);
	}

	// get getExpandedCategory () {
	// 	return this.expandedCategory;
	// }

	selectSection = (id) => {
		if (this.treeList.length > 0) {
			const childCategs = this.treeList.filter(tree => Number(tree.parentId) === Number(id));
			this.setChildCategories(childCategs);
			// console.log('this.childCategories >', toJS(this.childCategories));
			// console.log('this.treeList.parentId >', this.treeList.parentId);
			// console.log('this.treeList.filter(tree) >', this.treeList.filter(tree => tree.parentId === 3));
		} else {
			console.error('Дерево категорій пусте :(');
		}
	}

	addValueForFieldName = (e) => {
		console.log('e.target', e.target);
		if (e.target.checked) {
			this.formData[`${e.target.name}`].push({ id: Number(e.target.value) });
		} else {
			const newArr = this.formData[`${e.target.name}`].filter(item => Number(item.id) !== Number(e.target.value));
			this.formData[`${e.target.name}`] = newArr;
		}
		// console.log('this.current.field >', toJS(this.formData[`${e.target.name}`]));
	}

	// flatToHierarchy = (flat) => {
	// 	let roots = []; // things without parentId

	// 	// make them accessible by id on this map
	// 	let all = {};

	// 	[...flat].forEach(function(item) {
	// 		all[item.id] = { ...item };
	// 	});

	// 	// connect childrens to its parentId, and split roots apart
	// 	Object.keys(all).forEach(function(id) {
	// 		let item = all[id];
	// 		if (item.parentId === null || item.parentId === 0) {
	// 			roots.push(item);
	// 		} else if (item.parentId in all) {
	// 			let p = all[item.parentId];
	// 			if (!('children' in p)) {
	// 				p.children = [];
	// 			}
	// 			p.children.push(item);
	// 			p.children.sort((a, b) => a.sort > b.sort ? 1 : -1);
	// 		}
	// 	});
	// 	// done!
	// 	// return roots;
	// 	this.setHierarchyTree(roots);
	// }

	setTempCategories = (categs) => {
		// this.tempCategories.push(categs);
		this.tempCategories[this.categoryLevel] = categs;
	}

	fetchFlatTree = async () => {
		try {
			const typeResponse = 'asTree';
			const { data } = await axios.post('https://zefir.store/php/catalog/getCategories.php', { typeResponse });
			console.log('data >', data);
			this.setTreeList(data);
			// const ajaxCategories = data.filter(category => category.parentId === 0);
			this.setCategories(data);
			this.setTempCategories(data);
			// console.log('categories >', toJS(this.categories));
			// this.flatToHierarchy(data);
			this.setHierarchyTree(data);
			// console.log('hierarchy tree >', toJS(this.hierarchyTree));
		} catch (e) {
			console.error('Не можливо вивести категорії :(', e);
		}
	}

	submitFormData = async () => {
		try {
			const formData = this.formData;
			const { data } = await axios.post('https://zefir.store/php/catalog/addProduct.php', { formData });
			console.log('data >>> ', data);
		} catch (e) {
			console.error('Не вдалося додати товар :(', e);
		}
	}
}

export default AddProduct;
