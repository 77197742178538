// import axios from "axios";
import $api from "../../http";

export default class AuthService {
    static async login (login, password, rememberMe) {
			return $api.post('/login.php', { login, password, rememberMe });
    }

    static async registration (login, password) {
			return $api.post('/registration.php', { login, password });
    }

    static async logout () {
			return $api.post('/logout.php');
    }

		// static async validateToken (jwt) {
		// 	return $api.post('/validate.php', { jwt });
		// }
}
