import { useState } from 'react';
import { Box, IconButton, Button } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import ImageLoader from '../../../image-loader';
import { OptionTypesBlock } from '../../../recommended-product-card';
import styles from './FavoriteReviewBlock.module.scss';

const FavoriteReviewCard = ({ productInfo }) => {
	const [isFavorite, setIsFavorite] = useState(false);

	const favClick = () => {
		setIsFavorite(!isFavorite);
	}
	return (
		<Box className={styles['product']}>
			<Box className={styles['product-description']}>
				<Box className={styles['product-title']}>{productInfo.title}</Box>
				<Box className={styles['product-options']}>
					{
						productInfo.options.map(({ id, name, type, values }) => (
							<Box className={styles['option-item']} key={id}>
								<Box className={styles['option-item__title']}>{name}</Box>
								<Box className={styles['option-item__values']}>
									{
										values.map(({ value, id, isActive }) => (
											<OptionTypesBlock key={id} value={value} optionType={type} isActive={isActive} />
										))
									}
								</Box>
							</Box>
						))
					}
				</Box>
				<Box className={styles['product-price']}>
					<Box className={styles['product-price__title']}>Ціна:</Box>
					<Box className={styles['product-price__prices']}>
						<Box className={styles['product-price__prices-old']}>{productInfo.priceOld}</Box>
						<Box className={styles['product-price__prices-new']}>{productInfo.price}</Box>
					</Box>
				</Box>
				<Box className={styles['product-buttons']}>
					<Button className={styles['product-buttons__buy']} disableRipple>Додати в кошик</Button>
					<IconButton className={styles['product-buttons__favorite']} disableRipple onClick={favClick}>
						{ isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
					</IconButton>
				</Box>
			</Box>
			<Box className={styles['product-image']}>
				<ImageLoader src={productInfo.image} alt={`product-item-${productInfo.id}`} />
				{/* <img src={productInfo.image} alt={`product-item-${productInfo.id}`} /> */}
			</Box>
		</Box>
  )
}

export default FavoriteReviewCard
