import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import jwtDecode from "jwt-decode";
import { observer } from 'mobx-react-lite';

import Main from "./pages/Main";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import NoPage from "./pages/NoPage";
import ProductPage from "./pages/ProductPage";
import MyInfo from "./pages/admin/shopper/my-info";
import DeliveryAddress from "./pages/admin/shopper/delivery-address";
import Security from "./pages/admin/shopper/security";
import MyOrders from "./pages/admin/shopper/my-orders";
import Message from "./pages/admin/shopper/message";
import FavoritesReviews from "./pages/admin/shopper/favorites-reviews";
import Feedback from "./pages/admin/shopper/feedback";
import MySizes from "./pages/admin/shopper/my-sizes";
import Subscription from "./pages/admin/shopper/subscription";
import AddProduct from "./pages/admin/seller/add-product";
import Orders from "./pages/admin/seller/orders";
import OrderItem from "./pages/admin/seller/order-item";
import Catalog from "./pages/Сatalog";

import { Context } from ".";

const App = () => {
	const store = useContext(Context);
	const { authStore } = store.rootStore;
	const location = window.location.pathname;

	useEffect(() => {
		if (location.indexOf('shopper') !== 1 && location.indexOf('seller') !== 1) {
			const fetchValidateJWT = async () => {
				await authStore.validateJWT();
			}
			fetchValidateJWT();
		}
	}, [location, authStore]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/">
            <Route index element={<Main />} />
            <Route path="about" element={<About />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="*" element={<NoPage />} />
            <Route path="product" element={<ProductPage />}></Route>
            <Route path="catalog" element={<Catalog />}></Route>
          </Route>
          <Route path="/shopper/">
            <Route path="my-info" element={<MyInfo />} />
            <Route path="delivery-address" element={<DeliveryAddress />} />
            <Route path="security" element={<Security />} />
            <Route path="orders" element={<MyOrders />} />
            <Route path="message" element={<Message />} />
            <Route path="favorites" element={<FavoritesReviews typePage='favorites' />} />
            <Route path="reviews" element={<FavoritesReviews typePage='reviews' />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="sizes" element={<MySizes />} />
            <Route path="subscription" element={<Subscription />} />
          </Route>
					<Route path="/seller">
						<Route path="add-product" element={<AddProduct />} />
						<Route path="orders" element={<Orders />} />
						<Route path="orders/:id" element={<OrderItem />} />
					</Route>
        </Routes>
      </Router>
    </>
  );
}

export default observer(App);
