import MySizesBlock from '../../../../components/admin/shopper/my-sizes-block';
import LayoutSeller from '../../../../components/admin/layout';

const MySizes = () => {
  return (
    <LayoutSeller typeInfo='client-sizes'>
      <MySizesBlock />
    </LayoutSeller>
  )
}

export default MySizes;
