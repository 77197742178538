import $api from "../../http";

export default class MyData {
	static async loadClientInfo () {
		const typeInfo = 'client-info';
		return $api.post('/clientInfo.php', { typeInfo });
	}

	static async saveMyInfo (data) {
		return $api.post('/saveClientInfo.php', data);
	}

	static async uploadClientPhoto (formData) {
		return $api.post('/uploadPhoto.php', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}

	static async deleteMySize (data) {
		return $api.post('/saveClientInfo.php', data);
	}
}
