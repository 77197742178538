import MyInfoBlock from '../../../../components/admin/shopper/my-info-block';
import LayoutSeller from '../../../../components/admin/layout';

const MyInfo = () => {
  return (
    <LayoutSeller typeInfo='client-info'>
      <MyInfoBlock />
    </LayoutSeller>
  )
}

export default MyInfo;
