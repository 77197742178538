import { makeAutoObservable } from "mobx";
import axios from "axios";

class Search {
	constructor () {
		makeAutoObservable(this);
	}

	query = '';

	setQuery (query) {
		this.query = query;
	};

	async handleSearch () {
		try {
			if (this.query.length > 2) {
				const response = await axios.get(`https://zefir.store/php/search.php?query=${this.query}`);
				console.log(response);
			}
			// localStorage.setItem('token', response.data.accessToken);
			// this.setAuth(true);
		} catch (e) {
			console.error('Помилка під час виконання пошуку', e);
		}
	}
}

export default Search;