import React from 'react';
import { Grid, Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import ImageLoader from '../../../image-loader';

import styles from './Desktop.module.scss';

const MainSliderItemDesktop = ({ arrayImages }) => {
  return (
    // <div style={{ display: 'block' }}>
		<Box sx={{ display: 'flex', width: '100%' }}>
			<Grid item xs={12} md={6} sx={{ pr: 2 }}>
				<Box className={styles['slider-desktop__block']}>
					<ImageLoader src={arrayImages[0].image} alt="" />
					{/* <img src={arrayImages[0].image} alt="" /> */}
					<Box>
						<Box className={styles['slider-desktop__block-title']}>{arrayImages[0].title}</Box>
						<Box className={styles['slider-desktop__block-button']}>
							<Link to={arrayImages[0].url}>Перейти</Link>
						</Box>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} md={6} sx={{ pl: 2 }}>
				<Stack direction={{ xs: 'column' }} spacing={{ md: 4 }}>
					<Box className={clsx(styles['slider-desktop__block'], styles['slider-desktop__block-small'])}>
						<ImageLoader src={arrayImages[1].image} alt="" />
						{/* <img src={arrayImages[1].image} alt="" /> */}
						<Box>
							<Box className={styles['slider-desktop__block-title']}>{arrayImages[1].title}</Box>
							<Box className={styles['slider-desktop__block-button']}>
								<Link to={arrayImages[1].url}>Перейти</Link>
							</Box>
						</Box>
					</Box>
					<Box className={clsx(styles['slider-desktop__block'], styles['slider-desktop__block-small'])}>
						<ImageLoader src={arrayImages[2].image} alt="" />
						{/* <img src={arrayImages[2].image} alt="" /> */}
						<Box>
							<Box className={styles['slider-desktop__block-title']}>{arrayImages[2].title}</Box>
							<Box className={styles['slider-desktop__block-button']}>
								<Link to={arrayImages[2].url}>Перейти</Link>
							</Box>
						</Box>
					</Box>
				</Stack>
			</Grid>
		</Box>
    // </div>
  )
}

export default MainSliderItemDesktop;
