import React, { useId } from 'react';
import { Box, Stack } from '@mui/material';
// import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import styles from './TopBar.module.scss';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom';

const CategoriesChilds = ({ childs, level = 1 }) => {

	if (childs.length > 0) {
		return (
			<>
				{
					childs.map(categChild => (
						<Box className={clsx(styles['child-container'], styles[`child-container__level-${level}`])} key={categChild.id}>
							<Box key={categChild.id} className={clsx(styles['child-caption'], styles[`child-caption__level-${level}`])}>
								<Link to={categChild.url}>{categChild.caption}</Link>
							</Box>
							{
								typeof categChild.childs !== 'undefined' && categChild.childs.length > 0 && <CategoriesChilds childs={categChild.childs} level={(++level)} />
							}
						</Box>
					))
				}
			</>
		);
	} else {
		return false;
	}
}

const TopBar = ({ categoriesStore }) => {

	const { categories } = categoriesStore;
	categories.push({ id: useId(), caption: 'Каталог', 'url': '/catalog', childs: [] });
	// console.log(toJS(categories));

	return (
		<Stack className={styles['top-bar']}>
			{
				categories.map(category => (
					<Box key={category.id} className={styles['top-bar__item']}>
						<Box className={styles['top-bar__item-caption']}><Link to={category.url}>{category.caption}</Link></Box>
						{
							category.childs.length > 0 && (
								<PerfectScrollbar className={styles['top-bar__item-dropdown']}>
									<Box className={styles['top-bar__item-dropdown-wrapper']}>
										<CategoriesChilds childs={category.childs} />
									</Box>
								</PerfectScrollbar>
							)
						}
					</Box>
				))
			}
		</Stack>
	)
}

export default observer(TopBar);
