import { makeAutoObservable } from "mobx";
import AuthService from "../services/auth/AuthService";
import axios from "axios";
import { isDesktop, isMobile } from "react-device-detect";
import _ from 'lodash';

import { AUTH_API_URL } from "../http";

// import MyInfo from "./my-info";

class Auth {
	myInfoStoreTemp;
	constructor () {
		makeAutoObservable(this);
		// this.validateJWT();
		// this.myInfoStoreTemp = new MyInfo();
	}

	_jwt = localStorage.getItem('token') || '';
	login = '';
	password = '';
	isLogin = true;
	isAuth = false;
	rememberMe = false;
	// checkedIsAuth = false;
	errorMessage = '';
	isLoading = false;
	clientData = {};
	clientSizes = [];
	googleUser = [];
	googleProfile = [];
	// authError = false;

	setLogin (login) {
		this.login = login;
	};

	setPassword (password) {
		this.password = password;
	};

	setAuth (bool) {
		this.isAuth = bool;
	};

	setIsLogin (isLogin) {
		this.isLogin = isLogin;
	};

	// setAuthError (bool) {
	// 	this.authError = bool;
	// };

	setRememberMe (remeber) {
		this.rememberMe = Boolean(remeber);
	}

	setErrorMessage (message) {
		this.errorMessage = message;
	}

	setGoogleUser (user) {
		this.googleUser = user;
	}

	setGoogleProfile (profile) {
		this.googleProfile = profile;
	}

	setClientData = async (obj) => {
		this.clientData = obj;
	};

	setClientSizes = async (array) => {
		if (Array.isArray(array) && array.length > 0 && array[0].sizeID !== 'initMode') {
			this.clientSizes = array;
		}
	}

	// setCheckedIsAuth (checkedAuth) {
	// 	this.checkedIsAuth = checkedAuth;
	// }

	async loginAuth (login, password, rememberMe) {
		try {
			const { data } = await AuthService.login(login, password, rememberMe);
			if (data.status === 'success') {
				localStorage.setItem('token', data.jwt);
				this._jwt = data.jwt;
				this.setAuth(true);
			} else if (data.status === 'error') {
				localStorage.removeItem('token');
				this.setAuth(false);
				this.setErrorMessage('Неправильні дані.');
			}
		} catch (e) {
			console.log('Помилка логінізації', e);
		}
	}

	async registrationAuth (login, password) {
		try {
			const { data } = await AuthService.registration(login, password);
			if (data.status === 'success') {
				localStorage.setItem('token', data.jwt);
				this._jwt = data.jwt;
				this.setAuth(true);
			} else if (data.status === 'error') {
				this.setAuth(false);
				this.setErrorMessage('Неправильні дані.');
			}
		} catch (e) {
			console.log(e);
		}
	}

	logoutAuth = () => {
		localStorage.removeItem('token');
		this.setAuth(false);
	}

	setIsLoading (bool) {
		this.isLoading = bool;
	}

	setClientDataFromResponse = (data, typeInfo) => {
		if (_.includes(typeInfo, 'client-sizes')) {
			this.setClientSizes(data.data.client_sizes);
			// console.log('this.clientSizes > ', this.clientSizes);
		} else {
			this.setClientData(data.data.client_info);
		}
	}

	validateJWT = async (resultInfo = []) => {
		try {
			// this.jwt = localStorage.getItem('token');
			if (this._jwt !== null && this._jwt !== '') {
				const jwt = this._jwt;
				this.setIsLoading(true);
				if (isMobile || (_.includes(resultInfo, 'messages') && isDesktop)) {
					resultInfo.push('client-info');
				}
				const { data } = await axios.post(`${AUTH_API_URL}/validate.php`, { jwt, resultInfo });
				// const response = await axios.post(`https://zefir.store/php/auth/validate.php`, { jwt });

				if (data.status === 'error') {
					this.setAuth(false);
					localStorage.removeItem('token');
				} else if (data.status === 'success') {
					this.setAuth(true);
					// console.log('data.data.client_info >>>', data.data.client_info);
					if (resultInfo.length > 0) {
						this.setClientDataFromResponse(data, resultInfo);
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
		this.setIsLoading(false);
	}

	handleSubmitForm = async () => {
		if (this.login && this.password) {
			if (this.isLogin) {
				await this.loginAuth(this.login, this.password, this.rememberMe);
			} else {
				await this.registrationAuth(this.login, this.password);
			}
		}
	}

	loginWithGoogle = async () => {
		if (this.googleUser && this.googleUser.access_token) {
			try {
				const { data } = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${this.googleUser.access_token}`, {
					headers: {
						Authorization: `Bearer ${this.googleUser.access_token}`,
						Accept: 'application/json'
					}
				});
				this.setGoogleProfile(data);
			} catch (e) {
				console.error('Помилка отримання даних Google', e)
			}
		}
	}
}

export default Auth;
