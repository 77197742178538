import { makeAutoObservable } from "mobx";
// import axios from "axios";

class Catalog {
	constructor () {
		makeAutoObservable(this);
	}

	categories = [
		{
			id: 1,
			caption: 'Верхній одяг',
			value: 'outerwear'
		},
		{
			id: 2,
			caption: 'Вітровки',
			value: 'windbreakers'
		},
		{
			id: 3,
			caption: 'Дощовики',
			value: 'rainwear'
		},
		{
			id: 4,
			caption: 'Куртки',
			value: 'Jackets'
		},
		{
			id: 5,
			caption: 'Пальто',
			value: 'coat'
		},
		{
			id: 6,
			caption: 'Плащі',
			value: 'raincoats'
		}
	];

	sort = [
		{
			id: 1,
			caption: 'За рейтингом',
			value: 'rating'
		},
		{
			id: 2,
			caption: 'Новинки',
			value: 'new'
		},
		{
			id: 3,
			caption: 'Ціна: від низької до високої',
			value: 'priceAsc'
		},
		{
			id: 4,
			caption: 'Ціна: від високої до низької',
			value: 'priceDesc'
		},
	];

	colors = [
		{
			id: 1,
			caption: 'Orange',
			value: '#ffa500'
		},
		{
			id: 2,
			caption: 'Purple',
			value: '#800080'
		},
		{
			id: 3,
			caption: 'Cyan',
			value: '#00ffff'
		},
		{
			id: 4,
			caption: 'Brown',
			value: '#a52a2a'
		},
		{
			id: 5,
			caption: 'Gray',
			value: '#808080'
		},
		{
			id: 6,
			caption: 'Baby Blue',
			value: '#89cff0'
		},
		{
			id: 7,
			caption: 'Black',
			value: '#000000'
		},
		{
			id: 8,
			caption: 'Red',
			value: '#ff0000'
		}
	];

	size = [
		{
			id: 1,
			caption: 'M',
			value: 'm'
		},
		{
			id: 2,
			caption: 'L',
			value: 'l'
		},
		{
			id: 3,
			caption: 'X',
			value: 'x'
		},
		{
			id: 4,
			caption: 'XL',
			value: 'xl'
		},
	];

	pricesValue = [115 , 875];

	setPricesValue = (prices) => {
		this.pricesValue = prices;
	}

	setCategories (categories) {
		this.categories = categories;
	}

	setSort (sort) {
		this.sort = sort;
	}

	setColors (colors) {
		this.colors = colors;
	}

	setSize (size) {
		this.size = size;
	}
}

export default Catalog;
