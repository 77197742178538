import { Box } from "@mui/material";

import styles from './BrandCard.module.scss';

const BrandCard = ({ brandImage }) => {
	return (
		<Box sx={{ pr: { xxs: 1, def: 2 }, pl: { xxs: 1, def: 2 } }}>
			<Box className={styles['brand-card']}>
				<Box className={styles['brand-card__image']}><img src={brandImage.image} alt="" /></Box>
			</Box>
		</Box>
	)
}

export default BrandCard