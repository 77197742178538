import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Link } from 'react-router-dom';

import LoadingSpinner from '../loading-spinner';
import { Context } from '../..';

import './LeftBar.scss';

const LeftBar = () => {

	const store = useContext(Context);
	const { categoriesStore } = store.rootStore;

	if (categoriesStore.error.length > 0) {
		return <Box sx={{ color: 'tomato' }}>{categoriesStore.error}</Box>
	}

	if (categoriesStore.isLoading) {
		return <Box className="menu"><LoadingSpinner /></Box>;
	}

  return (
		<Box className='menu'>
			{
				categoriesStore.categories.map(category => (
					<Accordion className='menu-accordion' key={category.id}>
						<AccordionSummary
							className='menu-accordion__summary'
							aria-controls={`panel${category.id}a-content`}
							id={`panel${category.id}a-header`}
						>
							{category.caption}
						</AccordionSummary>
						{
							(typeof category.childs !== 'undefined' && category.childs.length > 0) && (
								<AccordionDetails className='menu-accordion__details'>
								{
									category.childs.map(childCategory => (
										<Box className='menu-accordion__details-link' key={childCategory.id}>
											<Link to='#' className='menu-accordion__details-link-item'>{childCategory.caption}</Link>
										</Box>
									))
								}
								</AccordionDetails>
							)
						}
					</Accordion>
				))
			}
		</Box>
  )
}

export default observer(LeftBar);
