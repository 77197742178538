import React, { useState, useContext } from 'react';
import { Box, Stack, Container, Grid, Checkbox, FormControlLabel, FormGroup, FormControl, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { isDesktop } from 'react-device-detect';

import BreadcrumbsPage from '../../components/breadcrumbs';
import ProductCard from '../../components/product-card';
import PaginationSeller from '../../components/admin/shopper/pagination-seller';

// import { Checkbox } from 'primereact/checkbox';

import Layout from '../../components/layout';

import styles from './Catalog.module.scss';

import { Context } from '../..';

const breadcrumbs = [
	{
		label: 'Головна',
		url: '/'
	},
	{
		label: 'Каталог',
		url: '/catalog'
	}
];

const BpColor = styled('span')(({ color }) => ({
	backgroundColor: `${color}`,
	width: '25px',
	height: '25px',
	borderRadius: '50%',
	padding: '2px',
	outline: '1px solid transparent',
	transition: 'outline .3s, outline-offset .3s',
	'input:hover ~ &': {
    outlineColor: '#b2b2b2',
		outlineOffset: 2
  }
}));

const BpColorChecked = styled(BpColor)(({ color }) => ({
	backgroundColor: `${color}`,
	'input:checked ~ &': {
    outlineColor: '#b2b2b2',
		outlineOffset: 2
  }
}));

const BpText = styled('span')(({ text }) => ({
	width: '45px',
	height: '45px',
	borderRadius: '6px',
	border: '1px solid #9e9e9e',
	fontWeight: 'bold',
	'&:before': {
		position: 'absolute',
		content: `'${text}'`,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		color: '#35322e',
		transition: 'color .3s'
	},
	transition: 'background-color .3s, color .3s',
	'input:hover ~ &': {
    backgroundColor: '#9e9e9e',
		':before': {
			color: '#ffffff'
		}
  }
}));

const BpTextChecked = styled(BpText)(({ text }) => ({
	'&:before': {
		content: `'${text}'`
	},
	'input:checked ~ &': {
    backgroundColor: '#9e9e9e',
		':before': {
			color: '#ffffff'
		}
  }
}));

const ItemFilterTitle = ({ title }) => {
	return (
		<Box className={styles['catalog-filter__item-title']}>
			<Box className={styles['filter-lines']}>
				<Box className={styles['line-top']}></Box>
				<Box className={styles['line-bottom']}></Box>
			</Box>
			<Box className={styles['filter-title']}>{title}</Box>
		</Box>
	);
}

const ItemFilterCheckbox = ({ items, title }) => {
	return (
		<Box className={styles['catalog-filter__item']}>
			<ItemFilterTitle title={title} />
			<Box className={styles['catalog-filter__item-checkboxes']}>
				{
					items.map((item) => (
						<FormControlLabel
							className="checkbox checkbox-catalog"
							key={item.id}
							control={<Checkbox value={item.value} disableRipple />}
							label={item.caption}
						/>
					))
				}
			</Box>
		</Box>
	)
}

const ItemFilterColors = ({ items, title, filterName }) => {
	return (
		<Box className={styles['catalog-filter__item']}>
			<ItemFilterTitle title={title} />
			<Box className={styles['catalog-filter__item-checkboxes']}>
				<FormControl>
					<FormGroup row name={filterName} aria-describedby="product-color-helper-text">
						{
							items.map(({ id, caption, value }) => (
								<FormControlLabel
									key={id}
									value={id}
									// label={caption}
									name={filterName}
									control={
										<Checkbox
											className='radio-button'
											icon={<BpColor color={value} />}
											checkedIcon={<BpColorChecked color={value} />}
											disableRipple
										/>
									}
								/>
							))
						}
					</FormGroup>
				</FormControl>
			</Box>
		</Box>
	)
}

const ItemFilterText = ({ items, title, filterName }) => {
	return (
		<Box className={styles['catalog-filter__item']}>
			<ItemFilterTitle title={title} />
			<Box className={styles['catalog-filter__item-checkboxes']}>
				<FormControl>
					<FormGroup row name={filterName} aria-describedby="product-color-helper-text">
						{
							items.map(({ id, caption, value }) => (
								<FormControlLabel
									key={id}
									value={value}
									name={filterName}
									control={
										<Checkbox
											className='radio-button'
											icon={<BpText text={caption} />}
											checkedIcon={<BpTextChecked text={caption} />}
											disableRipple
										/>
									}
								/>
							))
						}
					</FormGroup>
				</FormControl>
			</Box>
		</Box>
	)
}

const StyledSlider = styled(Slider)`
  && {
    color: #6fcce5;
    .MuiSlider-thumb {
      height: 20px;
      width: 20px;
      background-color: #6fcce5;
      border: 2px solid currentColor;
      margin-top: 0;
      margin-left: 0;
      &:focus,
      &:hover,
      &.Mui-active,
			&.Mui-focusVisible {
        box-shadow: none;
      }
			&:before {
				box-shadow: unset
			}
    }
    .MuiSlider-valueLabel {
      left: calc(-50% + 4px);
    }
    .MuiSlider-track {
      height: 4px;
      border-radius: 4px;
    }
    .MuiSlider-rail {
      height: 4px;
      border-radius: 4px;
    }
  }
`;

const ItemFilterSlider = ({ items, title, onChangeSlider }) => {
	// const valueRange = [items.minValue, items.maxValue];
	return (
		<Box className={styles['catalog-filter__item']}>
			<ItemFilterTitle title={title} />
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box className={styles['catalog-filter__item-checkboxes']}>
					<StyledSlider
						getAriaLabel={() => 'Temperature range'}
						value={items}
						onChange={onChangeSlider}
						// valueLabelDisplay="auto"
						// getAriaValueText={valuetext}
						min={0}
						max={1500}
						step={5}
					/>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
					<Box>Ціна:</Box>
					<Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
						<Box>{items[0] + '₴'}</Box>
						<Box sx={{ ml: 1, mr: 1 }}>&mdash;</Box>
						<Box>{items[1] + '₴'}</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

const categoryProductsInfo = [
  {
    id: 1,
    title: 'Джинсовий костюм',
    description: 'Джинси блакитні з високою посадкою',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front1.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '930 ₴'
  },
  {
    id: 2,
    title: 'Біла сукня',
    description: 'Джинси блакитні з високою посадкою',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front2.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '780 ₴'
  },
  {
    id: 3,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front3.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '330 ₴'
  },
  {
    id: 4,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front4.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '350 ₴'
  },
  {
    id: 5,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front5.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '450 ₴'
  },
  {
    id: 6,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front6.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '620 ₴'
  },
  {
    id: 7,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front7.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '790 ₴'
  },
  {
    id: 8,
    title: 'Джинсова сукня',
    description: 'Джинсова сукня з відкритими плечима',
    options: '38/M/46',
    imgFront: '../assets/images/product-card/prod_front8.webp',
    imgBack: '../assets/images/product-card/prod_back.webp',
    price: '240 ₴'
  }
];

const Catalog = () => {

	const store = useContext(Context);
	const { categories, sort, colors, size, pricesValue, setPricesValue } = store.rootStore.catalogStore;

	const [selectedCategories, setSelectedCategories] = useState([]);
	const onCategoryChange = (e) => {
		let _selectedCategories = [...selectedCategories];

		if (e.checked)
			_selectedCategories.push(e.value);
		else
			_selectedCategories = _selectedCategories.filter(items => items.id !== e.value.id);

		setSelectedCategories(_selectedCategories);
	};

	const handleChangeSlider = (event, newValue) => {
    setPricesValue(newValue);
  };

	const siblingCountPagination = isDesktop ? 1 : 0;

  return (
    <Layout>
			<Stack>
				<Container maxWidth='xmg' sx={{ paddingLeft: { xxs: 0, def: '24px' }, paddingRight: { xxs: 0, def: '24px' } }}>
					<Grid container className={styles['catalog-banner']}>
						<Box className={styles['catalog-banner__title']}>MarkeT</Box>
					</Grid>
					<Box sx={{ mt: 1, mb: 1 }}>
						<BreadcrumbsPage items={breadcrumbs} />
					</Box>
					<Grid container>
						<Grid item xxs={12} def={3} className={styles['catalog-filter']} sx={{ pr: { def: 2 } }}>
							<ItemFilterCheckbox title='Категорії' items={categories} />
							<ItemFilterCheckbox title='Сортувати за' items={sort} />
							<ItemFilterColors title='Колір' items={colors} filterName='colors' />
							<ItemFilterText title='Розмір' items={size} filterName='size' />
							<ItemFilterSlider title='Діапазон цін' items={pricesValue} onChangeSlider={handleChangeSlider} />
						</Grid>
						<Grid item xxs={12} def={9} sx={{ display: 'flex', flexDirection: 'column', pl: { def: 2 } }}>
							<Box className={styles['catalog-products']}>
								{
									categoryProductsInfo.map((prod) => (
										<ProductCard key={prod.id} productInfo={prod} />
									))
								}
							</Box>
							<Box className={styles['catalog-pagination']}>
								<PaginationSeller count={5} shape="rounded" defaultPage={1} siblingCount={siblingCountPagination} />
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Stack>
		</Layout>
  )
}

export default observer(Catalog);
