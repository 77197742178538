import MyOrdersBlock from '../../../../components/admin/shopper/my-orders-block';
import LayoutSeller from '../../../../components/admin/layout';

const MyOrders = () => {
  return (
    <LayoutSeller>
      <MyOrdersBlock />
    </LayoutSeller>
  )
}

export default MyOrders;
