import OrdersBlock from '../../../../components/admin/seller/orders-block';
import LayoutSeller from '../../../../components/admin/layout';

const Orders = () => {
  return (
    <LayoutSeller typeInfo='client-info'>
      <OrdersBlock />
    </LayoutSeller>
  )
}

export default Orders;
