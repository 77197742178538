import SubscriptionBlock from '../../../../components/admin/shopper/subscription-block';
import LayoutSeller from '../../../../components/admin/layout';

const Subscription = () => {
	return (
		<LayoutSeller typeInfo='client-info'>
			<SubscriptionBlock />
		</LayoutSeller>
	)
}

export default Subscription
