import * as yup from 'yup';

const schemaAuth = yup.object().shape({
	login: yup.string().required('Це поле обов\'язкове').matches(/^((((\+?)(\d{1,3}))\s?)?(([0-9]{0,4})|(\([0-9]{2,3}\)))(-|\s)?(([0-9]{3})(-|\s)?([0-9]{2})(-|\s)?([0-9]{2})|([0-9]{2})(-|\s)?([0-9]{2})(-|\s)?([0-9]{3})|([0-9]{2})(-|\s)?([0-9]{3})(-|\s)?([0-9]{2})))|((([a-zA-Z0-9&+-=_])+((\.([a-zA-Z0-9&+-=_]){1,})+)?){1,64}@([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6})$/, 'Некоректний e-mail або телефон'),
	password: yup.string().required('Це поле обов\'язкове').min(6, 'Пароль повинен бути довжиною не менше 6 символів'),
	email: yup.string().email('Введіть коректний e-mail'),
	phone: yup.string().matches(/^((((\+?)(\d{1,3}))\s?)?(([0-9]{0,4})|(\([0-9]{2,3}\)))(-|\s)?(([0-9]{3})(-|\s)?([0-9]{2})(-|\s)?([0-9]{2})|([0-9]{2})(-|\s)?([0-9]{2})(-|\s)?([0-9]{3})|([0-9]{2})(-|\s)?([0-9]{3})(-|\s)?([0-9]{2})))$/, 'Введіть коректний номер телефону')
});

const schemaSecurity = yup.object().shape({
	password: yup.string().required('Це поле обов\'язкове').min(6, 'Пароль повинен бути довжиною не менше 6 символів'),
	loginEmail: yup.string().email('Введіть коректний e-mail'),
	loginPhone: yup.string().matches(/^((((\+?)(\d{1,3}))\s?)?(([0-9]{0,4})|(\([0-9]{2,3}\)))(-|\s)?(([0-9]{3})(-|\s)?([0-9]{2})(-|\s)?([0-9]{2})|([0-9]{2})(-|\s)?([0-9]{2})(-|\s)?([0-9]{3})|([0-9]{2})(-|\s)?([0-9]{3})(-|\s)?([0-9]{2})))$/, 'Введіть коректний номер телефону')
});

const schemaInfo = yup.object().shape({
	firstname: yup.string().min(2, "Ім'я повинно бути довжиною не менше 2 символів"),
	surname: yup.string().min(2, "Прізвище повинно бути довжиною не менше 2 символів"),
	email: yup.string().matches(/^((([a-zA-Z0-9&+-=_])+((\.([a-zA-Z0-9&+-=_]){1,})+)?){1,64}@([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6})$/, 'Введіть дійсну адресу електронної пошти'),
	phone: yup.string().matches(/^((((\+?)(\d{1,3}))\s?)?(([0-9]{0,4})|(\([0-9]{2,3}\)))(-|\s)?(([0-9]{3})(-|\s)?([0-9]{2})(-|\s)?([0-9]{2})|([0-9]{2})(-|\s)?([0-9]{2})(-|\s)?([0-9]{3})|([0-9]{2})(-|\s)?([0-9]{3})(-|\s)?([0-9]{2})))$/, 'Введіть коректний номер телефону')
});

const schemaAddress = yup.object().shape({
	city: yup.string(),//.min(2, "Введіть щонайменше 2 символи"),
	post_office: yup.string(),//.min(2, "Введіть щонайменше 2 символи"),
	zip: yup.string(),//.matches(/^\d{5}$/, 'Введіть коректний поштовий індекс'),
	street: yup.string(),
	building: yup.string(),//.matches(/^\d$/, 'Введіть тільки цифри'),
	apartment: yup.string()
});

const schemaSize = yup.object().shape({
	caption: yup.string(),
	numberSize: yup.number().integer().nullable(true)
	// lengthFrom: yup.number(),
	// lengthTo: yup.number(),
	// lengthFoot: yup.number()
});


const FILE_FORMATS = {
  image: ['image/jpeg', 'image/png', 'image/gif'],
  video: ['video/mp4', 'video/mkv'],
};

const fileValidationSchema = yup.array()
    // .min(6, 'Мінімум 6 файлів')
		.test('required', 'Файли обов\'язкові', value => value && value.length > 0)
    .test('image-files', 'Має бути 5 малюнків', (value) => {
      const imageFiles = value.filter((file) => FILE_FORMATS.image.includes(file.type));
      return imageFiles.length === 5;
    })
    .test('video-file', 'Має бути 1 відео файл', (value) => {
      const videoFiles = value.filter((file) => FILE_FORMATS.video.includes(file.type));
      return videoFiles.length === 1;
    })
		.test('all-files-required', 'Всі файли обов\'язкові', (value) => value.length === 6);

// const fileValidationSchema = yup.mixed()
//   .test('required', 'Файли обов\'язкові', value => value && value.length > 0)
// 	.test('file-types', 'Має бути 5 зображень та 1 відео', value => {
//     if (!value || value.length < 6) return false;

//     let imageCount = 0;
//     let videoCount = 0;

//     value.forEach(file => {
//       if (file.type.startsWith('image/')) imageCount++;
//       if (file.type.startsWith('video/')) videoCount++;
//     });

// 		console.log('imageCount', imageCount);
// 		console.log('videoCount', videoCount);

//     return imageCount >= 5 && videoCount >= 1;
//   })
//   .test('min-files', 'Необхідно мінімум 6 файлів', value => {
// 		console.log('files length > ', (value && value.length));
// 		return value && value.length >= 6
// 	});

const requiredIfPresent2 = yup.mixed()
		.test('requiredIfPresent', "Це поле обов'язкове", (value) => value !== undefined && value !== null && value !== '')
		.typeError("Це поле обов'язкове");

const requiredIfPresent = (schema) => {
  return yup.mixed().test({
    name: 'requiredIfPresent',
    exclusive: false,
    message: '${path} is required',

    test: function (value) {
      if (value !== undefined && value !== null && value !== '') {
        try {
          schema.validateSync(value);
          return true;
        } catch (err) {
          return this.createError({
            path: this.path,
            message: err.errors[0],
          });
        }
      }
      return true; // Поле відсутнє, тому не обов'язкове
    },
  });
};


const schemaAddProduct = yup.object().shape({
	caption: yup.string().min(2, "Назва повинна бути довжиною не менше 2 символів").required("Це поле обов'язкове"),
	description: yup.string().min(5, "Опис повинен бути довжиною не менше 5 символів").required("Це поле обов'язкове"),
	price: yup.string().required("Це поле обов'язкове"),
	count: yup.string().required("Це поле обов'язкове"),
	files: fileValidationSchema,
	// categoryValue: yup.string().required("Це поле обов'язкове"),
	condition: yup.string().required("Це поле обов'язкове"),
	brand: yup.string().required("Це поле обов'язкове"),
	size_by_age: yup.string().required("Це поле обов'язкове"),
	size_by_pair_1: yup.string().required("Це поле обов'язкове"),
	size_by_pair_2: yup.string().required("Це поле обов'язкове"),
	size_by_foot: yup.string().required("Це поле обов'язкове"),
	size_child: yup.string().required("Це поле обов'язкове"),

	// size_child: yup.string().test('is-present', "Це поле обов'язкове", value => {
  //   return value === undefined || value === null || value === '' ? true : false
  // }).typeError("Це поле обов'язкове"),
	// size_child: yup.string().when([], {
	// 	is: () => false,
	// 	then: yup.string().required('Поле обов\'язкове'),
	// 	otherwise: yup.string().notRequired(),
	// }),
	// size_child: yup.string().when('$isFieldPresent', {
	// 	is: true,
	// 	then: yup.string().required('Поле обов\'язкове'),
	// 	otherwise: yup.string().notRequired(),
	// }),
	// size_child: yup.string().test('is-empty', 'Field is invalid', value => {
  //   return value !== undefined && value !== null && value !== ''
  // }),
	// size_child: yup.string()
	// 	.when('size_child', {
	// 		is: (value) => value !== undefined,
	// 		then: yup.string().required("Це поле обов'язкове"),
	// 	}),
	// size_child: yup.string().test('is-empty', "Це поле обов'язкове", value => value === '' || value),
	// size_child: yup.string()
	// 	.required("Це поле обов'язкове"),
		// .nullable(),
		// .transform((value, originalValue) => {
		// 	return originalValue === '' ? null : value;
		// }),
    // .when('size_child', {
    //   is: (value) => value !== undefined,
    //   then: yup.object().required('Це поле обов\'язкове'),
    // }),
	// size_child: requiredIfPresent(yup.string().required("Це поле обов'язкове")),
	// size_child: requiredIfPresent2,
	// size_child: yup.object().shape({
	// 	sizeName: yup.string().required(), // Значення radiobutton
	// 	sizeID: yup.string().required(), // Текст radiobutton
	// })
	// .when('size_child', {
	// 	is: (sizeID) => sizeID !== undefined, // Перевірка, чи поле присутнє
	// 	then: yup.string().required("Це поле обов'язкове"), // Обов'язкове, якщо присутнє
	// 	otherwise: yup.string(), // Необов'язкове, якщо відсутнє
	// }),
	// size_child: yup.string().when('$presence', (presence, schema) => {
  //   return presence ? schema.required("Це поле обов'язкове") : schema;
  // }),
	// Динамічні групи radiobuttonів
  // size: yup.array().of(
  //   yup.object().shape({
  //     valueID: yup.string().required(), // Значення radiobutton
	// 		label: yup.string().required(), // Текст radiobutton
  //   })
  // )
	// .when('size', {
	// 	is: (value) => value.length > 0, // Перевірка, чи є групи
	// 	then: yup.array().min(1).required("Це поле обов'язкове"), // Обов'язково, якщо є
	// 	otherwise: yup.array().nullable(), // Необов'язково, якщо немає
	// }),
	// size_bra: yup.string().required("Це поле обов'язкове"),
	// sizes: yup.array()
	// .of(
	// 	yup.object().shape({
	// 		attributeID: yup.string(),
	// 		systemName: yup.string(),
	// 	})
	// )
	// .required("Це поле обов'язкове")
	// .typeError("Це поле обов'язкове"),
	// size: yup.string().required("Це поле обов'язковіве"),

	breast: yup.string().required("Це поле обов'язкове"),
	waist: yup.string().required("Це поле обов'язкове"),
	thigh: yup.string().required("Це поле обов'язкове"),
	foot: yup.string().required("Це поле обов'язкове"),
	colors: yup.array()
		.required("Це поле обов'язкове")
		.typeError("Це поле обов'язкове")
		.min(1, "Виберіть хоча б один колір."),
	material: yup.string().required("Це поле обов'язкове"),
	print: yup.string().required("Це поле обов'язкове"),
	seasons: yup.array()
		.required("Це поле обов'язкове")
		.typeError("Це поле обов'язкове")
		.min(1, "Це поле обов'язкове")
});

export { schemaAuth, schemaInfo, schemaAddress, schemaSize, schemaAddProduct, schemaSecurity };
