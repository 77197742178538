import OrderItemBlock from '../../../../components/admin/seller/order-item-block';
import LayoutSeller from '../../../../components/admin/layout';

const OrderItem = () => {
  return (
    <LayoutSeller typeInfo='client-info'>
      <OrderItemBlock />
    </LayoutSeller>
  )
}

export default OrderItem;
