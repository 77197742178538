import React, { useContext, useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, Button, TextField, Grid, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaAuth } from '../validator';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

// import MyInfo from '../../pages/admin/seller/my-info';

import './Auth.scss';

const Auth = ({ openDialog, handleCloseDialog }) => {

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);

  const { register, formState: { errors }, handleSubmit, setError, clearErrors } = useForm({
    resolver: yupResolver(schemaAuth),
  });

	const navigate = useNavigate();

  const store = useContext(Context);
  const { authStore } = store.rootStore;

	const popupTitle = authStore.isLogin ? 'Увійдіть' : 'Зареєструватися';

	// const handleLoginChange = (event) => {
  //   authStore.setLogin(event.target.value);
	// 	// authStore.setEmail(event.target.value);
	// };

	// const handlePasswordChange = (event) => {
  //   authStore.setPassword(event.target.value);
	// 	// authStore.setPassword(event.target.value);
	// };

  const submitForm = async () => {
    await authStore.handleSubmitForm();

		if (!authStore.isAuth) {
			setError('login', {
				message: authStore.errorMessage
			});

			setError('password', {
				message: authStore.errorMessage
			});
		}

		if (authStore.isAuth) {
			navigate('/shopper/my-info');
		}
  }

	const handleClickCloseDialog = () => {
		handleCloseDialog();
		clearErrors();
	};

	// const submitForm = async (data) => {
  //   await authStore.handleSubmitForm(data);
	// 	if (authStore.isAuth) {
	// 		navigate('/seller/my-info');
	// 	}
  // }

	const login = useGoogleLogin({
		onSuccess: tokenResponse => {
			authStore.setGoogleUser(tokenResponse);
			authStore.loginWithGoogle();
		},
		onError: (error) => console.log('Google authorization Failed:', error)
	});

	// log out function to log the user out of google and set the profile array to null
	// const logOut = () => {
	// 		googleLogout();
	// 		authStore.setGoogleProfile([]);
	// };

	// const responseMessage = (response) => {
	// 	console.log(response);
	// };

	// const errorMessage = (error) => {
	// 		console.log(error);
	// };

  const typographyTextRegOrLogin = authStore.isLogin ? "Ще немає особистого кабінету?" : "Ви вже зареєструвалися?";

	return (
		<Dialog open={openDialog} onClose={() => handleClickCloseDialog()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="xs" fullWidth className='dialog-auth'>
			<DialogTitle className='dialog-auth__title' id="alert-dialog-title">
        {popupTitle}
        <IconButton disableRipple onClick={() => handleClickCloseDialog()} className='dialog-auth__close'><CloseRoundedIcon /></IconButton>
      </DialogTitle>
			<DialogContent className='dialog-auth__content'>
				<Box>
          <form onSubmit={handleSubmit(submitForm)} style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              name="login"
              {...register("login")}
              placeholder="E-mail або телефон"
              error={Boolean(errors.login)}
              // helperText={errors.login ? errors.login.message : (!authStore.isAuth ? authStore.errorMessage : '')}
              helperText={errors.login ? errors.login.message : ''}
              className='dialog-auth__input'
              InputProps={{
                startAdornment: <Person2RoundedIcon />
              }}
              autoComplete='off'
							value={authStore.login}
							onChange={(e) => authStore.setLogin(e.target.value)}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              name="password"
              {...register("password")}
              error={Boolean(errors.password)}
              // helperText={errors.password ? errors.password.message : (!authStore.isAuth ? authStore.errorMessage : '')}
              helperText={errors.password ? errors.password.message : ''}
              InputProps={{
                startAdornment: <KeyRoundedIcon />,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword} disableRipple>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              className='dialog-auth__input'
              placeholder="Пароль"
							value={authStore.password}
							onChange={(e) => authStore.setPassword(e.target.value)}
            />

            {
              authStore.isLogin && (
                <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <FormControlLabel
                    control={<Checkbox name="rememberMe" {...register("rememberMe")} value={authStore.rememberMe} size="small" className="dialog-auth__remember" disableRipple onChange={(e) => authStore.setRememberMe(e.target.checked)} />}
                    label="Запам'ятай мене"
                    className="dialog-auth__remember-label"
                  />
                  <Box className="dialog-auth__forgot-pass">Забули пароль?</Box>
                </Grid>
              )
            }

            <Button variant="contained" disableRipple className="dialog-auth__button" endIcon={<ArrowForwardRoundedIcon />} type='submit'>
              <span className='dialog-auth__button-text'>{authStore.isLogin ? "Увійти" : "Реєстрація"}</span>
            </Button>

            { /* <Button type="submit" variant="contained" fullWidth disableRipple className="dialog-auth__button">
              {authStore.isLogin ? "Login" : "Register"}
            </Button> */ }

						<Button variant="contained" disableRipple className='dialog-auth__button' onClick={() => login()}>
							<span className='dialog-auth__button-text'>Увійти за допомогою Google</span>
						</Button>
						{/* <div onClick={() => login()} style={{ border: '1px solid #d8d8d8', padding: '10px', cursor: 'pointer' }}>Sign in with Google 🚀 </div> */}
            <Grid className='dialog-auth__bottom' container>
              <Box sx={{ mr: { def: 2 } }}>{typographyTextRegOrLogin}</Box>
              <Box onClick={() => authStore.setIsLogin(!authStore.isLogin)} className='dialog-auth__bottom-link'>
                { authStore.isLogin ? "Зареєструватися" : "Увійти" }
              </Box>
            </Grid>
          </form>
				</Box>
			</DialogContent>
			{/* <DialogActions>
				<Button onClick={handleCloseDialog}>Disagree</Button>
				<Button onClick={handleCloseDialog} autoFocus>Agree</Button>
			</DialogActions> */}
		</Dialog>
  )
}

export default observer(Auth);
