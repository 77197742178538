import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import styles from './Categories.module.scss';

// import { Context } from '../..';

const Categories = ({ categoriesStore }) => {
	// const store = useContext(Context);
	// const { categoriesStore } = store.rootStore;
  const [activeCategory, setActiveCategory] = useState(1);

	// useEffect(() => {
	// 	const handleFetchCategories = async () => {
	// 		await categoriesStore.fetchCategories();
	// 	}

	// 	handleFetchCategories();
	// }, [categoriesStore]);

  // const size = useWindowSize();
	// const [categories, setCategories] = useState([]);
  // const { t } = useTranslation();

  const handleClickCategory = (id) => {
    setActiveCategory(id);
  }

  return (
		<Box className={styles['categories']}>
			<Box className={styles['categories-buttons']}>
				{
					categoriesStore.mainCategoriesCaption.map((category, index) => (
						<Button
							disableRipple
							key={category.id}
							className={clsx(styles['categories-button'], (Number(category.id) === Number(activeCategory) ? styles['is-active'] : ''))}
							onClick={() => handleClickCategory(category.id)}
						>
							{category.caption}
						</Button>
					))
				}
			</Box>
			<Link className={styles['categories-link']} to='#'>Переглянути всі</Link>
		</Box>
  )
}

export default observer(Categories);
