function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const showProductPrice = ({ price }) => {
	return `${price} ₴`;
}

function readableFileSize(attachmentSize) {
  const DEFAULT_SIZE = 0;
  const fileSize = attachmentSize ?? DEFAULT_SIZE;

  if (!fileSize) {
    return `${DEFAULT_SIZE} Kb`;
  }

  const sizeInKb = fileSize / 1024;

  if (sizeInKb > 1024) {
    return `${(sizeInKb / 1024).toFixed(2)} Mb`;
  } else {
    return `${sizeInKb.toFixed(2)} Kb`;
  }
}

export {numberWithSpaces, showProductPrice, readableFileSize};
