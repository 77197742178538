import { useState } from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import styled from '@emotion/styled';
import clsx from 'clsx';

import styles from './RecommendedProductCard.module.scss';
import ImageLoader from '../image-loader';

const BlockWithMedia = styled('div')(({ bgColor, isActive }) => {
  const borderActive = (isActive !== null && isActive === 1) ? '1px solid #ff785c' : 'none';
  return {
    backgroundColor: bgColor,
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    border: borderActive
    // '&:not(:first-child)': {
    //   marginLeft: '10px'
    // }
  }
});

export const OptionTypesBlock = ({ value = '', optionType = '', isActive = null }) => {
	let returnBlock = '';
	if (optionType.length) {
		if (optionType === 'media') {
			returnBlock = <BlockWithMedia bgColor={value} isActive={isActive} />;
		} else if (optionType === 'text') {
      const activeValue = (isActive !== null && isActive === 1) ? `${styles['is-active']}` : ''
			returnBlock = <Typography className={clsx(styles['option-item__values-text'], activeValue)}>{value}</Typography>;
		}
	}
	return returnBlock;
}

const RecommendedProdCard = ({ productInfo }) => {
  const [isFavorite, setIsFavorite] = useState(false);

	const favClick = () => {
		setIsFavorite(!isFavorite);
	}
  return (
    <Box sx={{ pl: { md: 2 }, pr: { md: 2 } }}>
      <Box className={styles['recommended-product']}>
        <Box className={styles['recommended-product__description']}>
          <div className={styles['product-title']}>{productInfo.title}</div>
          <Box className={styles['product-options']}>
            {
              productInfo.options.map(({ id, name, type, values }) => (
                <Box className={styles['option-item']} key={id}>
                  <Box className={styles['option-item__title']}>{name}</Box>
                  <Box className={styles['option-item__values']}>
                    {
                      values.map(({ value, id, isActive }) => (
                        <OptionTypesBlock key={id} value={value} optionType={type} isActive={isActive} />
                      ))
                    }
                  </Box>
                </Box>
              ))
            }
          </Box>
          <Box className={styles['product-price']}>
            <Box className={styles['product-price__title']}>Ціна:</Box>
            <Box className={styles['product-price__prices']}>
              <Box className={styles['product-price__prices-old']}>{productInfo.priceOld}</Box>
              <Box className={styles['product-price__prices-new']}>{productInfo.price}</Box>
            </Box>
          </Box>
          <Box className={styles['product-buttons']}>
            <Button className={styles['product-buttons__buy']} disableRipple>Додати в кошик</Button>
            <IconButton className={styles['product-buttons__favorite']} disableRipple onClick={favClick}>
              { isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
            </IconButton>
          </Box>
        </Box>
        <Box className={styles['recommended-product__image']}>
          <ImageLoader src={productInfo.image} alt={`product-item-${productInfo.id}`} />
          {/* <img src={productInfo.image} alt={`product-item-${productInfo.id}`} /> */}
        </Box>
      </Box>
    </Box>
  )
}

export default RecommendedProdCard
