import axios from "axios";

export const BASE_URL = 'https://zefir.store/php';
export const AUTH_API_URL = `${BASE_URL}/auth`;

const $api = axios.create({
    withCredentials: false,
    baseURL: AUTH_API_URL
});

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
});

export default $api;
