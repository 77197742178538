import React, { useRef } from 'react'
import { Box, Typography, IconButton, Grid } from '@mui/material';
import Slider from 'react-slick';
import clsx from 'clsx';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import RecommendedProdCard from '../recommended-product-card';
import TopSeller from '../top-seller';
import './CustomSlider.scss';
import BrandCard from '../brand-card';

const CustomSlider = ({ title, arrayFromSlider, sliderType }) => {
	let customSlider = useRef(null);

	const setCustomSliderRef = (currSlider) => {
		customSlider = currSlider;
	}

	const previousCustomSlider = () => {
		customSlider.slickPrev();
	}

	const nextCustomSlider = () => {
		customSlider.slickNext();
	}

	let customSliderSettings = {};

	if (sliderType === 'recommended') {
		customSliderSettings = {
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: false,
			ref: setCustomSliderRef,
			responsive: [
				{
					breakpoint: 1560,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}
		]
		};
	} else if (sliderType === 'sellers') {
		customSliderSettings = {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			rows: 2,
			slidesPerRow: 3,
			arrows: false,
			ref: setCustomSliderRef,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesPerRow: 2
					}
				},
				{
					breakpoint: 568,
					settings: {
						rows: 1,
						slidesToShow: 1,
						slidesPerRow: 1,
						slidesToScroll: 1,
					}
				}
			]
		};
	} else if (sliderType === 'brands') {
		customSliderSettings = {
			infinite: true,
			// slidesToShow: 2,
			slidesToScroll: 1,
			speed: 500,
			rows: 2,
			slidesPerRow: 5,
			arrows: false,
			ref: setCustomSliderRef,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesPerRow: 3
					}
				},
				{
					breakpoint: 568,
					settings: {
						rows: 1,
						slidesToShow: 2,
						slidesPerRow: 1,
						slidesToScroll: 1,
					}
				}
			]
		};
	}

// const GetTypeCard = ({ arrayItem }) => {
// 	let returnCard = null;
// 	if (sliderType === 'recommended') {
// 		returnCard = <RecommendedProdCard productInfo={arrayItem} />;
// 	} else if (sliderType === 'sellers') {
// 		returnCard = <div><TopSeller sellerInfo={arrayItem} /></div>;
// 	} else if (sliderType === 'brands') {
// 		returnCard = <div><BrandCard brandImage={arrayItem} /></div>;
// 	} else {
// 		returnCard = <div><h3>Error</h3></div>;
// 	}
// 	return returnCard;
// }

  return (
		<Grid container className='custom-slider'>
			{/* <Grid item md={1}></Grid> */}
			{/* <Grid item md={11}> */}
			<Box sx={{ width: '100%' }}>
				<Box className='custom-slider__title'>
					<Typography className='custom-slider__title-title'>{title}</Typography>
					<Box className='custom-slider__buttons'>
						<IconButton onClick={previousCustomSlider} className={clsx('custom-slider__button', 'custom-slider__buttons-prev')} disableRipple>
							<KeyboardArrowLeftIcon fontSize='small' />
						</IconButton>
						<IconButton onClick={nextCustomSlider} className={clsx('custom-slider__button', 'custom-slider__buttons-next')} disableRipple>
							<KeyboardArrowRightIcon fontSize='small' />
						</IconButton>
					</Box>
				</Box>
				<Box className='custom-slider__slider'>
					<Slider {...customSliderSettings}>
						{
							arrayFromSlider.map((arrayItem, index) => (
								(sliderType === 'recommended') ? (
									<RecommendedProdCard productInfo={arrayItem} key={arrayItem.id} />
								) : (
									(sliderType === 'sellers') ? (
										<div key={arrayItem.id}><TopSeller sellerInfo={arrayItem} /></div>
									) : (
										(sliderType === 'brands') ? (
											<div key={arrayItem.id}><BrandCard brandImage={arrayItem} /></div>
										) : (
											<div key={index}><h3>{index}</h3></div>
										)
									)
								)
							))
						}
					</Slider>
				</Box>
			</Box>
			{/* </Grid> */}
		</Grid>
  )
}

export default CustomSlider
