import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Box, IconButton, Button, FormControl, FormHelperText } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaAddProduct } from '../../../validator';
import { FormProvider, useForm, Controller, useController } from "react-hook-form";

import { readableFileSize } from '../../../../utils/functions';
import styles from './AddProductBlock.module.scss';
import { Context } from '../../../..';

const FILE_FORMATS = {
  image: ['image/jpeg', 'image/png', 'image/gif'],
  video: ['video/mp4', 'video/mkv'],
};

const AddFiles = ({ control, rules, errors, name, register }) => {
	const [fileList, setFileList] = useState([]);
	const {
    field: { onChange, onBlur, ref }
  } = useController({
    name,
    control,
    rules,
		errors,
		register
  });

	const store = useContext(Context);
	const { formData, setFiles, errorFileMessage, fileChange, deleteFile, deleteAllFiles } = store.rootStore.addProductStore;
	const { files } = formData;

	// const { register, control, formState: { errors }, setValue } = useForm({
  //   resolver: yupResolver(schemaAddProduct)
  // });

	const handleDrop = (e, field) => {
		// console.log('drop event >>>', e.target.files);
    e.preventDefault();
    // const files = Array.from(e.dataTransfer.files);
		const newFiles = Array.from(e.dataTransfer.files);
		const updatedFileList = [...fileList, ...newFiles];
		fileChange(updatedFileList);
    setFileList(updatedFileList);
    field.onChange(updatedFileList);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // const handleFilesChange = (e, field) => {
  //   const files = Array.from(e.target.files);
  //   fileChange(files);
	// 	// setValue('files', e.target.files)
	// 	// onChange(e.target.files);
	// 	field.onChange(files)
  // };

	const handleFilesChange = (event, field) => {
    const newFiles = Array.from(event.target.files);
    const updatedFileList = [...fileList, ...newFiles];
    setFileList(updatedFileList);
    field.onChange(updatedFileList);
		fileChange(newFiles);
  };

	const handleFileRemove = (fileToRemove, field) => {
    const updatedFileList = fileList.filter(file => file !== fileToRemove);
    setFileList(updatedFileList);
    field.onChange(updatedFileList);
		// deleteFile(index)
		fileChange(updatedFileList);
  };

	return (
		<Controller
			control={control}
			name={name}
			defaultValue={[]}
			value={fileList}
			{...register('files')}
			error={errors.files ? errors.files : undefined}
			aria-describedby="files-product-helper-text"
			render={({ field }) => (
				<>
					<Box
						className={clsx(styles['photo-dropzone'], (errors[name] ? ` ${styles['is-error']}` : ''))}
						onDrop={(e) => handleDrop(e, field)}
						onDragOver={handleDragOver}
					>
						<SaveAltRoundedIcon fontSize='large' />
						<Box className={styles['photo-dropzone__title']}>
							<Box component="span">Перетягніть файли сюди або</Box>
							<Box component="label" className={styles['photo-dropzone__title-browse']}>
								<FormControl>
									<>
										<input
											type="file"
											accept={Object.values(FILE_FORMATS).flat().join(',')}
											multiple
											onChange={(e) => handleFilesChange(e, field)}
											// onChange={(e) => field.onChange(Array.from(e.target.files))}
										/>
										натисніть для вибору
									</>

							{/* <>
								<input
									type="file"
									multiple
									accept="image/*,video/*"
									{...register('files')}
									// onChange={(e) => setValue('files', e.target.files)}
									onChange={handleFileSelect}
									id="fileInput"
									name="files"
									aria-describedby="files-product-helper-text"
									error={errors.files ? errors.files : undefined}
									onBlur={onBlur}
        					ref={ref}
								/>
								натисніть для вибору
							</> */}

						</FormControl>

						{/* {errors.files && <p>{errors.files.message}</p>} */}
						{/* <input id="fileInput" type="file" onChange={handleFileSelect} multiple accept="image/*, video/*" />натисніть для вибору */}
					</Box>
				</Box>
				{/* <Box className={styles['photo-dropzone__error']}>{errorFileMessage}</Box> */}
				<FormHelperText id="files-product-helper-text" className={styles['photo-dropzone__error']}>{errors[name] ? errors[name].message : ''}</FormHelperText>
			</Box>
			<Box className={styles['photo-items']}>
				{
					fileList.map((file, index) => (
						<Box className={styles['photo-item']} key={index}>
							{file.type.startsWith('image/') ? (
								<img src={URL.createObjectURL(file)} alt={`Фото ${index + 1}`} />
							) : (
								<video src={URL.createObjectURL(file)} alt={`Відео ${index + 1}`} controls />
							)}
							<Box className={styles['photo-item__details']}>
								<Box variant="span" className={styles['photo-name']}>{file.name}</Box>
								<Box variant="span" className={styles['photo-size']}>({readableFileSize(file.size)})</Box>
							</Box>
							<IconButton
								aria-label="delete"
								className={styles['photo-item__delete']}
								disableRipple
								onClick={() => handleFileRemove(file, field)}
							>
								<CloseRoundedIcon />
							</IconButton>
						</Box>
					))
				}
			</Box>
			{ files.length > 0 && <Button className="button button-light" variant="contained" disableRipple onClick={deleteAllFiles} sx={{ alignSelf: 'center', mt: 2 }}>Видалити всі файли</Button> }
				</>
			)}
		/>
	)
}

export default observer(AddFiles);
