import React, { useRef, useState, useEffect, useContext } from 'react';
import { IconButton, Grow, Paper, MenuItem, MenuList, Box, Popper, Divider } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
// import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';

import { useNavigate } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { Context } from '../..';

import './LoginMenuClient.scss';

const menuItems = [
		{
				id: 1,
				title: 'Моя інформація',
				url: '/shopper/my-info',
				icon: <Person2RoundedIcon />
		},
		{
				id: 2,
				title: 'Адреса доставки',
				url: '/shopper/delivery-address',
				icon: <LocalShippingRoundedIcon />
		},
		{
				id: 3,
				title: 'Безпека',
				url: '/shopper/security',
				icon: <SecurityRoundedIcon />
		},
		{
				id: 4,
				title: 'Мої розміри',
				url: '/shopper/sizes',
				icon: <StraightenRoundedIcon />
		},
		{
				id: 5,
				title: 'Замовлення',
				url: '/shopper/orders',
				icon: <ShoppingBagRoundedIcon />
		},
		{
				id: 6,
				title: 'Повідомлення',
				url: '/shopper/message',
				icon: <MessageRoundedIcon />
		},
		{
				id: 7,
				title: 'Відгуки',
				url: '/shopper/feedback',
				icon: <StarRoundedIcon />
		},
		{
				id: 8,
				title: 'Підписки',
				url: '/shopper/subscription',
				icon: <AssignmentTurnedInRoundedIcon />
		},
		{
				id: 9,
				title: 'Обране',
				url: '/shopper/favorites',
				icon: <FavoriteRoundedIcon />
		},
		{
				id: 10,
				title: 'Переглянуте',
				url: '/shopper/reviews',
				icon: <RemoveRedEyeRoundedIcon />
		},
		{
			id: 11,
			title: 'Додати товар',
			url: '/seller/add-product',
			icon: <AddBusinessRoundedIcon />
		},
		{
			id: 12,
			title: 'Замовлення',
			url: '/seller/orders',
			icon: <InventoryRoundedIcon />
		}
];

const LoginMenuClient = () => {
	const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

	const navigate = useNavigate();

	const store = useContext(Context);

	const { logoutAuth } = store.rootStore.authStore;

	const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

	const handleLogout = () => {
		logoutAuth();
		navigate('/');
	}

	return (
		<Box className='login-menu'>
			<IconButton
				disableRipple
				ref={anchorRef}
				id="composition-button"
				aria-controls={open ? 'composition-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				className={clsx('login-menu__person', (open ? 'is-open' : ''))}
			>
				<PersonIcon />
			</IconButton>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				placement='bottom-end'
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: 'right top' }}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="composition-menu"
									aria-labelledby="composition-button"
									onKeyDown={handleListKeyDown}
									className='login-menu__list'
								>
									{
										menuItems.map(item => (
											<MenuItem key={item.id} disableRipple sx={{ pl: 2, pr: 2, pt: 0, pb: 0 }}>
												<NavLink to={item.url} className='login-menu__list-item'>
													<IconButton className='login-menu__list-icon' disableRipple>{item.icon}</IconButton>
													<Box className='login-menu__list-text'>{item.title}</Box>
												</NavLink>
											</MenuItem>
										))
									}
									<Divider/>
									<MenuItem onClick={handleLogout} disableRipple sx={{ pl: 2, pr: 2, pt: 0, pb: 0 }} className='login-menu__list-item'>
										<IconButton className='login-menu__list-icon' disableRipple><LogoutRoundedIcon /></IconButton>
										<Box className='login-menu__list-text'>Вихід</Box>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Box>
	)
}

export default observer(LoginMenuClient)
