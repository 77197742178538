// import axios from "axios";
import { makeAutoObservable, toJS } from "mobx";
import MyData from "../services/auth/MyData";

class MyInfo {
	// firstname = '';
	// patronymic = '';
	// surname = '';
	// email = '';
	// phone = '';
	clientID = null;
	loginEmail = '';
	loginPhone = '';
	// photo = '';
	// dateCreate = null;
	newMessages = 0;
	newOrders = 0;
	password = null;

	// postService = 'NovaPoshta';
	// typeDelivery = 'Self';
	city = '';
	// postOffice = ''
	// street = '';
	// building = '';
	// appartment = '';

	isLoadingInfo = true;
	saveSizeLoading = false;

	clientInfo = {};

	addresses = {};

	defaultSize = {
		caption: '',
		sizes: [
			{
				key: 'breast',
				caption: 'Об\'єм грудей',
				limits: [
					{
						key: 'from',
						name: 'Від',
						value: '',
					},
					{
						key: 'to',
						name: 'До',
						value: ''
					}
				]
			},
			{
				key: 'waist',
				caption: 'Об\'єм талії',
				limits: [
					{
						key: 'from',
						name: 'Від',
						value: ''
					},
					{
						key: 'to',
						name: 'До',
						value: ''
					}
				]
			},
			{
				key: 'thigh',
				caption: 'Об\'єм стегон',
				limits: [
					{
						key: 'from',
						name: 'Від',
						value: ''
					},
					{
						key: 'to',
						name: 'До',
						value: ''
					}
				]
			},
			{
				key: 'foot',
				caption: 'Довжина ступні',
				limits: [
					{
						key: 'length',
						name: 'Довжина',
						value: ''
					}
				]
			}
		]
	}

	mySizes = [];
	selectedSizeID = null;
	selectedSize = this.defaultSize;

	constructor () {
		makeAutoObservable(this);
	}

	setFirstname (firstname) {
		console.log('firstname >', firstname);
		// this.setClientInfo();
		this.clientInfo.firstname = firstname;
		// this.setClientInfo(this.clientInfo);
	};
	setPatronymic (patronymic) {
		this.patronymic = patronymic !== null ? patronymic : '';
	};
	setSurname (surname) {
		this.surname = surname !== null ? surname : '';
	};
	setEmail (email) {
		this.email = email !== null ? email : '';
	};
	setPhone (phone) {
		this.phone = phone !== null ? phone : '';
	};

	// setSecurityInfo = (fieldName, fieldValue) => {
	// 	this.clientInfo[fieldName] = fieldValue;
	// }

	// setLoginPhone (loginPhone) {
	// 	this.loginPhone = loginPhone !== null ? loginPhone : '';
	// };
	// setLoginEmail (loginEmail) {
	// 	this.loginEmail = loginEmail !== null ? loginEmail : '';
	// };
	// setPassword (password) {
	// 	this.password = password !== null ? password : '';
	// };

	setPhoto (photo) {
		this.photo = photo !== null ? photo : '';
	};
	setNewMessages (newMessages) {
		this.newMessages = newMessages;
	};
	setNewOrders (newOrders) {
		this.newOrders = newOrders;
	};
	setClientID (clientID) {
		this.clientID = clientID;
	};
	setDateCreate (dateCreate) {
		this.dateCreate = dateCreate;
	};

	setAddressCity = (city) => {
		this.city = city;
	}

	setAddressStreet (street) {
		this.street = street !== null ? street : '';
	};
	setAddressBuilding (building) {
		this.building = building !== null ? building : '';
	};
	setAddressAppartment (appartment) {
		this.appartment = appartment !== null ? appartment : '';
	};
	setPostOffice (postOffice) {
		this.postOffice = postOffice !== null ? postOffice : '';
	};
	setPostService (postService) {
		this.postService = postService;
	};
	setTypeDelivery (typeDelivery) {
		this.typeDelivery = typeDelivery;
	};

	setIsLoadingInfo (bool) {
		this.isLoadingInfo = bool;
	}

	setAddresses (address) {
		this.addresses = address;
	}

	setMySizes = (sizes) => {
		this.mySizes = sizes;
	}

	setSelectedSizeID = (id) => {
		this.selectedSizeID = id;
	}

	setSelectedSize = (size) => {
		this.selectedSize = size;
	}

	setSaveSizeLoading = (bool) => {
		this.saveSizeLoading = bool;
	}

	setCustomNameAndValue = (name, value) => {
		if (this.clientInfo.hasOwnProperty(name)) {
			this.clientInfo[name] = value;
		}
	}

	saveSecurityInfo = () => {
		this.handleSaveMyInfo(this.clientInfo);
	}

	editSize = (sizes, id) => {
		// console.log('mySizes >', this.mySizes);
		// console.log('edit ID >>>', id);
		// const tempSizes = this.mySizes;
		const findSize = sizes.find(item => Number(item.sizeID) === Number(id));
		// console.log('findSize >', toJS(findSize));
		// setFindIndexOfSize(indexOfSelectSize);
		// console.log('indexOfSelectSize >', indexOfSelectSize);
		// this.setSelectedSizeID(null);
		// this.setSelectedSize(this.defaultSize);
		// this.setInitMySizes(this.mySizes);
		this.setSelectedSizeID(id);
		this.setSelectedSize(findSize);
		// setDefaultSize(mySizes[indexOfSelectSize]);
	}

	deleteSize = async (data) => {
		// const id = data.id;
		// const newArraySizes = this.mySizes.filter(size => Number(size.sizeID) !== Number(id));
		// this.setMySizes(newArraySizes);
		// не удалять!
		try {
			this.setSaveSizeLoading(true);
			const { data } = MyData.deleteMySize(data);
			this.setMySizes(data.data.client_sizes);
			this.setSaveSizeLoading(false);
 		} catch (e) {
			console.error('Не вдалося видалити розмір', e);
		}
	}

	addNewOrSaveEditSize = async (newSize) => {
		try {
			this.setSaveSizeLoading(true);
			const { data } = await MyData.saveMyInfo(newSize);
			this.setMySizes(data.data.client_sizes);
			this.setSaveSizeLoading(false);
		} catch (e) {
			console.error('Не можливо додати/зберегти розмір', e);
		}
	}

	setMyInfoDataFromResponse = async (authStore, typeInfo) => {
		if (typeInfo === 'client-sizes') {
			this.setMySizes(authStore.clientSizes);
			// console.log('myInfo, mySizes >', toJS(this.mySizes));
		} else {
			this.setClientInfo(authStore.clientData);
			console.log('client info >', toJS(this.clientInfo));
		}
	}

	setClientInfo = async (array) => {
		if (typeof array === 'object' && Object.keys(array).length > 0) {
			if (typeof array.addresses === 'object' && Object.keys(array.addresses).length > 0) {
				this.setAddresses(array.addresses);
				// this.addresses = array.addresses;
				delete array.addresses;
			}

			this.clientInfo = array;
			this.setIsLoadingInfo(false);
		}
		// console.log('addresses >>>', toJS(this.addresses));
		// this.city = this.addresses.city;
		// console.log(this.clientInfo);
		// this.setEmail(this.clientInfo.email);
		// this.setPhone(this.clientInfo.phone);
		// this.setFirstname(this.clientInfo.firstname);
		// this.setSurname(this.clientInfo.surname);
		// this.setPhoto(this.clientInfo.photo);
		// this.setPatronymic(this.clientInfo.patronymic);
		// this.setLoginEmail(this.clientInfo.loginEmail);
		// this.setLoginPhone(this.clientInfo.loginPhone);
		// this.setNewMessages(this.clientInfo.newMessages);
		// this.setNewOrders(this.clientInfo.newOrders);
		// this.setClientID(this.clientInfo.clientID);
		// this.setDateCreate(this.clientInfo.date_create);
	};

	// async fetchClientInfo () {
	// 	try {
	// 		const { data } = await MyData.loadClientInfo();
	// 		console.log('data >', data);
	// 	} catch (e) {
	// 		console.error('Помилка отримання даних клієнта з бази', e);
	// 	}
	// }

	async handleSaveMyInfo (data) {
		try {
			await MyData.saveMyInfo(data);
		} catch (e) {
			console.error('Помилка збереження даних в базу', e);
		}
	}

	async handleAddDeliveryAddress (data) {
		try {
			const response = await MyData.saveMyInfo(data);
			console.log(response);
		} catch (e) {
			console.error('Помилка збереження адреси доставки', e);
		}
	}

	async uploadPhoto (file) {
		try {
			const userJWT = localStorage.getItem('token');
			const formData = new FormData();
			formData.append('photo', file);
			formData.append('jwt', userJWT);
			const { data } = await MyData.uploadClientPhoto(formData);
			if (data.success) {
				this.setPhoto(data.file_name);
			}
		} catch (e) {
			console.error('Помилка додавання власної фотки', e);
		}
	}
}

export default MyInfo;
