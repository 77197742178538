import React, { useEffect, useContext } from 'react'

import Header from '../header'
import Footer from '../footer'
import { Box } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import { Context } from '../..';

const Layout = ({ children }) => {
	const store = useContext(Context);
	const { categoriesStore } = store.rootStore;

	useEffect(() => {
		const handleFetchCategories = async () => {
			await categoriesStore.fetchCategories();
		}

		handleFetchCategories();
	}, [categoriesStore]);

  return (
    <>
			<StyledEngineProvider injectFirst>
				<Header />
					<Box sx={{ mt: { def: 2 }, mb: {def: 2}, width: '100%', position: 'relative' }}>{ children }</Box>
				<Footer />
			</StyledEngineProvider>
    </>
  )
}

export default Layout;
