import FavoriteReviewBlock from '../../../../components/admin/shopper/favorite-review-block';
import LayoutSeller from '../../../../components/admin/layout';

const FavoritesReviews = ({ typePage }) => {
  return (
    <LayoutSeller>
      <FavoriteReviewBlock typePage={typePage} />
    </LayoutSeller>
  )
}

export default FavoritesReviews;
