import SecurityBlock from "../../../../components/admin/shopper/security-block";
import LayoutSeller from '../../../../components/admin/layout';

const Security = () => {
	return (
		<LayoutSeller typeInfo='client-info'>
			<SecurityBlock />
		</LayoutSeller>
	)
}

export default Security
